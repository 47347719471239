
import React, { useEffect, useState } from "react"
import { formatTimeHHMMSS, formatTimestamp } from "Utils/dates"
import { getTableHeader, getTDAlign } from "Utils/tables"
import paginationPrevoiusDisabledIcon from "Assets/images/arrows/pagination-prev-disabled.svg"
import rightArrowBlueIcon from "Assets/images/arrows/right-arrow-blue.svg"
import sortArrowDown from "Assets/images/arrows/sort-arrow-down.svg"
import "./Table.css"
import Tooltip from "Components/Tooltip/Tooltip"
import DownloadCSVButton from "Components/DownloadCSVButton/DownloadCSVButton"
import { TableData } from "@declarations"
import { Constants } from "Values/constants"

type Props = {
    title: string
    titleTooltip?: string
    showTitle: boolean
    headers: string[]
    searchBy?: string[]
    searchByPlaceholder?: string
    sortable?: string[]
    tooltipable?: Record<string, string>[]
    data: Array<TableData>
    limit: number
    pagination: boolean
    showDownloadCSV?: boolean
    dividedHeader?: boolean
}

const PAGINATION_TABS = 5

const Table = (props: Props) => {

    const { title, titleTooltip, showTitle, headers, searchBy, searchByPlaceholder, sortable, tooltipable, data, limit, pagination,
        showDownloadCSV = false,
        dividedHeader = false
    } = props

    const [cleanedBeforePagination, setCleanedBeforePagination] = useState<any[]>([])
    const [cleaned, setCleaned] = useState<any[]>(data)
    const [search, setSearch] = useState<string>("")
    const [sortingBy, setSortingBy] = useState<{ header: string, asc: boolean }>({ header: "", asc: true })
    const [pageNum, setPageNum] = useState<number>(1);
    const [carousel, setCarousel] = useState<number[]>([])
    const [active, setActive] = useState<number>(1)
    const [paginationItems, setPaginationItems] = useState<any[]>([])
    const [totalPageCount, setTotalPageCount] = useState<number>(0)
    const [goToPage, setGoToPage] = useState<number>(0)

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setSearch(value)
    }

    const handleSort = (h: string) => {
        if (h === sortingBy.header) {
            setSortingBy({
                header: h,
                asc: !sortingBy.asc
            })
        } else {
            setSortingBy({
                header: h,
                asc: true
            })
        }
    }

    useEffect(() => {
        const lower = (pageNum - 1) * limit
        const upper = ((pageNum - 1) * limit) + limit

        const working: any[] = []
        const filtered: any[] = []

        if (searchBy && search) {
            data.forEach((d: any) => {
                searchBy.forEach((s: string) => {
                    if (d.hasOwnProperty(s) && typeof d[s] === "string") {
                        if (d[s].toLowerCase().includes(search)) {
                            working.push(d)
                        }
                    }
                })
            })
        } else {
            working.push(...data)
        }

        if (sortingBy.header) {
            working.sort((a, b) => (
                sortingBy.asc
                    ? (a[sortingBy.header] > b[sortingBy.header]) ? 1 : ((b[sortingBy.header] > a[sortingBy.header]) ? -1 : 0)
                    : (b[sortingBy.header] > a[sortingBy.header]) ? 1 : ((a[sortingBy.header] > b[sortingBy.header]) ? -1 : 0)
            ))
        }

        setCleanedBeforePagination(working)

        working.forEach((d: any, order: number) => {
            if (order >= lower && order < upper) {
                filtered.push(d)
            }
        })

        setCleaned(filtered)
        setTotalPageCount(Math.ceil(working.length / limit))
    }, [data, limit, pageNum, search, searchBy, sortingBy])

    // handle pagination
    const updateCarousel = (start: number, pages: number, direction: "forward" | "backward") => {
        const items: number[] = []
        let track = 1
        for (let number = start; track <= PAGINATION_TABS; direction === "forward" ? number++ : number--) {
            if (number > pages || number < 1) {
                break
            }
            const newItem = number
            items.push(newItem)
            track++
        }
        setCarousel(items.sort((a: number, b: number) => a - b))
        return items
    }

    useEffect(() => {
        // initial setup once total page count is determined
        updateCarousel(1, totalPageCount, "forward")
    }, [totalPageCount])

    const updateGoToPage = (e: React.ChangeEvent<HTMLInputElement>) => {
        const v = e.target.value
        setGoToPage(Number(v) || 0)
    }

    const handleSubmitPageChange = (e: React.FormEvent<HTMLElement>) => {
        e.preventDefault()
        if (goToPage) handlePageChange(goToPage)
    }

    const handlePageChange = (number: number) => {
        setActive(number)
        setPageNum(number)
    }

    const handlePagePrev = () => {
        if (pageNum > 1) {
            setPageNum(p => p - 1)
            setActive(a => a - 1)
        }
    }

    const handlePageNext = () => {
        if (pageNum < totalPageCount) {
            setPageNum(p => p + 1)
            setActive(a => a + 1)
        }
    }

    useEffect(() => {

        // if we move the page number beyond the end of the current carousel, then move the carousel along
        if (pageNum > carousel[carousel.length - 1] && pageNum < totalPageCount) {
            updateCarousel(pageNum, totalPageCount, "forward")
        }
        else if (
            // if the page number is beyond the end of the current carousel, and we've reached or gone beyond (beyond shouldn't happen) the total page count
            (pageNum > carousel[carousel.length - 1] && pageNum >= totalPageCount)
            // or we've hit a page number before the beginning of the current carousel
            || (pageNum < carousel[0])
        ) {
            // then count backward from that page number
            updateCarousel(pageNum, totalPageCount, "backward")
        }
    }, [carousel, pageNum, totalPageCount])


    useEffect(() => {
        const items = []
        for (const number of carousel) {
            items.push(
                <button
                    key={number}
                    id={number?.toString()}
                    className={`page-number ${number === active ? "page-number-active" : ""}`}
                    onClick={() => handlePageChange(number)}
                >
                    {number}
                </button>,
            );
        }
        setPaginationItems(items)
    }, [active, carousel])

    const renderTd = (d: any, k: string, idx: number, isUser: boolean, isDate: boolean, isTime: boolean, isRate: boolean, isNumber: boolean) => {
        if (isUser) {
            return <span title={d[k]}>{idx}</span>
        }
        else if (isDate) {
            return <>
                {new Date(d[k]).toLocaleDateString()}
                <br />
                {<p className="subscript">{formatTimestamp(d[k])}</p>}
            </>
        } else if (isTime) {
            return formatTimeHHMMSS(d[k])
        } else if (isRate) {
            return `${d[k]?.toFixed(0)}%`
        } else if (!d[k]) {
            return isNumber ? 0 : Constants.unavailable_data_label
        } else {
            return d[k]
        }
    }

    return (
        <div className={`table-wrapper ${dividedHeader ? "table-wrapper-divided" : ""}`}>
            <div className={`table-top-header ${dividedHeader ? "table-top-header-divided" : ""}`}>
                {showTitle ? <h3>{title} {titleTooltip && <Tooltip text={titleTooltip} />}</h3> : <div></div>}
                {searchBy && <input className="search" onChange={handleSearch} type="text" placeholder={searchByPlaceholder} />}
                {showDownloadCSV && <DownloadCSVButton
                    title={title}
                    headers={headers}
                    data={data}
                />}
            </div>
            <table
            >
                <thead>
                    <tr>
                        {
                            headers.map(h => (
                                <th key={h}>

                                    <div className="th-inner-wrapper">
                                        <p>{getTableHeader(h)}</p>

                                        {tooltipable && tooltipable.length
                                            ? tooltipable.find(t => t.header && t.header === h) &&
                                            <Tooltip text={tooltipable.find(t => t.header && t.header === h)?.text} />
                                            : null
                                        }

                                        {sortable && sortable.length ?
                                            sortable.includes(h) &&
                                            <button
                                                onClick={() => handleSort(h)}
                                                className="sort-arrow">
                                                <img
                                                    src={sortArrowDown}
                                                    alt="sort"
                                                    className={sortingBy.header === h && !sortingBy.asc ? "sort-arrow-up" : ""}
                                                />
                                            </button>
                                            : null
                                        }
                                    </div>

                                </th>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        cleaned.map((d: any, idx: number) => (
                            <tr key={`${d}-${idx}`}>
                                {Object.keys(d).map((k: string, i: number) => {
                                    const isUser = k.toLowerCase().includes('user')
                                    const isDate = k.toLowerCase().includes('date')
                                    const isTime = k.toLowerCase().includes('time')
                                    const isRate = k.toLowerCase().includes('rate') || k.toLowerCase().includes('ctr') || k.toLowerCase().includes('percent')
                                    const isNumber = typeof d[k] === "number"
                                    return <td
                                        key={`${k} ${i}`}
                                        style={{ textAlign: getTDAlign(isDate) }}
                                    >
                                        {renderTd(d, k, idx + 1, isUser, isDate, isTime, isRate, isNumber)}
                                    </td>
                                })}
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            {pagination &&
                <div className="pagination-row">
                    <p>Results: {
                        !cleanedBeforePagination.length
                            ? `0`
                            : `${((pageNum - 1) * limit) + 1}-${Math.min(((pageNum - 1) * limit) + limit, cleanedBeforePagination.length)} of ${cleanedBeforePagination.length}`
                    } </p>

                    <div className="pagination-pages">
                        {<button
                            onClick={handlePagePrev}
                            disabled={active <= 1}
                            className={`pagination`}
                        >
                            <img
                                src={paginationPrevoiusDisabledIcon}
                                className={`${active <= 1 ? "" : "active"}`}
                                alt="previous" />
                        </button>}

                        {paginationItems}

                        {<button onClick={handlePageNext}
                            disabled={active >= totalPageCount}
                            className={`pagination pagination-next`}
                        >
                            <img
                                src={paginationPrevoiusDisabledIcon}
                                className={`${active >= totalPageCount ? "" : "active"}`}
                                alt="next" />
                        </button>}
                    </div>

                    <div className="direct-to-page">
                        <p>Go to Page</p>
                        <form onSubmit={handleSubmitPageChange}>
                            <input
                                type="number"
                                min="1"
                                max={totalPageCount}
                                value={goToPage || ""}
                                onChange={updateGoToPage} />
                            <button type="submit" className="go-to-page">
                                Go
                                <img src={rightArrowBlueIcon} alt="" />
                            </button>
                        </form>
                    </div>
                </div>
            }
        </div>

    )
}

export default Table