import { xApiKeys } from "Values/xApiKeys"

const returnExample = [
    {
        "total": [
            {
                "count": 261
            }
        ],
        "platform": [
            {
                "_id": "android",
                "count": 143
            },
            {
                "_id": "ios",
                "count": 118
            }
        ]
    }
]

export type totalScansReturnType = typeof returnExample

const buildTotalScans = (): any[] => [
    // group by platform
    {
        "$group": {
            "_id": xApiKeys.Extensions.platform.path,
            "count": {
                "$sum": 1
            },
        }
    },
    // run through sub-pipeline
    {
        "$facet": {
            "total": [
                // get total across records
                {
                    "$group": {
                        "_id": null,
                        "count": {
                            "$sum": "$count"
                        }
                    }
                },
                // project to get rid of _id value needed for group
                {
                    "$project": {
                        "_id": 0,
                        "count": "$count"
                    }
                }
            ],
            // use $match to pass along data from previous stage
            "platform": [
                {
                    "$match": {}
                }
            ]
        }
    }
]
export default buildTotalScans
