import toolTipQ from "Assets/images/buttons/tooltip-q.svg"
import { useState } from "react"
import "./Tooltip.css"

type Props = {
    text?: string
}

const Tooltip = (props: Props) => {

    const { text } = props

    const [hovering, setHovering] = useState<boolean>(false)

    return (
        <div className="my-tooltip-wrapper">
            <img
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => setHovering(false)}
                src={toolTipQ} alt="tooltip" />
            {hovering &&
                <div className="my-tooltip">
                    <p>{text}</p>
                </div>
            }
        </div>
    )

}

export default Tooltip