import { AppState, DateRange, MomentRange, PageProps, Session } from "@declarations"
import { getTop5QRCodes } from "Store/Actions/DataActions-Demographics"
import Table from "Components/Table/Table"
import { useEffect } from "react"
import Skeleton from "react-loading-skeleton"
import { connect } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { store } from "Store/store"
import { buildToLink } from "Utils/routes"
import rightArrowBlue from "Assets/images/arrows/right-arrow-blue.svg"
import "Components/Table/Table.css"
import DatePicker from "Components/DatePicker/DatePicker"
import EmptyState from "Components/EmptyState/EmptyState"
import DownloadCSVButton from "Components/DownloadCSVButton/DownloadCSVButton"
import { setDemographicsDateRange } from "Store/Actions/DateActions"
import { callCheck } from "Utils/data"

interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {
}

const Top5QRCodesTable = (props: Props) => {

    const { session, globalView, experiences, dataList, getDataList, loadingData, demoBrandActive, dates, updateDates } = props

    const location = useLocation()

    useEffect(() => {

        if (!callCheck(globalView, (session as Session.AuthSession).user.admin, experiences?.length)) return

        getDataList(dates)

    }, [dates, getDataList, session, demoBrandActive, experiences, globalView])

    const title = "Top 5 highest performing QR codes"

    return (
        <div className="box divided-box">
            <div className="divided-box-header two-rows">
                <div className="header-row">
                    <h3>{title}</h3>
                    <DatePicker
                        startDate={dates.start}
                        endDate={dates.end}
                        startDateId={"startDateT5QRCT"}
                        endDateId={"endDateT5QRCT"}
                        updateDates={updateDates}
                    />
                </div>
                <div className="header-row">
                    {dataList && dataList.length
                        ? <div style={{ marginLeft: "auto" }}>
                            <DownloadCSVButton
                                title={title}
                                headers={Object.keys(dataList[0])}
                                data={dataList}
                            />
                        </div>
                        : null
                    }
                </div>
            </div>
            <div className={`divided-box-content ${dataList && dataList.length ? "has-table" : ""}`}>
                {
                    loadingData
                        ? <Skeleton height={250} className="table-skeleton" style={{ marginBottom: '5rem' }} />
                        : dataList && dataList.length
                            ? <Table
                                title="Top 5 highest performing QR codes"
                                showTitle={false}
                                headers={Object.keys(dataList[0])}
                                data={dataList}
                                limit={5}
                                pagination={false}
                                showDownloadCSV={false}
                            />
                            : <EmptyState filterableData={true} />
                }
                <div className="link-row undo-top-margin">
                    <Link to={buildToLink(location.pathname, "/demographics")}>
                        <span>View Demographics Report</span>
                        <img src={rightArrowBlue} alt="arrow" />
                    </Link>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: AppState) => {
    return {
        session: state.session,
        experiences: state.experiences.experienceIdList,
        globalView: state.experiences.globalView,
        demoBrandActive: state.experiences.demoBrandActive,
        dataList: state.data.demographics.top5QRCodes,
        dates: state.dates.demographics.top5QRCodes,
        loadingData: state.loading.demographics.top5QRCodes
    }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
        getDataList: (dates: DateRange) => dispatch(getTop5QRCodes(dates)),
        updateDates: (dateRange: MomentRange) => dispatch(setDemographicsDateRange(dateRange, "top5QRCodes"))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Top5QRCodesTable)