import { Breakdown } from "@declarations"
import { getRandomNumber } from "Utils/numbers"

const titles = [
    "iOS",
    "Android"
]

export const getDemoDataTotalScans = (count: number) => {
    const scans = {
        total: 0,
        breakdown: [] as Breakdown[]
    }

    for (let i = 1; i <= count; i++) {
        const scan: Breakdown = {
            _id: titles[getRandomNumber(0, titles.length - 1)],
            count: getRandomNumber(1, 25),
        }
        scans.total += scan.count
        scans.breakdown.push(scan)
    }

    return scans
}