import { PageProps, AppState, Session, MomentRange, DateRange } from "@declarations"
import { getScansByLocation } from "Store/Actions/DataActions-Demographics"
import DatePicker from "Components/DatePicker/DatePicker"
import Map from "Components/Map/Map"
import { useEffect } from "react"
import Skeleton from "react-loading-skeleton"
import { connect } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { store } from "Store/store"
import { buildToLink } from "Utils/routes"
import rightArrowBlue from "Assets/images/arrows/right-arrow-blue.svg"
import TrafficTotalsTable from "../../Tables/TrafficTotalsTable.tsx/TrafficTotalsTable"
import EmptyState from "Components/EmptyState/EmptyState"
import { setDemographicsDateRange } from "Store/Actions/DateActions"
import useChartWidth from "Utils/useChartWidth"
import { callCheck } from "Utils/data"

interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {
    showLink: boolean
    showTable: boolean
}

const ScansByLocationMap = (props: Props) => {

    const { session, globalView, experiences, dataList, getDataList, loadingData, demoBrandActive, dates, updateDates, showLink, showTable } = props


    const location = useLocation()

    useEffect(() => {

        if (!callCheck(globalView, (session as Session.AuthSession).user.admin, experiences?.length)) return

        getDataList(dates)

    }, [experiences, getDataList, session, demoBrandActive, dates, globalView])

    const width = useChartWidth()

    return (
        <>
            {
                <div className='box divided-box' >
                    <div className="divided-box-header">
                        <div className="header-row">
                            <h3>Scans by location</h3>
                            <DatePicker
                                startDate={dates.start}
                                endDate={dates.end}
                                startDateId={"startDateSBLM"}
                                endDateId={"endDateSBLM"}
                                updateDates={updateDates}
                            />
                        </div>
                    </div>
                    <div className="divided-box-content">
                        {loadingData
                            ? <Skeleton height={500} />
                            : dataList && dataList.length
                                ? <>
                                    <Map width={width} height={500} data={dataList} events={true} />
                                    {showTable && <TrafficTotalsTable />}
                                </>
                                : <EmptyState filterableData={true} />
                        }
                        {showLink &&
                            <div className="link-row">
                                <Link to={buildToLink(location.pathname, "/demographics")}>
                                    <span>View Demographics Report</span>
                                    <img src={rightArrowBlue} alt="arrow" />
                                </Link>
                            </div>
                        }
                    </div>
                </div>

            }
        </>
    )
}

const mapStateToProps = (state: AppState) => {
    return {
        session: state.session,
        experiences: state.experiences.experienceIdList,
        globalView: state.experiences.globalView,
        demoBrandActive: state.experiences.demoBrandActive,
        dataList: state.data.demographics.scansByLocation,
        dates: state.dates.demographics.scansByLocation,
        loadingData: state.loading.demographics.scansByLocation
    }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
        getDataList: (dates: DateRange) => dispatch(getScansByLocation(dates)),
        updateDates: (dateRange: MomentRange) => dispatch(setDemographicsDateRange(dateRange, "scansByLocation"))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ScansByLocationMap)