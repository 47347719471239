import { AppState, Comparison, DateRange, HourCount, TableData, TimelineSlice } from "@declarations";
import { buildXapiQueryBase } from "Api/xAPI/QueryLibrary/query-builder";
import { xApiQueryLibrary } from "Api/xAPI/QueryLibrary/query-library";
import { bounceRateDetailsReturnType } from "Api/xAPI/QueryLibrary/visitor-behavior/bounceRates/buildBounceRateDetails";
import { bounceRateOverTimeReturnType } from "Api/xAPI/QueryLibrary/visitor-behavior/bounceRates/buildBounceRateOverTime";
import { clickRateDetailsReturnType } from "Api/xAPI/QueryLibrary/visitor-behavior/cta/buildClickRateDetails";
import { ctaOverTimeReturnType } from "Api/xAPI/QueryLibrary/visitor-behavior/cta/buildCTAOverTime";
import { mostTrafficDuringTimeOfDayReturnType } from "Api/xAPI/QueryLibrary/visitor-behavior/visitors/buildMostTrafficDuringTimeOfDay";
import { overallBounceRateReturnType } from "Api/xAPI/QueryLibrary/visitor-behavior/bounceRates/buildOverallBounceRate";
import { overallClickRateReturnType } from "Api/xAPI/QueryLibrary/visitor-behavior/cta/buildOverallClickRate";
import { topViewedEpisodesReturnType } from "Api/xAPI/QueryLibrary/visitor-behavior/topViewedEpisodes/buildTopViewedEpisodeToDate";
import { totalClicksToDateReturnType } from "Api/xAPI/QueryLibrary/visitor-behavior/cta/buildTotalClicksToDate";
import { totalEpisodesReturnType } from "Api/xAPI/QueryLibrary/visitor-behavior/topViewedEpisodes/buildTotalEpisodes";
import { totalScansToDateReturnType } from "Api/xAPI/QueryLibrary/visitor-behavior/visitors/buildTotalScansToDate";
import { totalVisitorsReturnType } from "Api/xAPI/QueryLibrary/visitor-behavior/visitors/buildTotalVisitors";
import { viewTimeReturnType } from "Api/xAPI/QueryLibrary/visitor-behavior/visitors/buildViewTime";
import { visitorsByTimeOfDayReturnType } from "Api/xAPI/QueryLibrary/visitor-behavior/visitors/buildVisitorsByTimeOfDay";
import { queryXapi } from "Api/xAPI/xapi";
import moment from "moment";
import { store } from "Store/store";
import { getPercentageComparison } from "Utils/data";
import { getCurrentMonthStart, getPreviousMonthStartAndEnd } from "Utils/dates";
import { heatMapDays } from "Utils/heatmap";
import { Constants } from "Values/constants";
import { getDemoDataBounceRates } from "Values/DemoData/VisitorBehavior/bounce-rates";
import { getDemoDataBounceRatesOverTime } from "Values/DemoData/VisitorBehavior/bounce-rates-over-time";
import { getDemoDataCTA } from "Values/DemoData/VisitorBehavior/cta";
import { getDemoDataCTAOverTime } from "Values/DemoData/VisitorBehavior/cta-over-time";
import { getDemoDataTopViewedEpisodes } from "Values/DemoData/VisitorBehavior/top-viewed-episodes";
import { getDemoDataVisitors } from "Values/DemoData/VisitorBehavior/visitors";
import { getDemoDataVisitorsByTimeOfDay } from "Values/DemoData/VisitorBehavior/visitors-by-time-of-day";
import { experienceIdRoot, xApiKeys } from "Values/xApiKeys";
import { Actions, makeAction } from "./ActionsTypes";

// LOADING
const setLoadingTopViewedEpisodes = (property: keyof AppState.LoadingTopViewedEpisodes, on: boolean) => async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    const currentLoading = getState().loading.visitorBehavior

    const loading: AppState.LoadingVisitorBehavior = {
        ...currentLoading,
        topViewedEpisodes: {
            ...currentLoading.topViewedEpisodes,
            [property]: on
        }
    }
    dispatch(makeAction(Actions.SET_LOADING_VISITOR_BEHAVIOR_DATA, loading))
}

const setLoadingVisitors = (property: keyof AppState.LoadingVisitors, on: boolean) => async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    const currentLoading = getState().loading.visitorBehavior

    const loading: AppState.LoadingVisitorBehavior = {
        ...currentLoading,
        visitors: {
            ...currentLoading.visitors,
            [property]: on
        }
    }
    dispatch(makeAction(Actions.SET_LOADING_VISITOR_BEHAVIOR_DATA, loading))
}

const setLoadingBounceRates = (property: keyof AppState.LoadingBounceRates, on: boolean) => async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    const currentLoading = getState().loading.visitorBehavior

    const loading: AppState.LoadingVisitorBehavior = {
        ...currentLoading,
        bounceRates: {
            ...currentLoading.bounceRates,
            [property]: on
        }
    }
    dispatch(makeAction(Actions.SET_LOADING_VISITOR_BEHAVIOR_DATA, loading))
}

const setLoadingCTA = (property: keyof AppState.LoadingCTA, on: boolean) => async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    const currentLoading = getState().loading.visitorBehavior

    const loading: AppState.LoadingVisitorBehavior = {
        ...currentLoading,
        cta: {
            ...currentLoading.cta,
            [property]: on
        }
    }
    dispatch(makeAction(Actions.SET_LOADING_VISITOR_BEHAVIOR_DATA, loading))
}

const setLoadingVisitorsByTimeOfDay = (property: keyof AppState.LoadingVisitorBehavior, on: boolean) => async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    const currentLoading = getState().loading.visitorBehavior

    const loading: AppState.LoadingVisitorBehavior = {
        ...currentLoading,
        [property]: on
    }
    dispatch(makeAction(Actions.SET_LOADING_VISITOR_BEHAVIOR_DATA, loading))
}


// SUMMARY DATA

export const getVBSnapshotData = () => async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    await dispatch(setLoadingTopViewedEpisodes("summaryData", true))
    await dispatch(setLoadingBounceRates("summaryData", true))
    await dispatch(setLoadingCTA("summaryData", true))
    await dispatch(setLoadingVisitors("summaryData", true))

    // initial states
    let topViewedEpisodeToDate = ""
    let totalAccumulatedViewTime = 0
    let overallBounceRateToDate = 0
    let totalClicksToDate = 0

    const { demoBrandActive, experienceIdList, experienceSummary } = getState().experiences

    if (demoBrandActive) {
        topViewedEpisodeToDate = "Spring 2021 Promo"
        totalAccumulatedViewTime = 2000
        overallBounceRateToDate = 51
        totalClicksToDate = 2956
    } else {

        const queries = []

        // top viewed episode
        const placed = [xApiKeys.Verbs.Placed]

        const base1 = buildXapiQueryBase(placed, "experiences", experienceIdList)
        const details1 = xApiQueryLibrary.visitorBehavior.topViewedEpisodes.summaryData.topViewedEpisodeToDate()
        const query1 = [...base1, ...details1]

        queries.push(query1)

        // total accumulated view time
        const closed = [xApiKeys.Verbs.ExperienceClosed]

        const base2 = buildXapiQueryBase(closed, "experiences", experienceIdList)
        const details2 = xApiQueryLibrary.visitorBehavior.visitors.summaryData.totalAccumulatedViewTime()
        const query2 = [...base2, ...details2]

        queries.push(query2)

        // overall bounce rate
        const verbs = [xApiKeys.Verbs.Experienced, xApiKeys.Verbs.Placed]

        const base3 = buildXapiQueryBase(verbs, "experiences", experienceIdList)
        const details3 = xApiQueryLibrary.visitorBehavior.bounceRates.summaryData.overallBounceRateToDate()
        const query3 = [...base3, ...details3]

        queries.push(query3)

        // total clicks to date

        const use = [xApiKeys.Verbs.Weblinked]

        const base4 = buildXapiQueryBase(use, "experiences", experienceIdList)
        const details4 = xApiQueryLibrary.visitorBehavior.cta.summaryData.totalClicksToDate()
        const query4 = [...base4, ...details4]

        queries.push(query4)

        try {
            const res = await Promise.all(queries.map(q => queryXapi(q)))
            const [res1, res2, res3, res4] = res

            // top viewed episode
            const result1 = (res1 as topViewedEpisodesReturnType)[0]
            const id = result1._id.replace(experienceIdRoot, '')
            topViewedEpisodeToDate = experienceSummary[id] ? `${experienceSummary[id].name}` : Constants.unavailable_data_label

            // total accumulated view time
            const result2 = (res2 as viewTimeReturnType)[0]
            totalAccumulatedViewTime = result2.totalDuration

            // overall bounce rate
            const result3 = (res3 as overallBounceRateReturnType)[0]
            overallBounceRateToDate = result3.rate

            // total clicks to date
            const result4 = (res4 as totalClicksToDateReturnType)[0]
            totalClicksToDate = result4.count

        } catch (e) {
            console.error("Error getting visitor behavior snapshot data", e)
        }

    }

    const currentState = getState().data.visitorBehavior
    const data: AppState.VisitorBehaviorData = {
        ...currentState,
        topViewedEpisodes: {
            ...currentState.topViewedEpisodes,
            summaryData: {
                ...currentState.topViewedEpisodes.summaryData,
                topViewedEpisodeToDate: topViewedEpisodeToDate,
            }
        },
        visitors: {
            ...currentState.visitors,
            summaryData: {
                ...currentState.visitors.summaryData,
                totalAccumulatedViewTime: totalAccumulatedViewTime
            }
        },
        bounceRates: {
            ...currentState.bounceRates,
            summaryData: {
                ...currentState.bounceRates.summaryData,
                overallBounceRateToDate: overallBounceRateToDate
            }
        },
        cta: {
            ...currentState.cta,
            summaryData: {
                ...currentState.cta.summaryData,
                totalClicksToDate: totalClicksToDate
            }
        }
    }

    dispatch(makeAction(Actions.SET_VISITOR_BEHAVIOR_DATA, data))

    await dispatch(setLoadingTopViewedEpisodes("summaryData", false))
    await dispatch(setLoadingBounceRates("summaryData", false))
    await dispatch(setLoadingCTA("summaryData", false))
    await dispatch(setLoadingVisitors("summaryData", false))
}

export const getTopViewedEpisodeSummaryData = () => async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    await dispatch(setLoadingTopViewedEpisodes("summaryData", true))

    // initial states
    let topViewedEpisodeToDate = ""
    let mostViewsOfAnEpisode = 0
    let avgViewTime = 0

    const { demoBrandActive, experienceIdList, experienceSummary } = getState().experiences

    if (demoBrandActive) {
        topViewedEpisodeToDate = "Spring 2021 Promo"
        mostViewsOfAnEpisode = 300
        avgViewTime = 6000
    } else {
        const queries = []

        // views
        const placed = [xApiKeys.Verbs.Placed]

        const base = buildXapiQueryBase(placed, "experiences", experienceIdList)
        const details = xApiQueryLibrary.visitorBehavior.topViewedEpisodes.summaryData.topViewedEpisodeToDate()
        const query = [...base, ...details]

        queries.push(query)

        // view time
        const closed = [xApiKeys.Verbs.ExperienceClosed]

        const base2 = buildXapiQueryBase(closed, "experiences", experienceIdList)
        const details2 = xApiQueryLibrary.visitorBehavior.topViewedEpisodes.summaryData.avgViewTime()
        const query2 = [...base2, ...details2]

        queries.push(query2)

        try {
            const res = await Promise.all(queries.map(q => queryXapi(q)))
            const [res1, res2] = res

            const result = (res1 as topViewedEpisodesReturnType)[0]
            const id = result._id.replace(experienceIdRoot, '')
            topViewedEpisodeToDate = experienceSummary[id] ? `${experienceSummary[id].name}` : Constants.unavailable_data_label
            mostViewsOfAnEpisode = result.count

            const result2 = (res2 as viewTimeReturnType)[0]
            avgViewTime = result2.avgDuration
        } catch (e) {
            console.error("Error getting top viewed episodes summary data", e)
        }
    }

    const currentState = getState().data.visitorBehavior
    const data: AppState.VisitorBehaviorData = {
        ...currentState,
        topViewedEpisodes: {
            ...currentState.topViewedEpisodes,
            summaryData: {
                topViewedEpisodeToDate: topViewedEpisodeToDate,
                mostViewsOfAnEpisode: mostViewsOfAnEpisode,
                avgViewTime: avgViewTime
            }
        }
    }

    dispatch(makeAction(Actions.SET_VISITOR_BEHAVIOR_DATA, data))

    await dispatch(setLoadingTopViewedEpisodes("summaryData", false))
}

export const getVisitorsSummaryData = () => async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    await dispatch(setLoadingVisitors("summaryData", true))

    // initial states
    let totalScansToDate = 0
    let totalAccumulatedViewTime = 0
    let mostTrafficDuringTimeOfDay: [string, string, string] = ["", "", ""]

    const { demoBrandActive, experienceIdList, brandInView, channelInView } = getState().experiences

    if (demoBrandActive) {
        totalScansToDate = 2659
        totalAccumulatedViewTime = 2000
        mostTrafficDuringTimeOfDay = ["5PM", "6PM", "(EST)"]
    } else {
        const queries = []

        // total scans to date
        const expd = [xApiKeys.Verbs.CodeEntered]

        const ids = []
        if (!channelInView?.id) {
            for (const c of brandInView?.channels) {
                ids.push(c.id)
            }
        } else {
            ids.push(channelInView.id)
        }

        const base1 = buildXapiQueryBase(expd, "channels", ids)
        const details1 = xApiQueryLibrary.visitorBehavior.visitors.summaryData.totalScansToDate()
        const query1 = [...base1, ...details1]

        queries.push(query1)

        // total accumulated view time
        const closed = [xApiKeys.Verbs.ExperienceClosed]

        const base2 = buildXapiQueryBase(closed, "experiences", experienceIdList)
        const details2 = xApiQueryLibrary.visitorBehavior.visitors.summaryData.totalAccumulatedViewTime()
        const query2 = [...base2, ...details2]

        queries.push(query2)

        // most traffic during time of day
        const exp = [xApiKeys.Verbs.Experienced]

        const base3 = buildXapiQueryBase(exp, "experiences", experienceIdList)
        const details3 = xApiQueryLibrary.visitorBehavior.visitors.summaryData.mostTrafficDuringTimeOfDay()
        const query3 = [...base3, ...details3]

        queries.push(query3)

        try {
            const res = await Promise.all(queries.map(q => queryXapi(q)))
            const [res1, res2, res3] = res

            // total scans to date
            const result1 = (res1 as totalScansToDateReturnType)[0]
            totalScansToDate = result1?.count || 0

            // total accumulated view time
            const result2 = (res2 as viewTimeReturnType)[0]
            totalAccumulatedViewTime = result2?.totalDuration || 0

            // most traffic during time of day
            const result3 = (res3 as mostTrafficDuringTimeOfDayReturnType)[0]
            const start = Number(result3.start) > 12 ? `${Number(result3.start) - 12}pm` : `${result3.start}am`
            const end = Number(result3.end) > 12 ? `${Number(result3.end) - 12}pm` : `${result3.end}am`
            mostTrafficDuringTimeOfDay = [start, end, "(EST)"]
        } catch (e) {
            console.error("Error getting visitors summary data", e)
        }
    }

    const currentState = getState().data.visitorBehavior
    const data: AppState.VisitorBehaviorData = {
        ...currentState,
        visitors: {
            ...currentState.visitors,
            summaryData: {
                totalScansToDate: totalScansToDate,
                totalAccumulatedViewTime: totalAccumulatedViewTime,
                mostTrafficDuringTimeOfDay: mostTrafficDuringTimeOfDay
            }
        }
    }

    dispatch(makeAction(Actions.SET_VISITOR_BEHAVIOR_DATA, data))

    await dispatch(setLoadingVisitors("summaryData", false))
}
export const getBounceRatesSummaryData = () => async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    await dispatch(setLoadingBounceRates("summaryData", true))

    // initial states
    let overallBounceRateToDate = 0
    let bouncRateComparedToLastMonth: Comparison = {
        comparison: null,
        value: 0
    }
    let avgSessionDuration = 0

    const { demoBrandActive, experienceIdList } = getState().experiences

    if (demoBrandActive) {
        overallBounceRateToDate = 50
        bouncRateComparedToLastMonth = {
            comparison: "Down",
            value: 10
        }
        avgSessionDuration = 2000
    } else {
        const queries = []

        // overall bounce rate
        const verbs = [xApiKeys.Verbs.Experienced, xApiKeys.Verbs.Placed]

        const base1 = buildXapiQueryBase(verbs, "experiences", experienceIdList)
        const details1 = xApiQueryLibrary.visitorBehavior.bounceRates.summaryData.overallBounceRateToDate()
        const query1 = [...base1, ...details1]

        queries.push(query1)

        // view time
        const closed = [xApiKeys.Verbs.ExperienceClosed]

        const base2 = buildXapiQueryBase(closed, "experiences", experienceIdList)
        const details2 = xApiQueryLibrary.visitorBehavior.bounceRates.summaryData.avgSessionDuration()
        const query2 = [...base2, ...details2]

        queries.push(query2)

        // bounce rate compared to last month

        // get this month's dates
        const thisMonthFirstDay = getCurrentMonthStart()
        const today = moment().valueOf()

        // get previous month's dates
        const prevMonthDateRange = getPreviousMonthStartAndEnd()

        // call total clicks for this month's dates
        const base3 = buildXapiQueryBase(verbs, "experiences", experienceIdList, { start: thisMonthFirstDay, end: today })
        const details3 = xApiQueryLibrary.visitorBehavior.bounceRates.summaryData.overallBounceRateToDate()
        const query3 = [...base3, ...details3]

        queries.push(query3)

        // call total clicks for last month's dates
        const base4 = buildXapiQueryBase(verbs, "experiences", experienceIdList, prevMonthDateRange)
        const details4 = xApiQueryLibrary.visitorBehavior.bounceRates.summaryData.overallBounceRateToDate()
        const query4 = [...base4, ...details4]

        queries.push(query4)

        try {
            const res = await Promise.all(queries.map(q => queryXapi(q)))

            const [res1, res2, res3, res4] = res

            // overall bounce rate
            const result1 = (res1 as overallBounceRateReturnType)[0]
            overallBounceRateToDate = result1.rate

            // view time
            const result2 = (res2 as viewTimeReturnType)[0]
            avgSessionDuration = result2.avgDuration

            // bounce rate comparison
            // compared to last month
            const result3 = (res3 as overallBounceRateReturnType)[0]
            const thisMonthRates = result3?.rate || 0

            const result4 = (res4 as overallBounceRateReturnType)[0]
            const prevMonthRates = result4?.rate || 0

            bouncRateComparedToLastMonth = getPercentageComparison(prevMonthRates, thisMonthRates, true)
        } catch (e) {
            console.error("Error getting bounce rates summary data")
        }
    }

    const currentState = getState().data.visitorBehavior
    const data: AppState.VisitorBehaviorData = {
        ...currentState,
        bounceRates: {
            ...currentState.bounceRates,
            summaryData: {
                overallBounceRateToDate: overallBounceRateToDate,
                bouncRateComparedToLastMonth: bouncRateComparedToLastMonth,
                avgSessionDuration: avgSessionDuration
            }
        }
    }

    dispatch(makeAction(Actions.SET_VISITOR_BEHAVIOR_DATA, data))

    await dispatch(setLoadingBounceRates("summaryData", false))
}
export const getCTASummaryData = () => async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    await dispatch(setLoadingCTA("summaryData", true))

    // initial states
    let totalClicksToDate = 0
    let ctaComparedToLastMonth: Comparison = {
        comparison: null,
        value: 0
    }
    let avgClickRate = 0


    const { demoBrandActive, experienceIdList } = getState().experiences

    if (demoBrandActive) {
        totalClicksToDate = 1268
        ctaComparedToLastMonth = {
            comparison: "Up",
            value: 19
        }
        avgClickRate = 51
    } else {
        const queries = []

        // total clicks to date
        const use = [xApiKeys.Verbs.Weblinked]

        const base1 = buildXapiQueryBase(use, "experiences", experienceIdList)
        const details1 = xApiQueryLibrary.visitorBehavior.cta.summaryData.totalClicksToDate()
        const query1 = [...base1, ...details1]

        queries.push(query1)

        // cta compared to last month

        // get this month's dates
        const thisMonthFirstDay = getCurrentMonthStart()
        const today = moment().valueOf()

        // get previous month's dates
        const prevMonthDateRange = getPreviousMonthStartAndEnd()

        // call total clicks for this month's dates
        const base2 = buildXapiQueryBase(use, "experiences", experienceIdList, { start: thisMonthFirstDay, end: today })
        const details2 = xApiQueryLibrary.visitorBehavior.cta.summaryData.totalClicksToDate()
        const query2 = [...base2, ...details2]

        queries.push(query2)

        // call total clicks for last month's dates
        const base3 = buildXapiQueryBase(use, "experiences", experienceIdList, prevMonthDateRange)
        const details3 = xApiQueryLibrary.visitorBehavior.cta.summaryData.totalClicksToDate()
        const query3 = [...base3, ...details3]

        queries.push(query3)

        // overall click rate
        const verbs = [xApiKeys.Verbs.Experienced, xApiKeys.Verbs.Weblinked]

        const base4 = buildXapiQueryBase(verbs, "experiences", experienceIdList)
        const details4 = xApiQueryLibrary.visitorBehavior.cta.summaryData.avgClickRate()
        const query4 = [...base4, ...details4]

        queries.push(query4)

        let res: any[] = []

        try {
            res = await Promise.all(queries.map(q => queryXapi(q)))
            const [res1, res2, res3, res4] = res

            // overall bounce rate
            const result1 = (res1 as totalClicksToDateReturnType)[0]
            totalClicksToDate = result1.count

            // compared to last month
            const result2 = (res2 as totalClicksToDateReturnType)[0]
            const thisMonthClicks = result2?.count || 0

            const result3 = (res3 as totalClicksToDateReturnType)[0]
            const prevMonthClicks = result3?.count || 0

            ctaComparedToLastMonth = getPercentageComparison(prevMonthClicks, thisMonthClicks)

            // overall click rate
            const result4 = (res4 as overallClickRateReturnType)[0]
            avgClickRate = result4.rate
        }
        catch (e) {
            console.error("Error getting CTA Summary Data", e)
        }

    }

    const currentState = getState().data.visitorBehavior
    const data: AppState.VisitorBehaviorData = {
        ...currentState,
        cta: {
            ...currentState.cta,
            summaryData: {
                totalClicksToDate: totalClicksToDate,
                ctaComparedToLastMonth: ctaComparedToLastMonth,
                avgClickRate: avgClickRate

            }
        }
    }

    dispatch(makeAction(Actions.SET_VISITOR_BEHAVIOR_DATA, data))

    await dispatch(setLoadingCTA("summaryData", false))
}

// TABLES

export const getTopViewedEpisodes = () => async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    await dispatch(setLoadingTopViewedEpisodes("topViewedEpisodes", true))

    let n: TableData.Episode[] = []
    const { demoBrandActive, experienceIdList, experienceSummary } = getState().experiences

    if (demoBrandActive) {
        n = getDemoDataTopViewedEpisodes(100)
    } else {
        const verbs = [xApiKeys.Verbs.Experienced, xApiKeys.Verbs.Weblinked, xApiKeys.Verbs.ExperienceClosed]

        const base1 = buildXapiQueryBase(verbs, "experiences", experienceIdList)
        const details1 = xApiQueryLibrary.visitorBehavior.topViewedEpisodes.topViewedEpisodes()
        const query = [...base1, ...details1]

        try {
            const res: totalEpisodesReturnType = await queryXapi(query)

            for (const r of res) {
                const id = r._id.replace(experienceIdRoot, '')
                const title = experienceSummary[id] ? `${experienceSummary[id].name}` : Constants.unavailable_data_label
                const ep: TableData.Episode = {
                    episodeTitle: title,
                    launchDate: new Date(r.date).valueOf(),
                    opens: r.scans,
                    sessions: r.sessions,
                    averageTimeViewed: r.avgDuration,
                    totalTimeViewed: r.totalDuration
                }
                n.push(ep)
            }
        } catch (e) {
            console.error("Error fetching top viewed episodes table", e)
        }

    }

    const currentState = getState().data.visitorBehavior
    const data: AppState.VisitorBehaviorData = {
        ...currentState,
        topViewedEpisodes: {
            ...currentState.topViewedEpisodes,
            topViewedEpisodes: n
        }
    }
    dispatch(makeAction(Actions.SET_VISITOR_BEHAVIOR_DATA, data))

    await dispatch(setLoadingTopViewedEpisodes("topViewedEpisodes", false))

}

export const getVisitors = () => async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    await dispatch(setLoadingVisitors("visitors", true))

    let n: TableData.Visitor[] = []

    const { demoBrandActive, experienceIdList } = getState().experiences

    if (demoBrandActive) {
        n = getDemoDataVisitors(100)
    } else {

        const verbs = [xApiKeys.Verbs.Experienced, xApiKeys.Verbs.Weblinked, xApiKeys.Verbs.ExperienceClosed]

        const base1 = buildXapiQueryBase(verbs, "experiences", experienceIdList)
        const details1 = xApiQueryLibrary.visitorBehavior.visitors.visitors()
        const query = [...base1, ...details1]

        try {
            const res: totalVisitorsReturnType = await queryXapi(query)

            for (const r of res) {
                const ep: TableData.Visitor = {
                    userId: r._id,
                    lastDateAccess: new Date(r.date).valueOf(),
                    opens: r.scans,
                    sessions: r.sessions,
                    averageTimeViewed: r.avgDuration,
                    totalTimeViewed: r.totalDuration
                }
                n.push(ep)
            }
        } catch (e) {
            console.error("Error fetching visitors table", e)
        }
    }

    const currentState = getState().data.visitorBehavior
    const data: AppState.VisitorBehaviorData = {
        ...currentState,
        visitors: {
            ...currentState.visitors,
            visitors: n
        }
    }
    dispatch(makeAction(Actions.SET_VISITOR_BEHAVIOR_DATA, data))

    await dispatch(setLoadingVisitors("visitors", false))

}

export const getBounceRates = (dates: DateRange) => async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    await dispatch(setLoadingBounceRates("bounceRates", true))

    let n: TableData.BounceRate[] = []

    const { demoBrandActive, experienceIdList, experienceSummary } = getState().experiences

    if (demoBrandActive) {
        n = getDemoDataBounceRates(100)
    } else {

        const verbs = [xApiKeys.Verbs.Experienced, xApiKeys.Verbs.ExperienceClosed, xApiKeys.Verbs.Placed]

        const base1 = buildXapiQueryBase(verbs, "experiences", experienceIdList, dates)
        const details1 = xApiQueryLibrary.visitorBehavior.bounceRates.bounceRates()
        const query = [...base1, ...details1]

        try {
            const res: bounceRateDetailsReturnType = await queryXapi(query)

            for (const r of res) {
                const id = r._id.replace(experienceIdRoot, '')
                const title = experienceSummary[id] ? `${experienceSummary[id].name}` : Constants.unavailable_data_label

                const ep: TableData.BounceRate = {
                    episodeTitle: title,
                    datePosted: new Date(r.date).valueOf(),
                    opens: r.scans,
                    bounceRate: r.bounceRate,
                    averageTimeDroppedOff: r.avgDuration,
                    totalTimeViewed: r.totalDuration
                }
                n.push(ep)
            }
        } catch (e) {
            console.error("Error fetching bounce rates table", e)
        }
    }
    const currentState = getState().data.visitorBehavior
    const data: AppState.VisitorBehaviorData = {
        ...currentState,
        bounceRates: {
            ...currentState.bounceRates,
            bounceRates: n
        }
    }
    dispatch(makeAction(Actions.SET_VISITOR_BEHAVIOR_DATA, data))

    await dispatch(setLoadingBounceRates("bounceRates", false))

}

export const getCTA = (dates: DateRange) => async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    await dispatch(setLoadingCTA("cta", true))

    let n: TableData.CTA[] = []

    const { demoBrandActive, experienceIdList, experienceSummary } = getState().experiences

    if (demoBrandActive) {
        n = getDemoDataCTA(100)
    } else {
        // total clicks to date
        const verbs = [xApiKeys.Verbs.Experienced, xApiKeys.Verbs.Weblinked, xApiKeys.Verbs.ExperienceClosed, xApiKeys.Verbs.Placed]

        const base1 = buildXapiQueryBase(verbs, "experiences", experienceIdList, dates)
        const details1 = xApiQueryLibrary.visitorBehavior.cta.cta()
        const query = [...base1, ...details1]

        try {
            const res: clickRateDetailsReturnType = await queryXapi(query)

            for (const r of res) {
                const id = r._id.replace(experienceIdRoot, '')
                const title = experienceSummary[id] ? `${experienceSummary[id].name}` : Constants.unavailable_data_label

                const ep: TableData.CTA = {
                    episodeTitle: title,
                    nameOfCTA: r.name || Constants.unavailable_data_label,
                    datePosted: new Date(r.date).valueOf(),
                    views: r.views,
                    clicks: r.clicks || 0,
                    clickRate: r.clickRate,
                }
                n.push(ep)
            }
        } catch (e) {
            console.error("Error fetching CTA table", e)
        }
    }

    const currentState = getState().data.visitorBehavior
    const data: AppState.VisitorBehaviorData = {
        ...currentState,
        cta: {
            ...currentState.cta,
            cta: n
        }
    }
    dispatch(makeAction(Actions.SET_VISITOR_BEHAVIOR_DATA, data))

    await dispatch(setLoadingCTA("cta", false))

}

export const getBounceRatesOverTime = (dates: DateRange) => async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    await dispatch(setLoadingBounceRates("bounceRatesOverTime", true))

    let n: TimelineSlice[] = []

    const { demoBrandActive, experienceIdList } = getState().experiences

    if (demoBrandActive) {
        n = getDemoDataBounceRatesOverTime(dates, 100)
    } else {
        const verbs = [xApiKeys.Verbs.Placed, xApiKeys.Verbs.Experienced]

        const base = buildXapiQueryBase(verbs, "experiences", experienceIdList, dates)
        const details = xApiQueryLibrary.visitorBehavior.bounceRates.bounceRatesOverTime()
        const query = [...base, ...details]

        try {
            const res: bounceRateOverTimeReturnType = await queryXapi(query)
            n = res
        }
        catch (e) {
            console.error("Error getting bounce rates over time", e)
        }
    }

    const currentState = getState().data.visitorBehavior
    const data: AppState.VisitorBehaviorData = {
        ...currentState,
        bounceRates: {
            ...currentState.bounceRates,
            bounceRatesOverTime: n
        }
    }
    dispatch(makeAction(Actions.SET_VISITOR_BEHAVIOR_DATA, data))

    await dispatch(setLoadingBounceRates("bounceRatesOverTime", false))

}

export const getCTAOverTime = (dates: DateRange) => async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    await dispatch(setLoadingCTA("ctaOvertime", true))

    let n: TimelineSlice[] = []

    const { demoBrandActive, experienceIdList } = getState().experiences

    if (demoBrandActive) {
        n = getDemoDataCTAOverTime(dates, 100)
    } else {
        const verbs = [xApiKeys.Verbs.Weblinked]

        const base = buildXapiQueryBase(verbs, "experiences", experienceIdList, dates)
        const details = xApiQueryLibrary.visitorBehavior.cta.ctaOvertime()
        const query = [...base, ...details]

        try {
            const res: ctaOverTimeReturnType = await queryXapi(query)
            n = res
        }
        catch (e) {
            console.error("Error getting cta clicks over time", e)
        }
    }

    const currentState = getState().data.visitorBehavior
    const data: AppState.VisitorBehaviorData = {
        ...currentState,
        cta: {
            ...currentState.cta,
            ctaOvertime: n
        }
    }
    dispatch(makeAction(Actions.SET_VISITOR_BEHAVIOR_DATA, data))

    await dispatch(setLoadingCTA("ctaOvertime", false))

}

export const getVisitorsByTimeOfDay = (dates: DateRange) => async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    await dispatch(setLoadingVisitorsByTimeOfDay("visitors", true))

    let n: HourCount[] = []

    const { demoBrandActive, experienceIdList } = getState().experiences

    if (demoBrandActive) {
        n = getDemoDataVisitorsByTimeOfDay(100)
    } else {
        const verbs = [xApiKeys.Verbs.Experienced]

        const base = buildXapiQueryBase(verbs, "experiences", experienceIdList, dates)
        const details = xApiQueryLibrary.visitorBehavior.visitorsByTimeOfDay()
        const query = [...base, ...details]

        try {
            const res: visitorsByTimeOfDayReturnType = await queryXapi(query)
            const result: HourCount[] = []

            for (const r of res) {
                const newR: HourCount = {
                    day: heatMapDays[r.day - 1],
                    hour: r.hour > 12 ? `${r.hour - 12}PM` : `${r.hour}AM`,
                    count: r.count
                }
                result.push(newR)
            }
            n = result
        } catch (e) {
            console.error("Error getting visitors by time of day", e)
        }
    }

    const currentState = getState().data.visitorBehavior
    const data: AppState.VisitorBehaviorData = {
        ...currentState,
        visitorsByTimeOfDay: n
    }
    dispatch(makeAction(Actions.SET_VISITOR_BEHAVIOR_DATA, data))

    await dispatch(setLoadingVisitorsByTimeOfDay("visitors", false))

}