import axios, { AxiosResponse } from "axios";
import { Constants } from "Values/constants";

export const login = async (email: string, password: string) => {

    const body = JSON.stringify({ email, password, method: "new" });

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: body,
        redirect: 'follow',
        credentials: 'include'
    };
    const path = "/accounts/auth";
    const response = await fetch(Constants.arc_url + path, requestOptions);

    if (response.status !== 200) throw (await response.json())
    return response.json()
}

export const logout = async () => {
    const response: AxiosResponse = await axios.get('/accounts/logout');
    console.log("Log out response", response)
    if (response.status === 200) sessionStorage.clear()
};

export const getJWT = async () => {
    const response: AxiosResponse = await axios.get(`${Constants.arc_url}/xapi/jwt`)
    if (response.status !== 200) throw (response.data)
    return response.data
}

export const getSession = async () => {
    const response: AxiosResponse = await axios.get(`${Constants.arc_url}/accounts/me`)
    if (response.status !== 200) throw (response.data)
    return response.data
}

export const refreshToken = async (): Promise<any> => {
    const raw = JSON.stringify({});

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: raw,
        redirect: 'follow',
        credentials: 'include'
    };

    const path = "/accounts/auth/refresh";
    return fetch(Constants.arc_url + path, requestOptions);
}


export const handle401 = async (error: any): Promise<any> => {

    const originalRequest = error.config

    if (error.response && error.response.status === 401 && !originalRequest?._retry) {

        if (error.response.data.message === "Expired token") {

            originalRequest._retry = true

            const response = await refreshToken();
            if (response.status === 200) {
                return axios(originalRequest);
            }
        }

        await logout();
    }
    return new Promise((_, reject) => reject(error));
}