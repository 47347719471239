export const Constants = {
    arc_url: "https://api.nextechar.com",
    xapi_url: "https://nexttech.lrs.io/dev/xapi/statements/aggregate",
    mobile_breakpoint: 1260,
    side_menu_width: 224,
    chart_width_difference: 350,
    timeline_bucket: 30,
    default_timeline_time_sampling_format: "%Y-%m-%dT00:00:00.000Z",
    unavailable_data_label: "Unavailable",
    default_timezone: "America/Toronto",
    session_threshold: 10 // in seconds
}