import { Breakdown, DateRange, } from "@declarations"
import { getRandomNumber } from "Utils/numbers"

const titles = [
    "on",
    "off"
]

export const getDemoDataPushNotificationBreakdown = (dates: DateRange, count: number): Breakdown[] => {
    const br: Breakdown[] = []

    for (let i = 1; i <= count; i++) {
        const ep: Breakdown = {
            _id: titles[getRandomNumber(0, titles.length - 1)],
            count: getRandomNumber(1, 25),
        }
        br.push(ep)

    }

    return br
}