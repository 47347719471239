import { AppState, DateRange, PageProps, Session } from "@declarations"
import { getCTA } from "Store/Actions/DataActions-VisitorBehavior"
import Table from "Components/Table/Table"
import { useEffect } from "react"
import Skeleton from "react-loading-skeleton"
import { connect } from "react-redux"
import { store } from "Store/store"
import { callCheck } from "Utils/data"

interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {
}

const CTATable = (props: Props) => {

    const { session, globalView, experiences, dataList, getDataList, loadingData, dates, demoBrandActive } = props

    useEffect(() => {

        if (!callCheck(globalView, (session as Session.AuthSession).user.admin, experiences?.length)) return

        getDataList(dates)

    }, [experiences, getDataList, session, demoBrandActive, dates, globalView])

    return (
        <div >
            {
                loadingData
                    ? <Skeleton height={500} className="table-skeleton-shared-box" />
                    : dataList && dataList.length
                        ? <Table
                            title="Call to action details"
                            titleTooltip=""
                            showTitle={true}
                            headers={Object.keys(dataList[0])}
                            sortable={["clicks", "clickRate"]}
                            tooltipable={[]}
                            data={dataList}
                            limit={20}
                            pagination={true}
                        />
                        : null
            }
        </div>
    )
}

const mapStateToProps = (state: AppState) => {
    return {
        session: state.session,
        experiences: state.experiences.experienceIdList,
        globalView: state.experiences.globalView,
        demoBrandActive: state.experiences.demoBrandActive,
        dataList: state.data.visitorBehavior.cta.cta,
        loadingData: state.loading.visitorBehavior.cta.cta,
        dates: state.dates.visitorBehavior.ctaClicksOverTime
    }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
        getDataList: (dates: DateRange) => dispatch(getCTA(dates))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CTATable)