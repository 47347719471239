import Skeleton from 'react-loading-skeleton';
import React from "react"
import { RadialChart } from "react-vis"

import "./DonutChart.css";
import Legend from "Components/Legend/Legend";
import { DonutData, LegendItem } from "@declarations";
import { getTotalAngles } from "Utils/donutData";

interface Props {
    data: DonutData[],
    bigText: string,
    width: number,
    height: number
    smallText?: string,
    loading?: boolean,
}
const DonutChart: React.FC<Props> = (props) => {

    const { data } = props

    const totalAngles = getTotalAngles(data)

    const legendData: LegendItem[] = data.map((item, index) => {
        const anglePercentage = (item.angle / totalAngles) * 100
        return { title: item.label, value: `${anglePercentage.toFixed(0)}%`, color: item.color, order: index }
    })

    return <div style={{ width: props.width }}>
        <div className="cardBody">
            {props.loading ? <Skeleton circle={true} width={120} height={120} /> :
                <div className="chart-side">
                    <RadialChart className="radialChart"
                        padAngle={0.05}
                        colorType="literal"
                        showLabels={false}
                        labelsRadiusMultiplier={1.4}
                        animation={true}
                        data={data}
                        width={220}
                        height={220}
                        innerRadius={50}
                        radius={70}
                        labelsStyle={{ fontSize: '1rem' }}
                    >
                    </RadialChart>
                    <div className="total">
                        <p>Total</p>
                        <h2>{totalAngles}</h2>
                    </div>
                </div>}
            <div className="info-side">
                <Legend items={legendData} />
            </div>

        </div>
    </div>

}

export default DonutChart