import { DateRange, TimelineSlice } from "@declarations"
import { A_DAY } from "Utils/dates"
import { getRandomNumber } from "Utils/numbers"

export const getDemoDataCTAOverTime = (dates: DateRange, count: number): TimelineSlice[] => {
    const br: TimelineSlice[] = []

    let min = 100
    let max = 2500

    for (let i = 1; i <= count; i++) {
        const d = new Date().setTime(dates.start + A_DAY * (i - 1))

        min += 100
        max += 100

        const ep: TimelineSlice = {
            count: getRandomNumber(min, max),
            instance: d < dates.end ? new Date(d).toISOString() : new Date(dates.end).toISOString()
        }
        br.push(ep)

        if (d === dates.end) break
    }

    return br
}