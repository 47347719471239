import { DateRange, TimelineSliceByLocation } from "@declarations"
import { A_DAY } from "Utils/dates"
import { getRandomNumber } from "Utils/numbers"


const places = [
    "Ontario, Canada",
    "British Columbia, Canada",
    "Texas, United States",
    "California, United States"
]

export const getDemoDataScansOvertimeByLocation = (dates: DateRange, count: number): TimelineSliceByLocation[] => {
    const episodes: TimelineSliceByLocation[] = []

    let min = 100
    let max = 2500

    for (let i = 1; i <= count; i++) {
        const place = places[getRandomNumber(0, places.length - 1)]
        const d = new Date().setTime(dates.start + A_DAY * (i - 1))

        min += 100
        max += 100

        const ep: TimelineSliceByLocation = {
            count: getRandomNumber(min, max),
            instance: d < dates.end ? new Date(d).toISOString() : new Date(dates.end).toISOString(),
            country: place.split(",")[1].trim(),
            region: place.split(",")[0].trim()
        }
        episodes.push(ep)

        if (d === dates.end) break
    }

    return episodes
}