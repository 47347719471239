import { Constants } from "Values/constants"

const returnExample = [
    {
        "_id": "d878f18a690f3c88540634180858102432115dcc",
        "date": "2021-06-12T22:49:29.925Z",
        "scans": 0,
        "sessions": 2,
        "avgDuration": 79.5,
        "totalDuration": 159
    }
]

export type totalVisitorsReturnType = typeof returnExample

const buildTotalVisitors = (): any[] => [
    {
        "$group": {
            "_id": {
                "actor": "$statement.actor.id",
                "verb": "$statement.verb.id"
            },
            "count": {
                "$sum": 1
            },
            "date": {
                "$push": {
                    "time": "$statement.timestamp"
                }
            },
            "secondsFromStart": {
                "$push": {
                    "seconds": "$EXT.https://aritize*`*nextechar*`*com/secondsfromstart"
                }
            }
        }
    },
    {
        "$facet": {
            "Launch Date": [
                {
                    "$match": {
                        "_id.verb": "http://activitystrea.ms/schema/1.0/experience"
                    }
                },
                {
                    "$group": {
                        "_id": "$_id.actor",
                        "date": {
                            "$max": "$date.time"
                        }
                    }
                },
            ],
            "Scans": [
                {
                    "$match": {
                        "_id.verb": "http://activitystrea.ms/schema/1.0/experience",
                    }
                },
                {
                    "$group": {
                        "_id": "$_id.actor",
                        "scanCount": {
                            "$sum": "$count"
                        }
                    }
                }
            ],
            "Sessions": [
                {
                    "$match": {
                        "_id.verb": "http://activitystrea.ms/schema/1.0/close",
                        "secondsFromStart.seconds": {
                            "$gte": Constants.session_threshold
                        }
                    }
                },
                {
                    "$project": {
                        "_id": "$_id.actor",
                        "sessionCount": {
                            "$size": "$secondsFromStart.seconds"
                        },
                    }
                }
            ],
            "Average Time Viewed": [
                {
                    "$match": {
                        "_id.verb": "http://activitystrea.ms/schema/1.0/close",
                    }
                },
                {
                    "$unwind": "$secondsFromStart"
                },
                {
                    "$group": {
                        "_id": "$_id.actor",
                        "avgDuration": {
                            "$avg": "$secondsFromStart.seconds"
                        }
                    }
                }
            ],
            "Total Time Viewed": [
                {
                    "$match": {
                        "_id.verb": "http://activitystrea.ms/schema/1.0/close",
                    }
                },
                {
                    "$unwind": "$secondsFromStart"
                },
                {
                    "$group": {
                        "_id": "$_id.actor",
                        "totalDuration": {
                            "$sum": "$secondsFromStart.seconds"
                        }
                    }
                }
            ],
        }
    },
    {
        "$project": {
            "all": {
                "$concatArrays": [
                    "$Launch Date",
                    "$Scans",
                    "$Sessions",
                    "$Average Time Viewed",
                    "$Total Time Viewed"
                ]
            }
        }
    },
    {
        "$unwind": "$all"
    },
    {
        "$group": {
            "_id": "$all._id",
            "date": {
                "$first": {
                    "$max": "$all.date"
                }
            },
            "scans": {
                "$sum": "$all.scanCount"
            },
            "sessions": {
                "$sum": "$all.sessionCount"
            },
            "avgDuration": {
                "$avg": "$all.avgDuration"
            },
            "totalDuration": {
                "$sum": "$all.totalDuration"
            },
        }
    },
]
export default buildTotalVisitors