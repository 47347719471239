import { AppState, PageProps, Session } from "@declarations"
import { getPushNotificationsCTR } from "Store/Actions/DataActions-Notifications"
import Table from "Components/Table/Table"
import { useEffect } from "react"
import Skeleton from "react-loading-skeleton"
import { connect } from "react-redux"
import { store } from "Store/store"
import EmptyState from "Components/EmptyState/EmptyState"
import { callCheck } from "Utils/data"

interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {
}

const PushNotificationsCTRTable = (props: Props) => {

    const { session, globalView, experiences, dataList, getDataList, loadingData, demoBrandActive } = props

    useEffect(() => {

        if (!callCheck(globalView, (session as Session.AuthSession).user.admin, experiences?.length)) return

        getDataList()

    }, [experiences, getDataList, session, demoBrandActive, globalView])

    return (
        <div className="box">
            {
                loadingData
                    ? <Skeleton height={250} />
                    : dataList && dataList.length
                        ? <Table
                            title="Push Notifications with Highest CTR's"
                            titleTooltip="CTR stands for click-through rate: a metric that measures the number of clicks advertisers receive on their ads per number of impressions."
                            showTitle={true}
                            headers={Object.keys(dataList[0])}
                            searchBy={["notificationTitle"]}
                            searchByPlaceholder="Search episode"
                            sortable={["ctr"]}
                            data={dataList}
                            limit={10}
                            pagination={true}
                            showDownloadCSV={true}
                            dividedHeader={true}
                        />
                        : <EmptyState filterableData={false} title="Push Notifications with Highest CTR's" />
            }
        </div>
    )
}

const mapStateToProps = (state: AppState) => {
    return {
        session: state.session,
        experiences: state.experiences.experienceIdList,
        globalView: state.experiences.globalView,
        demoBrandActive: state.experiences.demoBrandActive,
        dataList: state.data.notifications.pushNotificationsCTR,
        loadingData: state.loading.notifications.pushNotificationsCTR
    }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
        getDataList: () => dispatch(getPushNotificationsCTR())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PushNotificationsCTRTable)