import { TableData } from "@declarations"
import { getRandomNumber } from "Utils/numbers"

const titles = [
    "Spring 2021 Promo",
    "Summer 2021 Promo",
    "Fall 2021 Promo",
    "Winter 2021 Promo",
]

const ctas = [
    "Buy Now",
    "Learn More",
    "Contact Us"
]

export const getDemoDataCTA = (count: number): TableData.CTA[] => {
    const br: TableData.CTA[] = []

    for (let i = 1; i <= count; i++) {
        const ep: TableData.CTA = {
            episodeTitle: titles[getRandomNumber(0, titles.length - 1)],
            nameOfCTA: ctas[getRandomNumber(0, ctas.length - 1)],
            datePosted: new Date().valueOf(),
            views: getRandomNumber(1, 25),
            clicks: getRandomNumber(1, 25),
            clickRate: getRandomNumber(1, 25)
        }
        br.push(ep)
    }

    return br
}