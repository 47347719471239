import { AppState, Session } from "@declarations"
import { getJWT, login, getSession } from "Api/Auth/auth"
import { getBrandInfo } from "Api/Experiences/experiences"
import { store } from "Store/store"
import { Actions, makeAction } from "./ActionsTypes"

export const updateSession = (user?: Session.UserInfo) => async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    if (!user) dispatch(makeAction(Actions.SET_SESSION, { state: "Verifying" }))

    try {
        let userDetails: Session.UserInfo;

        // ensure user details
        if (user) userDetails = user;
        else userDetails = await getSession();

        // get JWT for querying xAPI
        const jwtRes = await getJWT();
        const { token } = jwtRes
        sessionStorage.setItem('token', token)

        // if not admin, ensure brand name associated with account
        if (!userDetails.admin) {
            const brand: AppState.Brand = await getBrandInfo(userDetails.brandId);
            userDetails.brandName = brand.name;
        }

        const sessionInfo: Session = {
            user: userDetails as Session.UserInfo,
            state: "Auth"
        }

        dispatch(makeAction(Actions.SET_SESSION, sessionInfo))

    } catch (e) {
        console.error("Error updating session", e)
        dispatch(makeAction(Actions.SET_SESSION, { state: "Guest" }))
    }

}

export const sessionLogin = (email: string, password: string) => async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    dispatch(makeAction(Actions.SET_SESSION, { state: "LoggingIn" }))

    try {
        const res: Session.UserInfo = await login(email, password)
        console.log("Login response:", res)
        dispatch(updateSession(res))
    }
    catch (e) {
        console.error("Error logging in", e)
        dispatch(makeAction(Actions.SET_SESSION, { state: "Guest", error: e.message }))
    }

}

