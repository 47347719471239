import { Constants } from "Values/constants"

const returnExample = [
    {
        "_id": "https://aritize.nextechar.com/experience/2aa6c76a-9900-42a9-8204-48ced69ab5f6",
        "date": "2020-09-25T17:10:46.330Z",
        "scans": 0,
        "sessions": 158,
        "avgDuration": 46,
        "totalDuration": 7268
    },
]

export type totalEpisodesReturnType = typeof returnExample

const buildTotalEpisodes = (): any[] => [

    {
        "$group": {
            "_id": {
                "object": "$statement.object.id",
                "verb": "$statement.verb.id"
            },
            "count": {
                "$sum": 1
            },
            "date": {
                "$push": {
                    "time": "$statement.timestamp"
                }
            },
            "secondsFromStart": {
                "$push": {
                    "seconds": "$EXT.https://aritize*`*nextechar*`*com/secondsfromstart"
                }
            }
        }
    },
    {
        "$facet": {
            "Launch Date": [
                {
                    "$match": {
                        "_id.verb": "http://activitystrea.ms/schema/1.0/experience"
                    }
                },
                {
                    "$group": {
                        "_id": "$_id.object",
                        "date": {
                            "$min": "$date.time"
                        },
                    }
                }
            ],
            "Scans": [
                {
                    "$match": {
                        "_id.verb": "http://activitystrea.ms/schema/1.0/experience",
                    }
                },
                {
                    "$group": {
                        "_id": "$_id.object",
                        "scanCount": {
                            "$sum": "$count"
                        }
                    }
                }
            ],
            "Sessions": [
                {
                    "$match": {
                        "_id.verb": "http://activitystrea.ms/schema/1.0/close",
                        "secondsFromStart.seconds": {
                            "$gte": Constants.session_threshold
                        }
                    }
                },
                {
                    "$project": {
                        "_id": "$_id.object",
                        "sessionCount": {
                            "$size": "$secondsFromStart.seconds"
                        },
                    }
                }
            ],
            "Average Time Viewed": [
                {
                    "$match": {
                        "_id.verb": "http://activitystrea.ms/schema/1.0/close",
                    }
                },
                {
                    "$unwind": "$secondsFromStart"
                },
                {
                    "$group": {
                        "_id": "$_id.object",
                        "avgDuration": {
                            "$avg": "$secondsFromStart.seconds"
                        }
                    }
                }
            ],
            "Total Time Viewed": [
                {
                    "$match": {
                        "_id.verb": "http://activitystrea.ms/schema/1.0/close",
                    }
                },
                {
                    "$unwind": "$secondsFromStart"
                },
                {
                    "$group": {
                        "_id": "$_id.object",
                        "totalDuration": {
                            "$sum": "$secondsFromStart.seconds"
                        }
                    }
                }
            ],
        }
    },
    {
        "$project": {
            "all": {
                "$concatArrays": [
                    "$Launch Date",
                    "$Scans",
                    "$Sessions",
                    "$Average Time Viewed",
                    "$Total Time Viewed"
                ]
            }
        }
    },
    {
        "$unwind": "$all"
    },
    {
        "$group": {
            "_id": "$all._id",
            "date": {
                "$first": {
                    "$min": "$all.date"
                }
            },
            "scans": {
                "$sum": "$all.scanCount"
            },
            "sessions": {
                "$sum": "$all.sessionCount"
            },
            "avgDuration": {
                "$avg": "$all.avgDuration"
            },
            "totalDuration": {
                "$sum": "$all.totalDuration"
            },
        }
    },
]
export default buildTotalEpisodes