import "./EmptyState.css"
import EmptyStateIcon from "Assets/images/data/empty-state/empty-state.svg"

type Props = {
    title?: string
    filterableData: boolean
}

const EmptyState = (props: Props) => {

    const { title, filterableData } = props

    return (
        <div className="empty-state-wrapper">
            {title && (
                <>
                    <h3 className="title">{title}</h3>
                    <br />
                </>
            )}
            <img src={EmptyStateIcon} alt="empty state icon" />
            <h4>No data available</h4>
            {
                filterableData
                    ? <p>There is no available data to show.<br />
                        Please choose different filters and try again.</p>
                    : <p>There is no data to report yet.<br />
                        Please come back later.</p>
            }
        </div>
    )
}

export default EmptyState