import { Constants } from "Values/constants"
import { xApiStatementKeys } from "Values/xApiKeys"

const returnExample = [
    {
        "start": 14,
        "end": 15,
        "count": 107
    }
]

export type mostTrafficDuringTimeOfDayReturnType = typeof returnExample

const buildMostTrafficByTimeOfDay = (): any[] => [
    {
        "$group": {
            "_id": {
                "$hour": {
                    "date": `$${xApiStatementKeys.timestamp}`,
                    "timezone": `${Constants.default_timezone}`
                }
            },
            "count": {
                "$sum": 1
            }
        }
    },
    {
        "$sort": {
            "count": -1
        }
    },
    {
        "$limit": 1
    },
    {
        "$project": {
            "_id": 0,
            "start": "$_id",
            "end": {
                "$add": ["$_id", 1]
            },
            "count": "$count"
        }
    }
]
export default buildMostTrafficByTimeOfDay