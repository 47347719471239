import { LegendItem } from "@declarations";
import React from "react"

import "./Legend.css";

interface Props {
    items: LegendItem[],
}
const Legend: React.FC<Props> = (props) => {

    return <div className="new-legend">
        {props.items
            .sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))
            .map((item, index) => (
                <div className="each-legend" key={index}>
                    <div style={{ backgroundColor: item.color }} className="label"></div>
                    <div className="details">
                        <p className="text">{item.title}</p>
                        <p className="value">{item.value}</p>
                    </div>
                </div>
            ))}
    </div>
}

export default Legend