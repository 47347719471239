

const returnExample = [
    {
        "instance": "2021-08-04T00:00:00.000Z",
        "count": 7
    },
]

export type ctaOverTimeReturnType = typeof returnExample

const buildCTAOverTime = (): any[] => [
    {
        "$group": {
            "_id": {
                "$dateToString": {
                    "format": "%Y-%m-%dT00:00:00.000Z",
                    "date": "$statement.timestamp"
                },
            },
            "count": {
                "$sum": 1
            },
        }
    },
    {
        "$addFields": {
            "isoTime": {
                "$toDate": "$_id"
            }
        }
    },
    {
        "$sort": {
            "isoTime": 1
        }
    },
    {
        "$bucketAuto": {
            "groupBy": "$_id",
            "buckets": 30,
            "output": {
                "count": {
                    "$sum": "$count"
                },
            }
        }
    },
    {
        "$addFields": {
            "_id": "$_id.min"
        }
    },
    {
        "$project": {
            "_id": 0,
            "instance": "$_id",
            "count": "$count",
        }
    }
]
export default buildCTAOverTime