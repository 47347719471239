

const returnExample = [
    {
        "_id": "https://aritize.nextechar.com/experience/7bbd77a3-36e6-4612-8827-295f5c81a720",
        "date": "2021-05-20T04:39:40.699Z",
        "views": 94,
        "clicks": 7,
        "clickRate": 7.446808510638298,
        "name": ""
    },
]

export type clickRateDetailsReturnType = typeof returnExample

const buildClickRateDetails = (): any[] => [
    {
        "$match": {
            "statement.verb.id": {
                "$in": [
                    "http://activitystrea.ms/schema/1.0/close",
                    "https://w3id.org/xapi/dod-isd/verbs/placed",
                    "http://activitystrea.ms/schema/1.0/experience",
                    "http://activitystrea.ms/schema/1.0/use"
                ]
            },
            "statement.object.id": {
                "$in": [
                    "https://aritize.nextechar.com/experience/2aa6c76a-9900-42a9-8204-48ced69ab5f6",
                    "https://aritize.nextechar.com/experience/d3f447ba-a1ce-438b-8145-6060f6a9bdf4",
                    "https://aritize.nextechar.com/experience/88121c8b-bf19-4d5f-9abe-a4dc097510a0",
                    "https://aritize.nextechar.com/experience/2c67fe3b-18e4-433c-b378-5030d657a44c",
                    "https://aritize.nextechar.com/experience/234c1cea-7f27-4c9c-b05d-d946b3f631f0",
                    "https://aritize.nextechar.com/experience/47e01343-4cb0-4aff-9bd8-1c901de67922",
                    "https://aritize.nextechar.com/experience/a1b5c451-f894-4184-a6c7-8dd057c8fe98",
                    "https://aritize.nextechar.com/experience/90c4bb90-c114-40f2-bc55-58271cab2902",
                    "https://aritize.nextechar.com/experience/7bbd77a3-36e6-4612-8827-295f5c81a720",
                    "https://aritize.nextechar.com/experience/02e4331b-da78-4103-96fb-c2c159f88b22",
                    "https://aritize.nextechar.com/experience/82f36a70-bdaf-4712-8ab1-858ce6ab39e9",
                    "https://aritize.nextechar.com/experience/f07b545f-5e3f-481e-965f-2a0d775930cf",
                    "https://aritize.nextechar.com/experience/dcd0488a-8216-45df-a470-123df26f31b2",
                    "https://aritize.nextechar.com/experience/67fdfcb5-7532-4dca-ae13-575213d11bf2",
                    "https://aritize.nextechar.com/experience/7261f5c7-bb42-42d6-bdd8-2879be37b3bd"
                ]
            },
        }
    },
    {
        "$addFields": {
            "EXT": "$statement.object.definition"
        }
    },
    {
        "$addFields": {
            "EXT": "$EXT.extensions"
        }
    },
    {
        "$group": {
            "_id": {
                "object": "$statement.object.id",
                "verb": "$statement.verb.id"
            },
            "count": {
                "$sum": 1
            },
            "date": {
                "$push": {
                    "time": "$statement.timestamp"
                }
            },
            "secondsFromStart": {
                "$push": {
                    "seconds": "$EXT.https://aritize*`*nextechar*`*com/secondsfromstart"
                }
            },
            "link": {
                "$addToSet": {
                    "name": "$EXT.https://aritize*`*nextechar*`*com/linkname"
                }
            }
        }
    },
    {
        "$facet": {
            "Launch Date": [
                {
                    "$match": {
                        "_id.verb": "http://activitystrea.ms/schema/1.0/experience"
                    }
                },
                {
                    "$group": {
                        "_id": "$_id.object",
                        "date": {
                            "$min": "$date.time"
                        },
                    }
                }
            ],
            "Views": [
                {
                    "$match": {
                        "_id.verb": "https://w3id.org/xapi/dod-isd/verbs/placed",
                    }
                },
                {
                    "$group": {
                        "_id": "$_id.object",
                        "viewCount": {
                            "$sum": "$count"
                        }
                    }
                }
            ],
            "Clicks": [
                {
                    "$match": {
                        "_id.verb": "http://activitystrea.ms/schema/1.0/use",
                    }
                },
                {
                    "$group": {
                        "_id": "$_id.object",
                        "clickCount": {
                            "$sum": "$count"
                        }
                    }
                }
            ],
            "Click Rates": [
                {
                    "$match": {
                        "_id.verb": {
                            "$in": [
                                "http://activitystrea.ms/schema/1.0/use",
                                "https://w3id.org/xapi/dod-isd/verbs/placed",
                            ]
                        }
                    }
                },
                {
                    "$project": {
                        "_id": {
                            "object": "$_id.object",
                            "verb": "$_id.verb"
                        },
                        "count": "$count"
                    }
                },
                {
                    "$group": {
                        "_id": "$_id.object",
                        "sum": {
                            "$sum": "$count"
                        },
                        "counts": {
                            "$push": {
                                "verb": "$_id.verb",
                                "count": "$count"
                            }
                        }
                    }
                },
                // Stage 2
                {
                    "$unwind": {
                        "path": "$counts",
                    }
                },
                // Stage 3
                {
                    "$project": {
                        "_id": "$_id",
                        "counts": "$counts.count",
                        "verb": "$counts.verb",
                        "sum": "$sum",
                        "percent": {
                            "$multiply": [
                                {
                                    "$cond": [
                                        {
                                            "$eq": [
                                                {
                                                    "$subtract": [
                                                        "$sum",
                                                        "$counts.count"
                                                    ]
                                                },
                                                0
                                            ]
                                        },
                                        0,
                                        {
                                            "$divide": [
                                                "$counts.count",
                                                {
                                                    "$subtract": [
                                                        "$sum",
                                                        "$counts.count"
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                100
                            ]
                        }
                    }
                },
                {
                    "$match": {
                        "verb": {
                            "$in": [
                                "http://activitystrea.ms/schema/1.0/use",
                            ]
                        }
                    }
                },
                {
                    "$project": {
                        "_id": "$_id",
                        "clickRate": "$percent"
                    }
                }
            ],
            "Name": [
                {
                    "$match": {
                        "_id.verb": "http://activitystrea.ms/schema/1.0/use"
                    }
                },
                {
                    "$group": {
                        "_id": "$_id.object",
                        "link": {
                            "$min": "$link.name"
                        }
                    }
                }
            ],
        }
    },
    {
        "$project": {
            "all": {
                "$concatArrays": [
                    "$Launch Date",
                    "$Views",
                    "$Clicks",
                    "$Click Rates",
                    "$Name",
                ]
            }
        }
    },
    {
        "$unwind": "$all"
    },
    {
        "$group": {
            "_id": "$all._id",
            "date": {
                "$first": {
                    "$min": "$all.date"
                }
            },
            "views": {
                "$sum": "$all.viewCount"
            },
            "clicks": {
                "$sum": "$all.clickCount"
            },
            "clickRate": {
                "$sum": "$all.clickRate"
            },
            "name": {
                "$min": {
                    "$min": "$all.link"
                }
            }
        }
    },
]
export default buildClickRateDetails