import { DateRange } from "@declarations"
import { VeracityDate } from "Utils/xAPIQueries/dates"
import { channelIdRoot, experienceIdRoot, xApiStatementKeys } from "Values/xApiKeys"

// idCategory determines if we should use the ids for the channels or experiences
// the access verb, "scans", uses channel ids
// most other verbs use experience ids
export const buildXapiQueryBase = (verbs: string[], idCategory: "experiences" | "channels", idList?: string[], dates?: DateRange) => {

    const request = [
        buildMatch(verbs, idCategory, idList, dates),
        ...addExtensionFields()
    ]
    return request

}

const buildMatch = (verbs: string[], idCategory: "experiences" | "channels", idList?: string[], dates?: DateRange) => {

    const matchKey = "$match"

    const match: Record<string, Record<string, any>> = {
        [matchKey]: {}
    }

    // VERBS
    if (verbs.length) {
        match[matchKey][xApiStatementKeys.verbId] = {
            "$in": [...verbs]
        }
    }

    if (idList?.length) {
        match[matchKey][xApiStatementKeys.objectId] = {
            "$in": idList.map(id => {
                switch (idCategory) {
                    case "experiences":
                        return experienceIdRoot + id
                    case "channels":
                        return channelIdRoot + id
                    default:
                        return id
                }
            })
        }
    }

    if (dates) {
        match[matchKey][xApiStatementKeys.timestamp] = {
            "$gte": VeracityDate(new Date(dates.start)),
            "$lt": VeracityDate(new Date(dates.end))
        }
    }
    return match
}

const addExtensionFields = () => {
    const field1 = {
        "$addFields": {
            "EXT": "$statement.object.definition"
        }
    }
    const field2 = {
        "$addFields": {
            "EXT": "$EXT.extensions"
        }
    }
    return [field1, field2]
}