import { AppState, MomentRange } from "@declarations"
import { getDefaultDates } from "Store/Reducers/dates"
import { store } from "Store/store"
import { Actions, makeAction } from "./ActionsTypes"

// function wrappers
export const setDemographicsDateRange = (dateRange: MomentRange, property: keyof AppState.DemographicsDateRanges) =>
    async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {
        dispatch(setDateRange(dateRange, "demographics", property))
    }

export const setNotificationsDateRange = (dateRange: MomentRange, property: keyof AppState.NotificationsDateRanges) =>
    async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {
        dispatch(setDateRange(dateRange, "notifications", property))
    }

export const setVisitorBehaviorDateRange = (dateRange: MomentRange, property: keyof AppState.VisitorBehaviorDateRanges) =>
    async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {
        dispatch(setDateRange(dateRange, "visitorBehavior", property))
    }

// set function
const setDateRange = (
    dateRange: MomentRange,
    parent: keyof AppState.DateRanges,
    property: keyof AppState.DemographicsDateRanges | keyof AppState.VisitorBehaviorDateRanges | keyof AppState.NotificationsDateRanges
) => async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    const currentState = getState().dates[parent]

    const start = dateRange.startDate?.valueOf() || getDefaultDates().start
    let end = dateRange.endDate?.valueOf() || getDefaultDates().end

    const dates: typeof currentState = {
        ...currentState,
        [property]: {
            start: start,
            end: end
        }
    }

    switch (parent) {
        case "demographics":
            dispatch(makeAction(Actions.SET_DEMOGRAPHICS_DATES, dates as AppState.DemographicsDateRanges))
            return
        case "visitorBehavior":
            dispatch(makeAction(Actions.SET_VISITOR_BEHAVIOR_DATES, dates as AppState.VisitorBehaviorDateRanges))
            return
        case "notifications":
            dispatch(makeAction(Actions.SET_NOTIFICATIONS_DATES, dates as AppState.NotificationsDateRanges))
            return
        default: return
    }
}

