import { toPng } from 'html-to-image';
import { jsPDF } from "jspdf";
import downloadIconWhite from "Assets/images/buttons/download-icon-white.png"
import { AppState, PageProps, PageView } from '@declarations';
import { getDashboardName } from 'Utils/routes';
import { connect } from 'react-redux';
import { store } from 'Store/store';

type Props = PageProps<typeof mapStateToProps, typeof mapDispatchToProps> & {
    page: PageView
}

const DownloadReportButton = (props: Props) => {

    const { page, brandInView, channelInView } = props

    const name = getDashboardName(brandInView?.name, channelInView?.name, page)

    const downloadPage = () => {
        const page: HTMLElement | null = document.getElementById("page-wrapper")
        if (page === null) console.error("Error downloading report: can't select page by ID")
        toPng(page as HTMLElement, { quality: 0.95 })
            .then(function (dataUrl) {
                var link = document.createElement('a');
                link.download = `${name}.jpeg`;
                const pdf = new jsPDF();
                const imgProps = pdf.getImageProperties(dataUrl);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight);
                pdf.save(`${name}.pdf`);
            });
    }

    return (
        <button
            onClick={downloadPage}
            className="alternate icon-l">
            <img src={downloadIconWhite} alt="download" />
            Download Report
        </button>
    )
}

const mapStateToProps = (state: AppState) => {
    return {
        brandsList: state.experiences.brandsList,
        brandInView: state.experiences.brandInView,
        channelInView: state.experiences.channelInView
    }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DownloadReportButton)