import React, { useState } from 'react';
import { scaleQuantize } from '@visx/scale';
import { Mercator, Graticule } from '@visx/geo';
import * as topojson from 'topojson-client';
import worldTopology from 'Values/Map/world-topo.json';
import usaTopology from 'Values/Map/usa-topo.json'
import canadaTopology from 'Values/Map/canada-topo.json'
import ukTopology from 'Values/Map/uk-topo.json';
import brazilTopology from 'Values/Map/brazil-topo.json'
import australiaTopology from 'Values/Map/australia-topo.json'
import chinaTopology from 'Values/Map/china-topo.json'
import japanTopology from 'Values/Map/japan-topo.json'
import indiaTopology from 'Values/Map/india-topo.json'
import russiaTopology from 'Values/Map/russia-topo.json'
import argentinaTopology from 'Values/Map/argentina-topo.json'
import newZealandTopology from 'Values/Map/new-zealand-topo.json'
import southAfricaTopology from 'Values/Map/south-africa-topo.json'
import uaeTopology from 'Values/Map/uae-topo.json'
import { MapCount } from '@declarations';
import { Zoom } from '@visx/zoom';
import "./Map.scss"
import { useEffect } from 'react';
import ReactTooltip from "react-tooltip";
import { useMemo } from 'react';
import { ScaleQuantize } from 'd3-scale';
import plusIcon from "Assets/images/forms/plus.svg";
import minusIcon from "Assets/images/forms/minus.svg";

export const background = '#DDDEE0';

export type GeoMercatorProps = {
    width: number;
    height: number;
    events?: boolean;
    data: MapCount[]
};

interface FeatureShape {
    type: 'Feature';
    id: string;
    geometry: { coordinates: [number, number][][]; type: 'Polygon' };
    properties: { name: string };
    count?: number
}

const Map = ({ width, height, events = false, data }: GeoMercatorProps) => {
    const centerX = width / 2;
    const centerY = height / 2;
    const initialScale = (width / 630) * 100;
    const scaleXMin = 100
    const scaleXMax = 1000
    const scaleYMin = 100
    const scaleYMax = 1000

    const regionDataMap: Record<string, Record<string, string | number>> = useMemo(() => {
        const map: Record<string, MapCount> = {} as Record<string, MapCount>
        for (const d of data) {
            map[d.region] = {
                region: d.region,
                country: d.country,
                count: map[d.region] ? map[d.region].count += d.count : d.count
            }
        }
        return map
    }, [data])

    const countryDataMap: Record<string, Record<string, string | number>> = useMemo(() => {
        const map: Record<string, MapCount> = {} as Record<string, MapCount>
        for (const d of data) {
            map[d.country] = {
                region: d.region,
                country: d.country,
                count: map[d.country] ? map[d.country].count += d.count : d.count
            }
        }
        return map
    }, [data])

    const [filter, setFilter] = useState<"region" | "country">("country")
    const [tooltipContent, setTooltipContent] = useState({
        name: "",
        count: 0
    });
    const [mapData, setMapData] = useState<FeatureShape[]>([])

    useEffect(() => {

        const map: FeatureShape[] = []
        // @ts-ignore
        const world = topojson.feature(worldTopology, worldTopology.objects.units) as {
            type: 'FeatureCollection';
            features: FeatureShape[];
        }

        map.push(...world.features)

        if (filter === 'region') {
            // @ts-ignore
            const usa = topojson.feature(usaTopology, usaTopology.objects.states) as {
                type: 'FeatureCollection';
                features: FeatureShape[];
            };

            // @ts-ignore
            const canada = {
                type: "FeatureCollection",
                features: (canadaTopology as any).features.map((f: any) => {
                    return {
                        type: f.type,
                        id: f.OBJECTID_1,
                        geometry: {
                            coordinates: f.geometry.coordinates,
                            type: 'MultiPolygon'
                        },
                        properties: { name: f.properties.name }
                    }
                })
            }

            // // @ts-ignore
            // const uk = topojson.feature(ukTopology, ukTopology.objects.regions) as {
            //     type: "FeatureCollection";
            //     features: FeatureShape[];
            // }

            // // @ts-ignore
            // const brazil = topojson.feature(brazilTopology, brazilTopology.objects.states) as {
            //     type: "FeatureCollection";
            //     features: FeatureShape[];
            // }

            // // @ts-ignore
            // const australia = topojson.feature(australiaTopology, australiaTopology.objects.states) as {
            //     type: "FeatureCollection";
            //     features: FeatureShape[];
            // }

            // // @ts-ignore
            // const china = topojson.feature(chinaTopology, chinaTopology.objects.regions) as {
            //     type: "FeatureCollection";
            //     features: FeatureShape[];
            // }

            // // @ts-ignore
            // const japan = topojson.feature(japanTopology, japanTopology.objects.regions) as {
            //     type: "FeatureCollection";
            //     features: FeatureShape[];
            // }

            // // @ts-ignore
            // const india = topojson.feature(indiaTopology, indiaTopology.objects.regions) as {
            //     type: "FeatureCollection";
            //     features: FeatureShape[];
            // }

            // // @ts-ignore
            // const russia = topojson.feature(russiaTopology, russiaTopology.objects.regions) as {
            //     type: "FeatureCollection";
            //     features: FeatureShape[];
            // }

            // // @ts-ignore
            // const argentina = topojson.feature(argentinaTopology, argentinaTopology.objects.regions) as {
            //     type: "FeatureCollection";
            //     features: FeatureShape[];
            // }

            // // @ts-ignore
            // const southAfrica = topojson.feature(southAfricaTopology, southAfricaTopology.objects.regions) as {
            //     type: "FeatureCollection";
            //     features: FeatureShape[];
            // }

            // // @ts-ignore
            // const newZealand = topojson.feature(newZealandTopology, newZealandTopology.objects.regions) as {
            //     type: "FeatureCollection";
            //     features: FeatureShape[];
            // }

            // // @ts-ignore
            // const uae = topojson.feature(uaeTopology, uaeTopology.objects.regions) as {
            //     type: "FeatureCollection";
            //     features: FeatureShape[];
            // }

            map.push(
                ...usa.features,
                ...canada.features,
                // ...uk.features,
                // ...brazil.features,
                // ...australia.features,
                // ...china.features,
                // ...japan.features,
                // ...india.features,
                // ...russia.features,
                // ...argentina.features,
                // ...southAfrica.features,
                // ...newZealand.features,
                // ...uae.features
            )
        }

        setMapData(map)

    }, [data, filter])

    const max = useMemo(() => Math.max(...Object.keys(countryDataMap).map(k => countryDataMap[k]?.count as number)), [countryDataMap])

    const [maxDataValue, setMaxDataValue] = useState<number>(max)

    const [color, setColor] = useState<ScaleQuantize<string, never>>(() => scaleQuantize({
        domain: [0, max],
        range: ['#FFFFFF', '#E6EBF6', '#99AFDC', '#6688CB', '#3360B9', '#0038A8']
    }))

    useEffect(() => {
        let colorScale: any = {}
        let max = 0

        if (filter === "region") {
            max = Math.max(...Object.keys(regionDataMap).map(k => regionDataMap[k]?.count as number))

            colorScale = scaleQuantize({
                domain: [0, max],
                range: ['#FFFFFF', '#E6EBF6', '#99AFDC', '#6688CB', '#3360B9', '#0038A8']
            })
        } else {
            max = Math.max(...Object.keys(countryDataMap).map(k => countryDataMap[k]?.count as number))

            colorScale = scaleQuantize({
                domain: [0, max],
                range: ['#FFFFFF', '#E6EBF6', '#99AFDC', '#6688CB', '#3360B9', '#0038A8']
            })
        }

        setMaxDataValue(max)
        setColor(() => colorScale)
    }, [regionDataMap, countryDataMap, filter])


    const getCountryColor = (feature: FeatureShape) => {
        const name = feature.properties.name;
        let count = 0

        if (filter === "region") {
            count = regionDataMap[name]?.count as number || 0
        } else if (filter === "country") {
            count = countryDataMap[name]?.count as number || 0
        }
        if (color) return color(count)
        return "#ffffff"
    }

    const handleMouseEnter = (feature: FeatureShape, filter: "region" | "country") => {
        const name = feature.properties.name;
        let count = 0

        if (filter === "region") {
            count = regionDataMap[name]?.count as number || 0
        } else if (filter === "country") {
            count = countryDataMap[name]?.count as number || 0
        }

        setTooltipContent({ name, count })
    }
    const handleMouseLeave = () => {
        setTooltipContent({
            name: "",
            count: 0
        })
    }

    const [rangeValue, setRangeValue] = useState<number>(initialScale)

    const handleRangeChange = (e: React.ChangeEvent<HTMLInputElement>, zoom: any) => {
        const target = e.currentTarget

        const min = Number(target.min)
        const max = Number(target.max)
        const val = Number(target.value)

        setRangeValue(val)

        const decimalDiff = (val - min) / (max - min)
        let newScale = decimalDiff * scaleXMax
        if (newScale < 100) newScale = 100

        zoom.setTransformMatrix({
            ...zoom.initialTransformMatrix,
            scaleX: newScale,
            scaleY: newScale
        })
    }

    if (width < 10) return null

    return (
        <>
            <div className="map-filter-buttons">
                <button
                    className={filter === 'region' ? "map-filter-button-selected" : ""}
                    onClick={() => setFilter("region")}
                >Region
                </button>
                <button
                    className={filter === 'country' ? "map-filter-button-selected" : ""}
                    onClick={() => setFilter("country")}
                >
                    Country
                </button>
            </div>
            <ReactTooltip
                type="light"
                className="map-tooltip"
            >
                {tooltipContent.name
                    ? <div className="map-tooltip-children">
                        <p className="name">{tooltipContent.name}</p>
                        <p>{tooltipContent.count ?? 0}</p>
                    </div>
                    : null
                }
            </ReactTooltip>
            <Zoom
                width={width}
                height={height}
                scaleXMin={scaleXMin}
                scaleXMax={scaleXMax}
                scaleYMin={scaleYMin}
                scaleYMax={scaleYMax}
                transformMatrix={{
                    scaleX: initialScale,
                    scaleY: initialScale,
                    translateX: centerX,
                    translateY: centerY,
                    skewX: 0,
                    skewY: 0,
                }}
            >
                {zoom => {
                    if (rangeValue !== zoom.transformMatrix.scaleX) {
                        setRangeValue(zoom.transformMatrix.scaleX)
                    }
                    return (
                        <div className="map-container" data-tip="">

                            <svg width={width} height={height}>
                                <rect x={0} y={0} width={width} height={height} fill={background} rx={14} />
                                {/** intercept all mouse events */}
                                <rect
                                    x={0}
                                    y={0}
                                    width={width}
                                    height={height}
                                    rx={14}
                                    fill="transparent"
                                    onTouchStart={zoom.dragStart}
                                    onTouchMove={zoom.dragMove}
                                    onTouchEnd={zoom.dragEnd}
                                    onMouseDown={zoom.dragStart}
                                    onMouseMove={zoom.dragMove}
                                    onMouseUp={zoom.dragEnd}
                                    onMouseLeave={() => {
                                        if (zoom.isDragging) zoom.dragEnd();
                                    }}
                                />
                                <Mercator<FeatureShape>
                                    data={mapData}
                                    scale={zoom.transformMatrix.scaleX}
                                    translate={[zoom.transformMatrix.translateX, zoom.transformMatrix.translateY]}
                                >
                                    {(mercator: any) => (
                                        <g>
                                            <Graticule graticule={(g: any) => mercator.path(g) || ''} stroke="rgba(33,33,33,0.05)" />
                                            {mercator.features.map(({ feature, path }: any, i: number) => (
                                                <path
                                                    key={`map-feature-${i}`}
                                                    d={path || ''}
                                                    fill={getCountryColor(feature)}
                                                    stroke={background}
                                                    strokeWidth={1}
                                                    onMouseEnter={() => handleMouseEnter(feature, filter)}
                                                    onMouseLeave={handleMouseLeave}
                                                />
                                            ))}
                                        </g>
                                    )}
                                </Mercator>

                            </svg>
                            {events && (
                                <div className="controls">
                                    <button
                                        className="btn btn-zoom"
                                        onClick={() => {
                                            zoom.scale({ scaleX: 0.8, scaleY: 0.8 })
                                            setRangeValue(v => v * 0.8)
                                        }}
                                    >
                                        <img src={minusIcon} alt="zoom out" />
                                    </button>
                                    <div className="divider"></div>
                                    <input
                                        type="range"
                                        min={scaleXMin}
                                        max={scaleXMax}
                                        onChange={e => handleRangeChange(e, zoom)}
                                        value={rangeValue}
                                        style={{ backgroundSize: `${rangeValue}% 100%` }}
                                    />
                                    <div className="divider"></div>
                                    <button
                                        className="btn btn-zoom"
                                        onClick={() => {
                                            zoom.scale({ scaleX: 1.2, scaleY: 1.2 })
                                            setRangeValue(v => v * 1.2)
                                        }}
                                    >
                                        <img src={plusIcon} alt="zoom in" />
                                    </button>
                                </div>
                            )}
                            <div className="map-legend">
                                <p>{0}</p>
                                <div className="scale">
                                </div>
                                <p>{maxDataValue}</p>
                            </div>
                        </div>
                    )
                }
                }
            </Zoom>
        </>
    );
};

export default Map