import React, { useMemo, useState } from "react"
import { useEffect } from "react"
import { getCountriesAndRegions } from "Utils/locations"
import dropdownArrow from 'Assets/images/arrows/dropdown-arrow.svg'
import "./LocationSelector.scss"

type Props = {
    data: any[]
    sendLocation: (country: string, region: string) => void
}

const LocationSelector = (props: Props) => {

    const { data, sendLocation } = props

    const countriesAndRegions = useMemo(() => getCountriesAndRegions(data), [data])

    const [country, setCountry] = useState<string>("")
    const [region, setRegion] = useState<string>("")


    const [showCountryDropdown, setShowCountryDropdown] = useState<boolean>(false)
    const [showRegionDropdown, setShowRegionDropdown] = useState<boolean>(false)

    const toggleShowCountryDropdown = () => {
        setShowCountryDropdown(!showCountryDropdown)
    }

    const toggleShowRegionDropdown = () => {
        setShowRegionDropdown(!showRegionDropdown)
    }

    const handleCountrySelect = (k: string) => {
        if (!k) {
            setCountry("")
            setRegion("")
        } else if (k !== country) {
            setCountry(k)
            setRegion("")
        } else {
            setCountry(k)
        }
        setShowCountryDropdown(false)
        setShowRegionDropdown(false)
    }

    const handleRegionSelect = (r: string) => {
        setRegion(r)
        setShowRegionDropdown(false)
    }

    useEffect(() => {
        sendLocation(country, region)
    }, [country, region])


    return (
        <div className="location-selector">
            <div className="selector">
                <button onClick={toggleShowCountryDropdown}>
                    <p className="selection-title">Country:</p>
                    <p className="selection">{country || "--"}</p>
                    <img src={dropdownArrow} alt="dropdown-arrow" className={showCountryDropdown ? 'selected-arrow' : 'arrow'} />
                </button>
                {showCountryDropdown &&
                    <div className="dropdown-list">
                        <button onClick={() => handleCountrySelect("")}>--</button>
                        {countriesAndRegions && Object.keys(countriesAndRegions)?.map(k => (
                            <button
                                onClick={() => handleCountrySelect(k)}
                                key={`country-selection-${k}`} value={k}>{k}</button>
                        ))}
                    </div>
                }
            </div>

            <div className="selector">
                <button onClick={toggleShowRegionDropdown} disabled={!country}>
                    <p className="selection-title">Region:</p>
                    <p className="selection">{region || "--"}</p>
                    <img src={dropdownArrow} alt="dropdown-arrow" className={showRegionDropdown ? 'selected-arrow' : 'arrow'} />
                </button>

                {showRegionDropdown &&
                    <div className="dropdown-list">
                        <button onClick={() => handleRegionSelect("")}>--</button>
                        {country && countriesAndRegions && countriesAndRegions[country] && Array.from(countriesAndRegions[country])?.map(r => (
                            <button
                                onClick={() => handleRegionSelect(r)}
                                key={`country-selection-${r}`} value={r}>{r}</button>
                        ))}
                    </div>
                }
            </div>
        </div>
    )
}

export default LocationSelector