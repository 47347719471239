import { xApiKeys, xApiStatementKeys } from "Values/xApiKeys"

const returnExample = [
    {
        "rate": 26.558497011101622
    }
]

export type overallClickRateReturnType = typeof returnExample

const buildOverallClickRate = (): any[] => [
    {
        "$group": {
            "_id": `$${xApiStatementKeys.verbId}`,
            "count": {
                "$sum": 1
            }
        }
    },
    {
        "$group": {
            "_id": null,
            "sum": {
                "$sum": "$count"
            },
            "counts": {
                "$push": {
                    "_id": "$_id",
                    "count": "$count"
                }
            }
        }
    },
    // Stage 2
    {
        "$unwind": {
            "path": "$counts",
        }
    },
    // // Stage 3
    {
        "$project": {
            "_id": "$counts._id",
            "counts": "$counts.count",
            "sum": "$sum",
            "percent": {
                "$multiply": [
                    {
                        "$divide": [
                            "$counts.count",
                            {
                                "$subtract": [
                                    "$sum",
                                    "$counts.count"
                                ]
                            }
                        ]
                    },
                    100
                ]
            }
        }
    },
    {
        "$match": {
            "_id": {
                "$in": [`${xApiKeys.Verbs.Weblinked}`]
            }
        }
    },
    {
        "$project": {
            "_id": 0,
            "rate": "$percent"
        }
    }
]
export default buildOverallClickRate