import { AppState, ReduxAction, Session } from "@declarations";

export enum Actions {
    // SESSION
    SET_SESSION = 'SET_USER',
    CLEAR_SESSION = 'CLEAR_USER',

    // BRANDS
    SET_BRANDS_LIST = "SET_BRANDS_LIST",
    SET_BRAND_IN_VIEW = "SET_BRAND_IN_VIEW",
    SET_CHANNEL_IN_VIEW = "SET_CHANNEL_IN_VIEW",
    SET_EXPERIENCE_ID_LIST = "SET_EXPERIENCE_ID_LIST",
    SET_EXPERIENCES_SUMMARY = "SET_EXPERIENCES_SUMMARY",
    SET_DEMO_BRAND_ACTIVE = "SET_DEMO_BRAND_ACTIVE",
    SET_GLOBAL_VIEW = "SET_GLOBAL_VIEW",

    // DASHBOARD DATA
    SET_VISITOR_BEHAVIOR_DATA = "SET_VISITOR_BEHAVIOR_DATA",
    SET_DEMOGRAPHICS_DATA = "SET_DEMOGRAPHICS_DATA",
    SET_NOTIFICATIONS_DATA = "SET_NOTIFICATIONS_DATA",

    // DATES
    SET_DEMOGRAPHICS_DATES = "SET_DEMOGRAPHICS_DATES",
    SET_VISITOR_BEHAVIOR_DATES = "SET_VISITOR_BEHAVIOR_DATES",
    SET_NOTIFICATIONS_DATES = "SET_NOTIFICATIONS_DATES",

    // LOADING STATES
    SET_LOADING_BRAND_LIST = "SET_LOADING_BRAND_LIST",
    SET_LOADING_VISITOR_BEHAVIOR_DATA = "SET_LOADING_VISITOR_BEHAVIOR_DATA",
    SET_LOADING_DEMOGRAPHICS_DATA = "SET_LOADING_DEMOGRAPHICS_DATA",
    SET_LOADING_NOTIFICATIONS_DATA = "SET_LOADING_NOTIFICATIONS_DATA",
}

// overloads
// SESSION
export function makeAction(type: Actions.SET_SESSION, payload: Session): ReduxAction;
export function makeAction(type: Actions.CLEAR_SESSION): ReduxAction;

// BRANDS
export function makeAction(type: Actions.SET_BRANDS_LIST, payload: AppState.Brand[]): ReduxAction;
export function makeAction(type: Actions.SET_BRAND_IN_VIEW, payload: AppState.Brand): ReduxAction;
export function makeAction(type: Actions.SET_CHANNEL_IN_VIEW, payload: AppState.Channel): ReduxAction;
export function makeAction(type: Actions.SET_EXPERIENCE_ID_LIST, payload: string[]): ReduxAction;
export function makeAction(type: Actions.SET_EXPERIENCES_SUMMARY, payload: Record<string, AppState.ExperiencesSummary>): ReduxAction;
export function makeAction(type: Actions.SET_DEMO_BRAND_ACTIVE, payload: boolean): ReduxAction;
export function makeAction(type: Actions.SET_GLOBAL_VIEW, payload: boolean): ReduxAction;

// DASHBOARD DATA
export function makeAction(type: Actions.SET_DEMOGRAPHICS_DATA, payload: AppState.DemographicsData): ReduxAction
export function makeAction(type: Actions.SET_VISITOR_BEHAVIOR_DATA, payload: AppState.VisitorBehaviorData): ReduxAction
export function makeAction(type: Actions.SET_NOTIFICATIONS_DATA, payload: AppState.NotificationsData): ReduxAction

// DATES
export function makeAction(type: Actions.SET_DEMOGRAPHICS_DATES, payload: AppState.DemographicsDateRanges): ReduxAction
export function makeAction(type: Actions.SET_VISITOR_BEHAVIOR_DATES, payload: AppState.VisitorBehaviorDateRanges): ReduxAction
export function makeAction(type: Actions.SET_NOTIFICATIONS_DATES, payload: AppState.NotificationsDateRanges): ReduxAction

// LOADING
export function makeAction(type: Actions.SET_LOADING_BRAND_LIST, payload: boolean): ReduxAction;
export function makeAction(type: Actions.SET_LOADING_DEMOGRAPHICS_DATA, payload: AppState.LoadingDemographics): ReduxAction
export function makeAction(type: Actions.SET_LOADING_VISITOR_BEHAVIOR_DATA, payload: AppState.LoadingVisitorBehavior): ReduxAction
export function makeAction(type: Actions.SET_LOADING_NOTIFICATIONS_DATA, payload: AppState.LoadingNotifications): ReduxAction

// definition
export function makeAction(type: Actions, payload?: Record<string, any> | boolean | number): ReduxAction {
    return { type, payload }
}
