
import moment from 'moment'
import humanizeDuration from "humanize-duration"
import { DateRange } from '@declarations';

export const A_DAY = 24 * 60 * 60 * 1000;

export const getXDaysAgo = (date: Date, ago: number): Date => {
    const d = new Date().setDate(date.getDate() - ago)
    return new Date(d)
}

export const formatTimestamp = (date: string | Date) => {

    const dateFormat = 'en-US'

    const options = { hour: 'numeric', minute: 'numeric' } as const;
    const d = new Date(date).toLocaleString(dateFormat, options);
    return d;
}

export const formatTimeHHMMSS = (seconds: number): string => {
    return new Date(1000 * seconds).toISOString().substr(11, 8)
}

export const formatDate = (d: Date): string => {

    const day = d.getDate()

    const monthFormatter = new Intl.DateTimeFormat('en-US', { month: 'short' })
    const month = monthFormatter.format(d)

    const year = d.getFullYear()

    return `${day} ${month} ${year}`

}

export const formatDateMonthDate = (d: number): string => {
    const date = new Date(d)

    const monthFormatter = new Intl.DateTimeFormat('en-US', { month: 'short' })
    const month = monthFormatter.format(d)

    const day = date.getDate()

    return `${month} ${day}`
}

export const formatDateRange = (start: Date, end: Date): string => {

    const startDate = start.getDate()
    const endDate = end.getDate()

    const startYear = start.getFullYear()
    const endYear = end.getFullYear()

    const monthFormatter = new Intl.DateTimeFormat('en-US', { month: 'long' })
    const startMonth = monthFormatter.format(start)
    const endMonth = monthFormatter.format(end)

    if (startMonth === endMonth && startYear === endYear) {
        return `${startMonth} ${startDate} - ${endDate}, ${startYear}`
    } else if (startYear === endYear) {
        return `${startMonth} ${startDate} - ${endMonth} ${endDate}, ${startYear}`
    }
    else {
        return `${startMonth} ${startDate}, ${startYear} - ${endMonth} ${endDate}, ${endYear}`
    }
}

const ourDurationHumanizer = humanizeDuration.humanizer({
    largest: 2, round: true, serialComma: false, delimiter: " "
})

const cleanTime = (duration: string) => {
    let split = duration.split(" ")
    let cleaned = ""
    for (let i = 0; i < split.length; i++) {
        let d = split[i];
        if (d === "second") d = "sec"
        if (d === "seconds") d = "secs"
        if (d === "minute") d = "min"
        if (d === "minutes") d = "mins"
        if (d === "hour") d = "hr"
        if (d === "hours") d = "hrs"
        if (d === "week") d = "wk"
        if (d === "weeks") d = "wks"
        if (d === "month") d = "mo"
        if (d === "months") d = "mos"
        if (d === "year") d = "yr"
        if (d === "years") d = "yrs"
        cleaned += d + " "
    }
    return cleaned
}

export const cleanDuration = (d: number): string => {

    return cleanTime(ourDurationHumanizer(Number(moment.duration(d, 'seconds'))))

}

export const getCurrentMonthStart = (): number => {
    const startOfMonth = moment().startOf('month').valueOf()

    return startOfMonth
}

export const getPreviousMonthStartAndEnd = (): DateRange => {
    const prevMonthFirstDay = moment().subtract(1, 'months').startOf('month').valueOf()
    const prevMonthLastDay = moment().subtract(1, 'months').endOf('month').valueOf()

    return { start: prevMonthFirstDay, end: prevMonthLastDay }
}