import { AppState, DateRange, MomentRange, PageProps, Session } from "@declarations"
import { getCTAOverTime } from "Store/Actions/DataActions-VisitorBehavior"
import DatePicker from "Components/DatePicker/DatePicker"
import TimelineChart from "Components/TimelineChart/TimelineChart"
import { useEffect } from "react"
import { connect } from "react-redux"
import { store } from "Store/store"
import EmptyState from "Components/EmptyState/EmptyState"
import { setVisitorBehaviorDateRange } from "Store/Actions/DateActions"
import useChartWidth from "Utils/useChartWidth"
import { callCheck } from "Utils/data"

interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {
}

const CTAOverTime = (props: Props) => {

    const { session, globalView, experiences, dates, updateDates, demoBrandActive, dataList, getDataList } = props

    useEffect(() => {

        if (!callCheck(globalView, (session as Session.AuthSession).user.admin, experiences?.length)) return

        getDataList(dates)

    }, [getDataList, session, demoBrandActive, dates, experiences, globalView])

    const width = useChartWidth()

    return (
        <div className="timeline-chart-wrapper">
            <div className="timeline-chart-header">
                <h3>CTA clicks over time</h3>
                <DatePicker
                    startDate={dates.start}
                    endDate={dates.end}
                    startDateId={"startDateCTAOT"}
                    endDateId={"endDateCTAOT"}
                    updateDates={updateDates}
                />
            </div>
            {
                dataList && dataList.length
                    ? <TimelineChart
                        width={width}
                        height={500}
                        startDate={dates.start}
                        endDate={dates.end}
                        data={dataList}
                        legendTitle="Number of CTA Clicks"
                    />
                    : <EmptyState filterableData={true} />
            }
        </div>
    )
}

const mapStateToProps = (state: AppState) => {
    return {
        session: state.session,
        experiences: state.experiences.experienceIdList,
        globalView: state.experiences.globalView,
        demoBrandActive: state.experiences.demoBrandActive,
        dataList: state.data.visitorBehavior.cta.ctaOvertime,
        loadingData: state.loading.visitorBehavior.cta.ctaOvertime,
        dates: state.dates.visitorBehavior.ctaClicksOverTime
    }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
        getDataList: (dates: DateRange) => dispatch(getCTAOverTime(dates)),
        updateDates: (dateRange: MomentRange) => dispatch(setVisitorBehaviorDateRange(dateRange, "ctaClicksOverTime"))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CTAOverTime)