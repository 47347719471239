import { ReduxAction, AppState } from '@declarations';
import { Actions } from 'Store/Actions/ActionsTypes'

const initialState: AppState.DashboardData = {
    visitorBehavior: {
        topViewedEpisodes: {
            topViewedEpisodes: [],
            summaryData: {
                topViewedEpisodeToDate: "",
                mostViewsOfAnEpisode: 0,
                avgViewTime: 0,
            }
        },
        visitors: {
            visitors: [],
            summaryData: {
                totalScansToDate: 0,
                totalAccumulatedViewTime: 0,
                mostTrafficDuringTimeOfDay: ["", "", ""],
            }
        },
        bounceRates: {
            bounceRates: [],
            bounceRatesOverTime: [],
            summaryData: {
                overallBounceRateToDate: 0,
                bouncRateComparedToLastMonth: {
                    comparison: null,
                    value: 0
                },
                avgSessionDuration: 0
            }
        },
        cta: {
            cta: [],
            ctaOvertime: [],
            summaryData: {
                totalClicksToDate: 0,
                ctaComparedToLastMonth: {
                    comparison: null,
                    value: 0
                },
                avgClickRate: 0
            }
        },
        // time of day
        visitorsByTimeOfDay: []
    },
    demographics: {
        trafficTotalsByRegion: [],
        qrCodesByLocation: [],
        top5QRCodes: [],
        scansByLocation: [],
        scansOverTimeByLocation: [],
        scansOverTimeTotal: [],
        totalScans: {
            total: 0,
            breakdown: []
        }
    },
    notifications: {
        totalCount: 0,
        pushNotificationsCTR: [],
        pushNotificationStatus: {
            breakdown: [],
            funnel: {}
        },
        pushNotificationStatusTimeline: []
    }
};

export const data = (state: AppState.DashboardData = initialState, { type, payload }: ReduxAction): AppState.DashboardData => {
    switch (type) {
        case Actions.SET_VISITOR_BEHAVIOR_DATA:
            return {
                ...state,
                visitorBehavior: payload as AppState.VisitorBehaviorData
            }
        case Actions.SET_DEMOGRAPHICS_DATA:
            return {
                ...state,
                demographics: payload as AppState.DemographicsData
            }
        case Actions.SET_NOTIFICATIONS_DATA:
            return {
                ...state,
                notifications: payload as AppState.NotificationsData
            }
        default:
            return state
    }
}