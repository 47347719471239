import { xApiKeys } from "Values/xApiKeys"

const returnExample = [
    {
        "avgDuration": 48.087227414330215,
        "totalDuration": 46308
    }
]

export type viewTimeReturnType = typeof returnExample

const buildViewTime = (): any[] => [
    {
        "$group": {
            "_id": null,
            "avgDuration": {
                "$avg": `${xApiKeys.Extensions.secondsfromstart.path}`
            },
            "totalDuration": {
                "$sum": `${xApiKeys.Extensions.secondsfromstart.path}`
            }
        }
    },
    {
        "$project": {
            "_id": 0,
            "avgDuration": "$avgDuration",
            "totalDuration": "$totalDuration"
        }
    }
]
export default buildViewTime