import { CSVLink } from "react-csv";

import downloadIconBlue from "Assets/images/buttons/download-icon-blue.svg"
import { useState, useEffect } from "react";
import { getTableHeader } from "Utils/tables";

type Props = {
    title: string
    headers: string[]
    data: any[]
}

const DownloadCSVButton = (props: Props) => {

    const { title, headers, data } = props

    const [csvHeaders, setCSVHeaders] = useState<any[]>([])

    useEffect(() => {
        const headerMaps = []
        for (const h of headers) {
            const label = getTableHeader(h)
            headerMaps.push({
                label: label,
                key: h
            })
        }
        setCSVHeaders(headerMaps)
    }, [headers])


    return (
        <CSVLink
            className="alternate-light icon-l"
            data={data}
            headers={csvHeaders}
            filename={title}
            asyncOnClick={true}
            separator={";"}
            enclosingCharacter={`"`}
        >
            <img src={downloadIconBlue} alt="download" />
            Download CSV
        </CSVLink>
    )
}

export default DownloadCSVButton