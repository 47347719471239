import { combineReducers } from 'redux'

import { session } from './session'
import { experiences } from './experiences'
import { data } from './data'
import { loading } from './loading'
import { dates } from './dates'

const rootReducer = combineReducers({
    session,
    experiences,
    data,
    dates,
    loading
});

export default rootReducer
