import { AppState } from "@declarations";

export const demoSite: AppState.Brand = {
    "id": "33b7766a-2b4a-4c97-896e-96aaa08527e",
    "name": "Demo Account",
    "channels": [
        {
            "experiences": [
                {
                    "id": "2aa6c76a-9900-42a9-8204-48ced69ab5f",
                    "name": "Demo Account Turmeric",
                    "brand": {
                        "id": "33b7766a-2b4a-4c97-896e-96aaa08527e",
                        "name": "Demo Account"
                    },
                    "channel": {
                        "id": "bcbaea83-7487-4a7c-a719-5f369d990c5",
                        "name": "Demo Account"
                    }
                },
                { "id": "d3f447ba-a1ce-438b-8145-6060f6a9bdf", "name": "Demo Account Melatonin", "brand": { "id": "33b7766a-2b4a-4c97-896e-96aaa08527e", "name": "Demo Account" }, "channel": { "id": "bcbaea83-7487-4a7c-a719-5f369d990c5", "name": "Demo Account" } },
                { "id": "88121c8b-bf19-4d5f-9abe-a4dc097510a", "name": "Vitamin C Genie Object Tracking", "brand": { "id": "33b7766a-2b4a-4c97-896e-96aaa08527e", "name": "Demo Account" }, "channel": { "id": "bcbaea83-7487-4a7c-a719-5f369d990c5", "name": "Demo Account" } },
                { "id": "2c67fe3b-18e4-433c-b378-5030d657a44", "name": "Vitamin C Genie No Plants", "brand": { "id": "33b7766a-2b4a-4c97-896e-96aaa08527e", "name": "Demo Account" }, "channel": { "id": "bcbaea83-7487-4a7c-a719-5f369d990c5", "name": "Demo Account" } },
                { "id": "234c1cea-7f27-4c9c-b05d-d946b3f631f", "name": "Test", "brand": { "id": "33b7766a-2b4a-4c97-896e-96aaa08527e", "name": "Demo Account" }, "channel": { "id": "bcbaea83-7487-4a7c-a719-5f369d990c5", "name": "Demo Account" } },
                { "id": "47e01343-4cb0-4aff-9bd8-1c901de6792", "name": "Demo Account Vitamin C", "brand": { "id": "33b7766a-2b4a-4c97-896e-96aaa08527e", "name": "Demo Account" }, "channel": { "id": "bcbaea83-7487-4a7c-a719-5f369d990c5", "name": "Demo Account" } },
                { "id": "a1b5c451-f894-4184-a6c7-8dd057c8fe9", "name": "Demo Account Elderberry", "brand": { "id": "33b7766a-2b4a-4c97-896e-96aaa08527e", "name": "Demo Account" }, "channel": { "id": "bcbaea83-7487-4a7c-a719-5f369d990c5", "name": "Demo Account" } }
            ],
            "name": "Demo Account Turmeric",
            "id": "bcbaea83-7487-4a7c-a719-5f369d990c5"
        },
        { "experiences": [{ "id": "90c4bb90-c114-40f2-bc55-58271cab290", "name": "Demo Account Melatonin 2", "brand": { "id": "33b7766a-2b4a-4c97-896e-96aaa08527e", "name": "Demo Account" }, "channel": { "id": "f47647d6-c50f-4aed-847f-88b6c163ee3", "name": "Demo Account Melatonin" } }, { "id": "7bbd77a3-36e6-4612-8827-295f5c81a72", "name": "Demo Account Melatonin", "brand": { "id": "33b7766a-2b4a-4c97-896e-96aaa08527e", "name": "Demo Account" }, "channel": { "id": "f47647d6-c50f-4aed-847f-88b6c163ee3", "name": "Demo Account Melatonin" } }], "name": "Demo Account Melatonin 2", "id": "f47647d6-c50f-4aed-847f-88b6c163ee3" },
        { "experiences": [{ "id": "02e4331b-da78-4103-96fb-c2c159f88b2", "name": "Demo Account Vitamin C", "brand": { "id": "33b7766a-2b4a-4c97-896e-96aaa08527e", "name": "Demo Account" }, "channel": { "id": "60fac413-1598-41e1-bb8e-831e5a6bcdd", "name": "Demo Account Vitamin C" } }, { "id": "82f36a70-bdaf-4712-8ab1-858ce6ab39e", "name": "Demo Account Vitamin C 2", "brand": { "id": "33b7766a-2b4a-4c97-896e-96aaa08527e", "name": "Demo Account" }, "channel": { "id": "60fac413-1598-41e1-bb8e-831e5a6bcdd", "name": "Demo Account Vitamin C" } }], "name": "Demo Account Vitamin C", "id": "60fac413-1598-41e1-bb8e-831e5a6bcdd" },
        { "experiences": [{ "id": "dcd0488a-8216-45df-a470-123df26f31b", "name": "Demo Account Elderberry", "brand": { "id": "33b7766a-2b4a-4c97-896e-96aaa08527e", "name": "Demo Account" }, "channel": { "id": "faaf4113-95c2-4ed5-baee-c8b1957bf77", "name": "Demo Account Elderberry" } }, { "id": "f07b545f-5e3f-481e-965f-2a0d775930c", "name": "Demo Account Elderberry 2", "brand": { "id": "33b7766a-2b4a-4c97-896e-96aaa08527e", "name": "Demo Account" }, "channel": { "id": "faaf4113-95c2-4ed5-baee-c8b1957bf77", "name": "Demo Account Elderberry" } }], "name": "Demo Account Elderberry", "id": "faaf4113-95c2-4ed5-baee-c8b1957bf77" },
        { "experiences": [{ "id": "67fdfcb5-7532-4dca-ae13-575213d11bf", "name": "Demo Account Turmeric", "brand": { "id": "33b7766a-2b4a-4c97-896e-96aaa08527e", "name": "Demo Account" }, "channel": { "id": "8c37fcec-0531-410a-8288-299c0aef1e6", "name": "Demo Account Turmeric" } }, { "id": "7261f5c7-bb42-42d6-bdd8-2879be37b3b", "name": "Demo Account Turmeric 2", "brand": { "id": "33b7766a-2b4a-4c97-896e-96aaa08527e", "name": "Demo Account" }, "channel": { "id": "8c37fcec-0531-410a-8288-299c0aef1e6", "name": "Demo Account Turmeric" } }], "name": "Demo Account Turmeric", "id": "8c37fcec-0531-410a-8288-299c0aef1e6" }
    ]
}