import { xApiKeys, xApiStatementKeys } from "Values/xApiKeys"

const returnExample = [
    {
        "name": "https://aritize.nextechar.com/experience/90c4bb90-c114-40f2-bc55-58271cab2902",
        "rank": 1,
        "count": 78,
        "states": [
            {
                "state": "Ontario",
                "country": "Canada",
                "count": 75
            }
        ]
    }
]

export type top5ReturnType = typeof returnExample

const buildTop5QRCodes = (): any[] => [
    {
        "$group": {
            "_id": {
                "object": `$${xApiStatementKeys.objectId}`,
                "state": xApiKeys.Extensions.state.path,
                "country": xApiKeys.Extensions.country.path
            },
            "count": {
                "$sum": 1
            },
        }
    },
    {
        "$sort": {
            "count": -1
        }
    },
    {
        "$group": {
            "_id": "$_id.object",
            "count": {
                "$sum": "$count"
            },
            "region": {
                "$push": {
                    "state": "$_id.state",
                    "country": "$_id.country",
                    "count": "$count"
                }
            },
        }
    },
    {
        "$sort": {
            "count": -1
        }
    },
    {
        "$limit": 5
    },
    {
        "$group": {
            "_id": null,
            "groups": {
                "$push": "$$ROOT"
            },
        }
    },
    {
        "$unwind": {
            "path": "$groups",
            "includeArrayIndex": "rank"
        }
    },
    {
        "$project": {
            "_id": 0,
            "name": "$groups._id",
            "rank": {
                "$add": [
                    "$rank",
                    1
                ]
            },
            "count": "$groups.count",
            "states": "$groups.region"
        }
    },

    // {
    //     "$group": {
    //         "_id": {
    //             "object": `$${xApiStatementKeys.objectId}`
    //         },
    //         "count": {
    //             "$sum": 1
    //         },
    //         "states": {
    //             "$push": {
    //                 "state": xApiKeys.Extensions.state.path,
    //                 "country": xApiKeys.Extensions.country.path
    //             }
    //         }
    //     }
    // },
    // {
    //     "$limit": 5
    // },
    // {
    //     "$sort": {
    //         "count": -1
    //     }
    // },
    // {
    //     "$group": {
    //         "_id": null,
    //         "groups": {
    //             "$push": "$$ROOT"
    //         },
    //     }
    // },
    // {
    //     "$unwind": {
    //         "path": "$groups",
    //         "includeArrayIndex": "rank"
    //     }
    // },
    // {
    //     "$project": {
    //         "_id": 0,
    //         "name": "$groups._id.object",
    //         "rank": {
    //             "$add": [
    //                 "$rank",
    //                 1
    //             ]
    //         },
    //         "count": "$groups.count",
    //         "states": "$groups.states"
    //     }
    // },
]
export default buildTop5QRCodes