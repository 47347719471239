import { TableData } from "@declarations"
import { getRandomNumber } from "Utils/numbers"

const places = [
    "Ontario, Canada",
    "British Columbia, Canada",
    "Texas, United States",
    "California, United States"
]

export const getDemoDataTrafficTotalsByRegion = (count: number): TableData.TrafficTotalsByRegion[] => {
    const episodes: TableData.TrafficTotalsByRegion[] = []

    for (let i = 1; i <= count; i++) {

        const place = places[getRandomNumber(0, places.length - 1)]

        const ep: TableData.TrafficTotalsByRegion = {
            rank: 0,
            region: place.split(",")[0].trim(),
            country: place.split(",")[1].trim(),
            experienceOpens: getRandomNumber(1, 25),
            totalPercentage: getRandomNumber(1, 25)
        }
        episodes.push(ep)
    }

    episodes.sort((a, b) => b.experienceOpens - a.experienceOpens)

    episodes.forEach((e, idx) => {
        e.rank = idx + 1
    })

    return episodes
}