import { AppState, Breakdown, DateRange, MapCount, TableData, TimelineSlice, TimelineSliceByLocation } from "@declarations";
import { store } from "Store/store";
import { getDemoDataQRCodesByLocation } from "Values/DemoData/Demographics/qr-codes-by-location";
import { getDemoDataScansByLocation } from "Values/DemoData/Demographics/scans-by-location";
import { getDemoDataScansOvertimeTotal } from "Values/DemoData/Demographics/scans-over-time-total";
import { getDemoDataScansOvertimeByLocation } from "Values/DemoData/Demographics/scans-over-time-by-location"
import { getDemoDataTop5QRCodes } from "Values/DemoData/Demographics/top-5-qr-codes";
import { getDemoDataTotalScans } from "Values/DemoData/Demographics/total-scans";
import { getDemoDataTrafficTotalsByRegion } from "Values/DemoData/Demographics/traffic-totals-by-region";
import { Actions, makeAction } from "./ActionsTypes";
import { channelIdRoot, experienceIdRoot, xApiKeys } from "Values/xApiKeys";
import { buildXapiQueryBase } from "Api/xAPI/QueryLibrary/query-builder";
import { queryXapi } from "Api/xAPI/xapi";
import { xApiQueryLibrary } from "Api/xAPI/QueryLibrary/query-library";
import { totalScansReturnType } from "Api/xAPI/QueryLibrary/demographics/totalScans";
import { qrCodesByLocationReturnType } from "Api/xAPI/QueryLibrary/demographics/qrCodesByLocation";
import { scansByLocationReturnType } from "Api/xAPI/QueryLibrary/demographics/scansByLocation";
import { trafficTotalsReturnType } from "Api/xAPI/QueryLibrary/demographics/trafficTotalsByRegion";
import { scansOverTimeByLocationReturnType } from "Api/xAPI/QueryLibrary/demographics/scansOverTimeByLocation";
import { scansOverTimeTotalReturnType } from "Api/xAPI/QueryLibrary/demographics/scansOverTimeTotal";
import { Constants } from "Values/constants";

// LOADING
const setLoading = (property: keyof AppState.LoadingDemographics, on: boolean) => async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    const currentLoading = getState().loading.demographics

    const loading: AppState.LoadingDemographics = {
        ...currentLoading,
        [property]: on
    }
    dispatch(makeAction(Actions.SET_LOADING_DEMOGRAPHICS_DATA, loading))
}

// TABLES

export const getTrafficTotalsByRegion = (dates: DateRange) => async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    await dispatch(setLoading("trafficTotalsByRegion", true))

    // get data
    let n: TableData.TrafficTotalsByRegion[] = []

    const { demoBrandActive, experienceIdList } = getState().experiences

    if (demoBrandActive) {
        n = getDemoDataTrafficTotalsByRegion(100)
    } else {
        const verbs = [xApiKeys.Verbs.Experienced]

        const base = buildXapiQueryBase(verbs, "experiences", experienceIdList, dates)
        const details = xApiQueryLibrary.demographics.trafficTotalsByRegion()
        const query = [...base, ...details]

        try {
            const res: trafficTotalsReturnType = await queryXapi(query)
            n = res
        }
        catch (e) {
            console.error("Error getting traffic totals by region", e)
        }
    }

    // set data
    const currentState = getState().data.demographics
    const data: AppState.DemographicsData = {
        ...currentState,
        trafficTotalsByRegion: n
    }
    dispatch(makeAction(Actions.SET_DEMOGRAPHICS_DATA, data))

    await dispatch(setLoading("trafficTotalsByRegion", false))

}

export const getQRCodesByLocation = (dates: DateRange) => async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    await dispatch(setLoading("qrCodesByLocation", true))

    let n: TableData.QRCodesByLocation[] = []

    const { demoBrandActive, experienceIdList, experienceSummary } = getState().experiences

    if (demoBrandActive) {
        n = getDemoDataQRCodesByLocation(100)
    } else {

        const verbs = [xApiKeys.Verbs.Experienced]

        const base = buildXapiQueryBase(verbs, "experiences", experienceIdList, dates)
        const details = xApiQueryLibrary.demographics.qrCodesByLocation()
        const query = [...base, ...details]

        try {

            const res: qrCodesByLocationReturnType = await queryXapi(query)

            for (const r of res) {
                const item: TableData.QRCodesByLocation = {
                    rank: r.rank,
                    QRCodeTitle: experienceSummary[r.name.replace(experienceIdRoot, '')] ? `${experienceSummary[r.name.replace(experienceIdRoot, '')]?.name}` : Constants.unavailable_data_label,
                    numberOfOpens: r.count,
                    // mongodb query sorts the states by count. first item has highest count
                    region: r.states[0]?.state || Constants.unavailable_data_label,
                    country: r.states[0]?.country || Constants.unavailable_data_label
                }
                n.push(item)
            }
        } catch (e) {
            console.error("Error getting QR Codes by location", e)
        }

    }

    // set data
    const currentState = getState().data.demographics
    const data: AppState.DemographicsData = {
        ...currentState,
        qrCodesByLocation: n
    }
    dispatch(makeAction(Actions.SET_DEMOGRAPHICS_DATA, data))

    await dispatch(setLoading("qrCodesByLocation", false))

}

export const getTop5QRCodes = (dates: DateRange) => async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    await dispatch(setLoading("top5QRCodes", true))

    let n: TableData.Top5QRCodes[] = []

    const { demoBrandActive, experienceIdList, experienceSummary } = getState().experiences

    if (demoBrandActive) {
        n = getDemoDataTop5QRCodes(5)
    } else {
        const verbs = [xApiKeys.Verbs.Experienced]

        const base = buildXapiQueryBase(verbs, "experiences", experienceIdList, dates)
        const details = xApiQueryLibrary.demographics.top5QRCodes()
        const query = [...base, ...details]

        try {
            const res: qrCodesByLocationReturnType = await queryXapi(query)

            for (const r of res) {
                const item: TableData.Top5QRCodes = {
                    rank: r.rank,
                    QRCodeTitle: experienceSummary[r.name.replace(experienceIdRoot, '')] ? `${experienceSummary[r.name.replace(experienceIdRoot, '')]?.name}` : "Unavailable",
                    numberOfOpens: r.count,
                    // mongodb query sorts the states by count. first item has highest count
                    country: r.states[0]?.country || Constants.unavailable_data_label
                }
                n.push(item)
            }

        } catch (e) {
            console.error("Error getting top 5 QR Codes", e)
        }
    }

    // set data
    const currentState = getState().data.demographics
    const data: AppState.DemographicsData = {
        ...currentState,
        top5QRCodes: n
    }
    dispatch(makeAction(Actions.SET_DEMOGRAPHICS_DATA, data))

    await dispatch(setLoading("top5QRCodes", false))

}

// CHARTS

export const getScansOverTimeByLocation = (dates: DateRange) => async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    await dispatch(setLoading("scansOverTimeByLocation", true))

    let n: TimelineSliceByLocation[] = []
    const { demoBrandActive, brandInView, channelInView } = getState().experiences

    if (demoBrandActive) {
        n = getDemoDataScansOvertimeByLocation(dates, 100)
    } else {
        const verbs = [xApiKeys.Verbs.CodeEntered]

        const ids = []
        if (!channelInView?.id) {
            for (const c of brandInView?.channels) {
                ids.push(c.id)
            }
        } else {
            ids.push(channelInView.id)
        }

        const base = buildXapiQueryBase(verbs, "channels", ids, dates)
        const details = xApiQueryLibrary.demographics.scansOverTimeByLocation()
        const query = [...base, ...details]

        try {
            const res: scansOverTimeByLocationReturnType = await queryXapi(query)
            n = res
        } catch (e) {
            console.error("Error getting scans over time by location", e)
        }
    }

    // set data
    const currentState = getState().data.demographics
    const data: AppState.DemographicsData = {
        ...currentState,
        scansOverTimeByLocation: n
    }
    dispatch(makeAction(Actions.SET_DEMOGRAPHICS_DATA, data))

    await dispatch(setLoading("scansOverTimeByLocation", false))

}

export const getScansOverTimeTotal = (dates: DateRange) => async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    await dispatch(setLoading("scansOverTimeTotal", true))

    let n: TimelineSlice[] = []

    const { demoBrandActive, brandInView, channelInView } = getState().experiences

    if (demoBrandActive) {
        n = getDemoDataScansOvertimeTotal(dates, 100)
    } else {
        const verbs = [xApiKeys.Verbs.CodeEntered]

        const ids = []
        if (!channelInView?.id) {
            for (const c of brandInView?.channels) {
                ids.push(c.id)
            }
        } else {
            ids.push(channelInView.id)
        }

        const base = buildXapiQueryBase(verbs, "channels", ids, dates)
        const details = xApiQueryLibrary.demographics.scansOverTimeTotal(dates)
        const query = [...base, ...details]

        try {
            const res: scansOverTimeTotalReturnType = await queryXapi(query)
            n = res
        }
        catch (e) {
            console.error("Error getting total scans over time", e)
        }
    }

    // set data
    const currentState = getState().data.demographics
    const data: AppState.DemographicsData = {
        ...currentState,
        scansOverTimeTotal: n
    }
    dispatch(makeAction(Actions.SET_DEMOGRAPHICS_DATA, data))

    await dispatch(setLoading("scansOverTimeTotal", false))

}


export const getTotalScans = (dates: DateRange) => async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    await dispatch(setLoading("totalScans", true))

    let n = {
        total: 0,
        breakdown: [] as Breakdown[]
    }

    const { demoBrandActive, brandInView, channelInView } = getState().experiences

    if (demoBrandActive) {
        n = getDemoDataTotalScans(100)
    } else {
        const verbs = [xApiKeys.Verbs.CodeEntered]

        const ids = []
        if (!channelInView?.id) {
            for (const c of brandInView?.channels) {
                ids.push(c.id)
            }
        } else {
            ids.push(channelInView.id)
        }

        const base = buildXapiQueryBase(verbs, "channels", ids, dates)
        const details = xApiQueryLibrary.demographics.totalScans()
        const query = [...base, ...details]

        try {
            const res: totalScansReturnType = await queryXapi(query)
            n.total = res[0]?.total[0]?.count
            n.breakdown = res[0]?.platform
        } catch (e) {
            console.error("Error getting total scans", e)
        }
    }

    // set data
    const currentState = getState().data.demographics
    const data: AppState.DemographicsData = {
        ...currentState,
        totalScans: n
    }
    dispatch(makeAction(Actions.SET_DEMOGRAPHICS_DATA, data))

    await dispatch(setLoading("totalScans", false))

}

// MAPS

export const getScansByLocation = (dates: DateRange) => async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    await dispatch(setLoading("scansByLocation", true))

    let n: MapCount[] = []
    const { demoBrandActive, brandInView, channelInView } = getState().experiences

    if (demoBrandActive) {
        n = getDemoDataScansByLocation(100)
    } else {
        const verbs = [xApiKeys.Verbs.CodeEntered]

        const ids = []
        if (!channelInView?.id) {
            for (const c of brandInView?.channels) {
                ids.push(c.id)
            }
        } else {
            ids.push(channelInView.id)
        }

        const base = buildXapiQueryBase(verbs, "channels", ids, dates)
        const details = xApiQueryLibrary.demographics.scansByLocation()
        const query = [...base, ...details]

        try {
            const res: scansByLocationReturnType = await queryXapi(query)
            n = res
        } catch (e) {
            console.error("Error getting scans by location", e)
        }
    }

    const currentState = getState().data.demographics
    const data: AppState.DemographicsData = {
        ...currentState,
        scansByLocation: n
    }
    dispatch(makeAction(Actions.SET_DEMOGRAPHICS_DATA, data))

    await dispatch(setLoading("scansByLocation", false))

}
