import { TableData } from "@declarations"
import { getRandomNumber } from "Utils/numbers"

const ids = [
    "001",
    "002",
    "003",
    "004",
    "005"
]

export const getDemoDataVisitors = (count: number): TableData.Visitor[] => {
    const visitors: TableData.Visitor[] = []

    for (let i = 1; i <= count; i++) {
        const ep: TableData.Visitor = {
            userId: ids[getRandomNumber(0, ids.length - 1)],
            lastDateAccess: new Date().valueOf(),
            opens: getRandomNumber(1, 25),
            sessions: getRandomNumber(1, 25),
            averageTimeViewed: getRandomNumber(1000, 100000),
            totalTimeViewed: getRandomNumber(1000, 100000)
        }
        visitors.push(ep)
    }

    return visitors
}