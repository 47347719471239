import { AppState, PageProps, VisitorBehaviorTab } from "@declarations"
import { connect } from "react-redux"
import { store } from "Store/store"
import { getDashboardName } from "Utils/routes"
import "./VisitorBehavior.css"
import Tabs from "Components/Tabs/Tabs"
import { useState } from "react"
import DownloadReportButton from "Components/DownloadReportButton/DownloadReportButton"
import SummaryRow from "./SummaryRow/SummaryRow"
import TableArea from "./TableArea/TableArea"
import ChartArea from "./ChartArea/ChartArea"
import DownloadCSVButton from "Components/DownloadCSVButton/DownloadCSVButton"

interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {
}

const VisitorBehavior = (props: Props) => {

    const {
        brandInView,
        channelInView,
        topViewedEpisodesData,
        visitorsData,
        ctaData,
        bounceRatesData
    } = props

    const [tab, setTab] = useState<VisitorBehaviorTab>("Top Viewed Episodes")

    const options: VisitorBehaviorTab[] = [
        "Top Viewed Episodes",
        "Visitors",
        "Bounce Rates",
        "Calls to Action"
    ]

    const handleTabSelection = (option: VisitorBehaviorTab | string) => {
        setTab(option as VisitorBehaviorTab)
    }

    const renderCSVDownload = (tab: VisitorBehaviorTab) => {
        switch (tab) {
            case "Top Viewed Episodes":
                return topViewedEpisodesData && topViewedEpisodesData.length
                    ? <DownloadCSVButton
                        title={tab}
                        headers={Object.keys(topViewedEpisodesData[0])}
                        data={topViewedEpisodesData} />
                    : null
            case "Visitors":
                return visitorsData && visitorsData.length
                    ? <DownloadCSVButton
                        title={tab}
                        headers={Object.keys(visitorsData[0])}
                        data={visitorsData} />
                    : null
            case "Bounce Rates":
                return bounceRatesData && bounceRatesData.length
                    ? <DownloadCSVButton
                        title={tab}
                        headers={Object.keys(bounceRatesData[0])}
                        data={bounceRatesData} />
                    : null
            case "Calls to Action":
                return ctaData && ctaData.length
                    ? <DownloadCSVButton
                        title={tab}
                        headers={Object.keys(ctaData[0])}
                        data={ctaData} />
                    : null
            default:
                return null
        }
    }

    return (
        <div className="page">
            <div className="header">
                <h2>{getDashboardName(brandInView?.name, channelInView?.name, "visitor-behavior")}</h2>
                <DownloadReportButton page="visitor-behavior" />
            </div>
            <Tabs
                options={options}
                select={handleTabSelection}
                selected={tab}
            />
            <div className="box">
                <div className="header-row" style={{ margin: "1rem 0 2rem" }}>
                    <h2 style={{ margin: 0 }}>{tab}</h2>
                    {renderCSVDownload(tab)}
                </div>
                <SummaryRow tab={tab} />
                <ChartArea tab={tab} />
                <TableArea tab={tab} />
            </div>
        </div >
    )
}

const mapStateToProps = (state: AppState) => {
    return {
        brandInView: state.experiences.brandInView,
        channelInView: state.experiences.channelInView,
        topViewedEpisodesData: state.data.visitorBehavior.topViewedEpisodes.topViewedEpisodes,
        visitorsData: state.data.visitorBehavior.visitors.visitors,
        ctaData: state.data.visitorBehavior.cta.cta,
        bounceRatesData: state.data.visitorBehavior.bounceRates.bounceRates,
    }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(VisitorBehavior)