
const returnExample = [
    {
        "_id": "https://aritize.nextechar.com/experience/7bbd77a3-36e6-4612-8827-295f5c81a720",
        "date": "2021-05-20T04:39:40.699Z",
        "scans": 0,
        "bounceRate": 30.37037037037037,
        "avgDuration": 36.18556701030928,
        "totalDuration": 3510
    },
]

export type bounceRateDetailsReturnType = typeof returnExample

const buildBounceRateDetails = (): any[] => [
    {
        "$group": {
            "_id": {
                "object": "$statement.object.id",
                "verb": "$statement.verb.id"
            },
            "count": {
                "$sum": 1
            },
            "date": {
                "$push": {
                    "time": "$statement.timestamp"
                }
            },
            "secondsFromStart": {
                "$push": {
                    "seconds": "$EXT.https://aritize*`*nextechar*`*com/secondsfromstart"
                }
            }
        }
    },
    {
        "$facet": {
            "Launch Date": [
                {
                    "$match": {
                        "_id.verb": "http://activitystrea.ms/schema/1.0/experience"
                    }
                },
                {
                    "$group": {
                        "_id": "$_id.object",
                        "date": {
                            "$min": "$date.time"
                        },
                    }
                }
            ],
            "Scans": [
                {
                    "$match": {
                        "_id.verb": "http://activitystrea.ms/schema/1.0/experience",
                    }
                },
                {
                    "$group": {
                        "_id": "$_id.object",
                        "scanCount": {
                            "$sum": "$count"
                        }
                    }
                }
            ],
            "Bounce Rates": [
                {
                    "$match": {
                        "_id.verb": {
                            "$in": [
                                "https://w3id.org/xapi/dod-isd/verbs/placed",
                                "http://activitystrea.ms/schema/1.0/experience"
                            ]
                        }
                    }
                },
                {
                    "$project": {
                        "_id": {
                            "object": "$_id.object",
                            "verb": "$_id.verb"
                        },
                        "count": "$count"
                    }
                },
                {
                    "$group": {
                        "_id": "$_id.object",
                        "sum": {
                            "$sum": "$count"
                        },
                        "counts": {
                            "$push": {
                                "verb": "$_id.verb",
                                "count": "$count"
                            }
                        }
                    }
                },
                // // Stage 2
                {
                    "$unwind": {
                        "path": "$counts",
                    }
                },
                // // // Stage 3
                {
                    "$project": {
                        "_id": "$_id",
                        "counts": "$counts.count",
                        "verb": "$counts.verb",
                        "sum": "$sum",
                        "percent": {
                            "$multiply": [
                                {
                                    "$subtract": [
                                        1,
                                        {
                                            "$divide": [
                                                "$counts.count",
                                                {
                                                    "$subtract": [
                                                        "$sum",
                                                        "$counts.count"
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                100
                            ]
                        }
                    }
                },
                {
                    "$match": {
                        "verb": {
                            "$in": [
                                "https://w3id.org/xapi/dod-isd/verbs/placed"
                            ]
                        }
                    }
                },
                {
                    "$project": {
                        "_id": "$_id",
                        "rate": "$percent"
                    }
                }
            ],
            "Average Time Viewed": [
                {
                    "$match": {
                        "_id.verb": "http://activitystrea.ms/schema/1.0/close",
                    }
                },
                {
                    "$unwind": "$secondsFromStart"
                },
                {
                    "$group": {
                        "_id": "$_id.object",
                        "avgDuration": {
                            "$avg": "$secondsFromStart.seconds"
                        }
                    }
                }
            ],
            "Total Time Viewed": [
                {
                    "$match": {
                        "_id.verb": "http://activitystrea.ms/schema/1.0/close",
                    }
                },
                {
                    "$unwind": "$secondsFromStart"
                },
                {
                    "$group": {
                        "_id": "$_id.object",
                        "totalDuration": {
                            "$sum": "$secondsFromStart.seconds"
                        }
                    }
                }
            ],
        }
    },
    {
        "$project": {
            "all": {
                "$concatArrays": [
                    "$Launch Date",
                    "$Scans",
                    "$Bounce Rates",
                    "$Average Time Viewed",
                    "$Total Time Viewed"
                ]
            }
        }
    },
    {
        "$unwind": "$all"
    },
    {
        "$group": {
            "_id": "$all._id",
            "date": {
                "$first": {
                    "$min": "$all.date"
                }
            },
            "scans": {
                "$sum": "$all.scanCount"
            },
            "bounceRate": {
                "$sum": "$all.rate"
            },
            "avgDuration": {
                "$avg": "$all.avgDuration"
            },
            "totalDuration": {
                "$sum": "$all.totalDuration"
            },
        }
    },
]
export default buildBounceRateDetails