import { AppState, PageProps, PageView, RouteParams } from "@declarations"
import { makeAction, Actions } from "Store/Actions/ActionsTypes"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, useLocation, useParams } from "react-router-dom"
import { store } from "Store/store"
import { getPageName, getPathRoot } from "Utils/routes"
import dropArrow from "Assets/images/page-header/drop-arrow.png"
import "./PageHeader.css"
import useOutsideClick from "Utils/useOutsideClick"
import { getBrands, setBrandInView } from "Store/Actions/ExperienceActions"
import { demoSite } from "Values/DemoData/demoSite"

type Props = PageProps<typeof mapStateToProps, typeof mapDispatchToProps>

const PageHeader = (props: Props) => {

    const { brandsList, brandInView, channelInView, setBrandInView, getBrandList, setDemoSiteActive, setGlobalView } = props

    const { brand, channel } = useParams<RouteParams>()

    const [showDropdown, setShowDropdown] = useState<boolean>(false)

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown)
    }
    const { ref, isComponentVisible } = useOutsideClick();

    useEffect(() => {
        if (!isComponentVisible) {
            setShowDropdown(false)
        }
    }, [isComponentVisible])

    const location = useLocation()
    const pathRoot = getPathRoot(location.pathname)

    useEffect(() => {
        if (!brand) {
            setBrandInView({} as AppState.Brand)
            setDemoSiteActive(false)
            setGlobalView(true)
            return;
        }
        if (!brandsList || !brandsList.length) {
            getBrandList()
            return
        }
        if (brand === demoSite.id) {
            setDemoSiteActive(true)
            if (channel) {
                const demoChannel = demoSite.channels.find((c: AppState.Channel) => c.id === channel)
                setBrandInView(demoSite, demoChannel)
            } else {
                setBrandInView(demoSite)
            }
        } else {
            const b = brandsList.filter(item => item.id === brand)[0]
            let c: AppState.Channel | undefined
            if (channel) {
                c = b?.channels?.find((ch: AppState.Channel) => ch.id === channel)
            }
            setBrandInView(b, c)
        }
    }, [brand, brandsList, channel, getBrandList, setBrandInView, setDemoSiteActive, setGlobalView])

    return (
        <div className="page-header">
            <div className="title">
                <h1>{getPageName(brandInView?.name, pathRoot as PageView)}</h1>
            </div>
            <div className="dropdown-wrapper" ref={ref}>
                {brand &&
                    <>
                        <h3>Product Selection</h3>
                        <div className={showDropdown ? "selected-dropdown dropdown" : "dropdown"}>
                            <button onClick={toggleDropdown}>
                                <h4>{channelInView?.name || brandInView?.name}</h4>
                                <img src={dropArrow} alt="open selection" className={showDropdown ? 'selected-arrow' : 'arrow'} />
                            </button>
                            {showDropdown &&
                                <div className="dropdown-list" >
                                    {brandInView &&
                                        <Link
                                            to={`/${pathRoot}/brand/${brandInView.id}`}
                                            className={!channel ? "selected-link" : ""}
                                            onClick={() => setShowDropdown(false)}
                                        >
                                            {brandInView.name}
                                        </Link>
                                    }
                                    {brandInView?.channels?.map((c: AppState.Channel) => (
                                        <Link
                                            key={c.id}
                                            to={`/${pathRoot}/channel/${brandInView.id}/${c.id}`}
                                            className={channel === c.id ? "selected-link" : ""}
                                            onClick={() => setShowDropdown(false)}
                                        >
                                            {c.name}
                                        </Link>
                                    ))}
                                </div>
                            }
                        </div>
                    </>
                }
            </div>
        </div >
    )
}

const mapStateToProps = (state: AppState) => {
    return {
        brandsList: state.experiences.brandsList,
        brandInView: state.experiences.brandInView,
        channelInView: state.experiences.channelInView
    }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
        setBrandInView: (brand: AppState.Brand, channel?: AppState.Channel) => dispatch(setBrandInView(brand, channel)),
        getBrandList: () => dispatch(getBrands()),
        setDemoSiteActive: (active: boolean) => dispatch(makeAction(Actions.SET_DEMO_BRAND_ACTIVE, active)),
        setGlobalView: (active: boolean) => dispatch(makeAction(Actions.SET_GLOBAL_VIEW, active))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PageHeader)