import { DateRange, MarkedTimelineSlice } from "@declarations"
import { A_DAY } from "Utils/dates"
import { getRandomNumber } from "Utils/numbers"

export const getDemoDataPushNotificationStatusTimeline = (dates: DateRange, count: number): MarkedTimelineSlice[] => {
    const br: MarkedTimelineSlice[] = []

    let onMin = 100
    let onMax = 2500

    for (let i = 1; i <= count; i++) {
        const d = new Date().setTime(dates.start + A_DAY * (i - 1))

        onMin += 100
        onMax += 100

        const ep: MarkedTimelineSlice = {
            id: "on",
            count: getRandomNumber(onMin, onMax),
            instance: d < dates.end ? new Date(d).toISOString() : new Date(dates.end).toISOString()
        }
        br.push(ep)

        if (d === dates.end) break
    }

    let offMin = 1500
    let offMax = 2500

    for (let i = 1; i <= count; i++) {
        const d = new Date().setTime(dates.start + A_DAY * (i - 1))

        offMin -= 100
        offMax -= 100

        if (offMin < 0) offMin = 0
        if (offMax < 0) offMax = 0

        const ep: MarkedTimelineSlice = {
            id: "off",
            count: getRandomNumber(offMin, offMax),
            instance: d < dates.end ? new Date(d).toISOString() : new Date(dates.end).toISOString()
        }
        br.push(ep)

        if (d === dates.end) break
    }

    return br
}