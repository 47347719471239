import { xApiStatementKeys } from "Values/xApiKeys"

const returnExample = [
    {
        "_id": "https://aritize.nextechar.com/experience/2aa6c76a-9900-42a9-8204-48ced69ab5f6",
        "count": 116
    }
]

export type topViewedEpisodesReturnType = typeof returnExample

const buildTopViewedEpisodeToDate = (): any[] => [
    {
        "$group": {
            "_id": `$${xApiStatementKeys.objectId}`,
            "count": {
                "$sum": 1
            }
        }
    },
    {
        "$sort": {
            "count": -1
        }
    },
    {
        "$limit": 1
    }
]
export default buildTopViewedEpisodeToDate