import { PageProps, AppState, Session, MomentRange, DateRange } from "@declarations"
import { getScansOverTimeTotal } from "Store/Actions/DataActions-Demographics"
import DatePicker from "Components/DatePicker/DatePicker"
import TimelineChart from "Components/TimelineChart/TimelineChart"
import { useEffect } from "react"
import { connect } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { store } from "Store/store"
import EmptyState from "Components/EmptyState/EmptyState"
import { buildToLink } from "Utils/routes"
import rightArrowBlue from "Assets/images/arrows/right-arrow-blue.svg"
import Skeleton from "react-loading-skeleton"
import { setDemographicsDateRange } from "Store/Actions/DateActions"
import useChartWidth from "Utils/useChartWidth"
import { callCheck } from "Utils/data"

interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {
}

const TotalScansOverTime = (props: Props) => {

    const { session, experiences, dataList, getDataList, loadingData, demoBrandActive, dates, updateDates, globalView } = props

    const location = useLocation()

    useEffect(() => {

        if (!callCheck(globalView, (session as Session.AuthSession).user.admin, experiences?.length)) return

        getDataList(dates)

    }, [experiences, getDataList, session, demoBrandActive, dates, globalView])

    const width = useChartWidth()

    return (
        <div className='box divided-box'>
            <div className="divided-box-header">
                <div className="header-row">
                    <h3>Total scans over time</h3>
                    <DatePicker
                        startDate={dates.start}
                        endDate={dates.end}
                        startDateId={"startDateTSOT"}
                        endDateId={"endDateTSOT"}
                        updateDates={updateDates}
                    />
                </div>
            </div>
            <div className="divided-box-content">
                {loadingData
                    ? <Skeleton height={500} />
                    : dataList && dataList.length
                        ? <TimelineChart
                            width={width}
                            height={500}
                            startDate={dates.start}
                            endDate={dates.end}
                            data={dataList}
                            legendTitle="Number of Scans"
                        />
                        : <EmptyState filterableData={true} />
                }
                <div className="link-row">
                    <Link to={buildToLink(location.pathname, "/demographics")}>
                        <span>View Demographics Report</span>
                        <img src={rightArrowBlue} alt="arrow" />
                    </Link>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: AppState) => {
    return {
        session: state.session,
        experiences: state.experiences.experienceIdList,
        globalView: state.experiences.globalView,
        demoBrandActive: state.experiences.demoBrandActive,
        dataList: state.data.demographics.scansOverTimeTotal,
        dates: state.dates.demographics.scansOverTimeTotal,
        loadingData: state.loading.demographics.scansOverTimeTotal
    }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
        getDataList: (dates: DateRange) => dispatch(getScansOverTimeTotal(dates)),
        updateDates: (dateRange: MomentRange) => dispatch(setDemographicsDateRange(dateRange, "scansOverTimeTotal"))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TotalScansOverTime)
