import { AppState } from "@declarations";
import { Constants } from "Values/constants";

export const getExperiencesSummary = async (): Promise<AppState.ExperiencesSummary[]> => {
    const resp: Response = await fetch(`${Constants.arc_url}/experiences/summary`);
    return resp.json();
}

export const getBrandInfo = async (brandId: string) => {
    const response = await fetch(`${Constants.arc_url}/brands/${brandId}`, {
        method: 'GET',
        credentials: 'include'
    });
    if (response.status !== 200) throw (await response.json())
    return response.json();
}
