import { AppState, PageProps, Session, VisitorBehaviorTab } from "@declarations"
import SummaryBox from "Pages/DashboardNav/VisitorBehavior/SummaryBox/SummaryBox"
import Skeleton from "react-loading-skeleton"
import tvIcon from "Assets/images/data/visitor-behavior/tv-icon.svg"
import eyeIcon from "Assets/images/data/visitor-behavior/eye-icon.svg"
import scanIcon from "Assets/images/data/visitor-behavior/scan-icon.svg"
import percentageIconGreen from "Assets/images/data/visitor-behavior/percentage-icon-green.svg"
import percentageIconPurple from "Assets/images/data/visitor-behavior/percentage-icon-purple.svg"
import graphIcon from "Assets/images/data/visitor-behavior/graph-icon.svg"
import timeIcon from "Assets/images/data/visitor-behavior/time-icon.svg"
import clickIcon from "Assets/images/data/visitor-behavior/click-icon.svg"
import { connect } from "react-redux"
import { store } from "Store/store"
import { useEffect } from "react"
import { getBounceRatesSummaryData, getCTASummaryData, getTopViewedEpisodeSummaryData, getVisitorsSummaryData } from "Store/Actions/DataActions-VisitorBehavior"
import { cleanDuration } from "Utils/dates"
import { callCheck } from "Utils/data"

type Props = PageProps<typeof mapStateToProps, typeof mapDispatchToProps> & {
    tab: VisitorBehaviorTab
}

const SummaryRow = (props: Props) => {

    const { session,
        experiences,
        globalView,
        data,
        loadingDataTopViewedEpisodes,
        loadingDataBounceRates,
        loadingDataCTA,
        loadingDataVisitors,
        tab, demoBrandActive,
        getTopViewedEpisodeSummaryData, getVisitorsSummaryData, getBounceRatesSummaryData, getCTASummaryData
    } = props

    useEffect(() => {

        if (!callCheck(globalView, (session as Session.AuthSession).user.admin, experiences?.length)) return

        switch (tab) {
            case "Top Viewed Episodes":
                getTopViewedEpisodeSummaryData()
                return
            case "Visitors":
                getVisitorsSummaryData()
                return
            case "Bounce Rates":
                getBounceRatesSummaryData()
                return
            case "Calls to Action":
                getCTASummaryData()
                return
            default:
                console.log("At admin analytics page")
        }

    }, [demoBrandActive, experiences, getBounceRatesSummaryData, getCTASummaryData, getTopViewedEpisodeSummaryData, getVisitorsSummaryData, globalView, session, tab])


    const setLoading = (tab: VisitorBehaviorTab): boolean => {
        switch (tab) {
            case "Top Viewed Episodes":
                return loadingDataTopViewedEpisodes
            case "Visitors":
                return loadingDataVisitors
            case "Bounce Rates":
                return loadingDataBounceRates
            case "Calls to Action":
                return loadingDataCTA
            default: return false
        }
    }
    const renderSummaryBoxes = (tab: VisitorBehaviorTab) => {
        switch (tab) {
            case "Top Viewed Episodes":
                return <>
                    <SummaryBox
                        variant="green"
                        icon={tvIcon}
                        value={data.topViewedEpisodes.summaryData.topViewedEpisodeToDate
                            ? data.topViewedEpisodes.summaryData.topViewedEpisodeToDate
                            : "--"}
                        text={"Top viewed episode to date"}
                    />
                    <SummaryBox
                        variant="blue"
                        icon={eyeIcon}
                        value={data.topViewedEpisodes.summaryData.mostViewsOfAnEpisode
                            ? data.topViewedEpisodes.summaryData.mostViewsOfAnEpisode?.toLocaleString()
                            : "--"}
                        text={"Most views of an episode"}
                    />
                    <SummaryBox
                        variant="purple"
                        icon={timeIcon}
                        value={data.topViewedEpisodes.summaryData.avgViewTime
                            ? cleanDuration(data.topViewedEpisodes.summaryData.avgViewTime)
                            : "--"}
                        text={"Avg. view time"}
                    />
                </>
            case "Visitors":
                return <>
                    <SummaryBox
                        variant="green"
                        icon={scanIcon}
                        value={data.visitors.summaryData.totalScansToDate
                            ? data.visitors.summaryData.totalScansToDate.toLocaleString()
                            : "--"}
                        text={"Total scans to date"}
                    />
                    <SummaryBox
                        variant="blue"
                        icon={eyeIcon}
                        value={data.visitors.summaryData.totalAccumulatedViewTime
                            ? cleanDuration(data.visitors.summaryData.totalAccumulatedViewTime)
                            : "--"}
                        text={"Total accumulated view time"}
                    />
                    <SummaryBox
                        variant="purple"
                        icon={timeIcon}
                        value={data.visitors.summaryData.mostTrafficDuringTimeOfDay
                            ? `${data.visitors.summaryData.mostTrafficDuringTimeOfDay[0]}-${data.visitors.summaryData.mostTrafficDuringTimeOfDay[1]} ${data.visitors.summaryData.mostTrafficDuringTimeOfDay[2]}`
                            : "--"}
                        text={"Most traffic during time of day"}
                    />
                </>
            case "Bounce Rates":
                return <>
                    <SummaryBox
                        variant="green"
                        icon={percentageIconGreen}
                        value={data.bounceRates.summaryData.overallBounceRateToDate
                            ? `${data.bounceRates.summaryData.overallBounceRateToDate.toFixed(2)}%`
                            : "--"}
                        text={"Overall bounce rate to date"}
                    />
                    <SummaryBox
                        variant="blue"
                        icon={graphIcon}
                        value={data.bounceRates.summaryData.bouncRateComparedToLastMonth.comparison
                            ? `${data.bounceRates.summaryData.bouncRateComparedToLastMonth.comparison} ${data.bounceRates.summaryData.bouncRateComparedToLastMonth.value.toFixed(2)}%`
                            : "--"}
                        text={"Compared to last month"}
                    />
                    <SummaryBox
                        variant="purple"
                        icon={timeIcon}
                        value={data.bounceRates.summaryData.avgSessionDuration
                            ? cleanDuration(data.bounceRates.summaryData.avgSessionDuration)
                            : "--"}
                        text={"Avg. session duration"}
                    />
                </>
            case "Calls to Action":
                return <>
                    <SummaryBox
                        variant="pink"
                        icon={clickIcon}
                        value={data.cta.summaryData.totalClicksToDate
                            ? data.cta.summaryData.totalClicksToDate.toLocaleString()
                            : "--"}
                        text={"Total clicks to date"}
                    />
                    <SummaryBox
                        variant="blue"
                        icon={graphIcon}
                        value={data.cta.summaryData.ctaComparedToLastMonth.comparison
                            ? `${data.cta.summaryData.ctaComparedToLastMonth.comparison} ${data.cta.summaryData.ctaComparedToLastMonth.value.toFixed(2)}%`
                            : "--"}
                        text={"Compared to last month"}
                    />
                    <SummaryBox
                        variant="purple"
                        icon={percentageIconPurple}
                        value={data.cta.summaryData.avgClickRate
                            ? `${data.cta.summaryData.avgClickRate.toFixed(2)}%`
                            : "--"}
                        text={"Avg. click rate"}
                    />
                </>
            default:
                return <>
                </>
        }
    }

    return (
        <div className="summary-row">
            {setLoading(tab)
                ? <><Skeleton height={91} />
                    <Skeleton height={91} />
                    <Skeleton height={91} />
                </>
                : renderSummaryBoxes(tab)
            }
        </div>
    )
}

const mapStateToProps = (state: AppState) => {
    return {
        session: state.session,
        experiences: state.experiences.experienceIdList,
        globalView: state.experiences.globalView,
        demoBrandActive: state.experiences.demoBrandActive,
        data: state.data.visitorBehavior,
        loadingDataTopViewedEpisodes: state.loading.visitorBehavior.topViewedEpisodes.summaryData,
        loadingDataVisitors: state.loading.visitorBehavior.visitors.summaryData,
        loadingDataBounceRates: state.loading.visitorBehavior.bounceRates.summaryData,
        loadingDataCTA: state.loading.visitorBehavior.cta.summaryData
    }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
        getTopViewedEpisodeSummaryData: () => dispatch(getTopViewedEpisodeSummaryData()),
        getVisitorsSummaryData: () => dispatch(getVisitorsSummaryData()),
        getBounceRatesSummaryData: () => dispatch(getBounceRatesSummaryData()),
        getCTASummaryData: () => dispatch(getCTASummaryData()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryRow)