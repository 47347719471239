
import { Constants } from "Values/constants"
import { xApiKeys } from "Values/xApiKeys"

const returnExample = [
    {
        "region": "Andhra Pradesh",
        "country": "India",
        "count": 4
    }
]

export type scansByLocationReturnType = typeof returnExample

const buildScansByLocation = (): any[] => [
    {
        "$group": {
            "_id": {
                "state": xApiKeys.Extensions.state.path,
                "country": xApiKeys.Extensions.country.path,
            },
            "count": {
                "$sum": 1
            },
        }
    },
    {
        "$project": {
            "_id": 0,
            "region": { $ifNull: ["$_id.state", Constants.unavailable_data_label] },
            "country": { $ifNull: ["$_id.country", Constants.unavailable_data_label] },
            "count": "$count"
        }
    }
]
export default buildScansByLocation