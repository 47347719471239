import { PageProps, AppState, Session, MomentRange, DateRange, TimelineSliceByLocation } from "@declarations"
import { getScansOverTimeByLocation } from "Store/Actions/DataActions-Demographics"
import DatePicker from "Components/DatePicker/DatePicker"
import TimelineChart from "Components/TimelineChart/TimelineChart"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { store } from "Store/store"
import EmptyState from "Components/EmptyState/EmptyState"
import LocationSelector from "Components/LocationSelector/LocationSelector"
import { filterDataByLocation } from "Utils/locations"
import Skeleton from "react-loading-skeleton"
import { setDemographicsDateRange } from "Store/Actions/DateActions"
import useChartWidth from "Utils/useChartWidth"
import { callCheck } from "Utils/data"

interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {
}

const ScansOverTimeByLocation = (props: Props) => {

    const { session, globalView, experiences, dataList, getDataList, loadingData, demoBrandActive, dates, updateDates } = props

    const [locationFilter, setLocationFilter] = useState({
        country: "",
        region: ""
    })

    useEffect(() => {

        if (!callCheck(globalView, (session as Session.AuthSession).user.admin, experiences?.length)) return

        getDataList(dates)

    }, [experiences, getDataList, session, demoBrandActive, dates, globalView])

    const handleLocationFilter = (country: string, region: string) => {
        setLocationFilter({ country, region })
    }

    const [filteredData, setFilteredData] = useState<TimelineSliceByLocation[]>([])

    useEffect(() => {
        const f = filterDataByLocation(dataList, locationFilter)
        setFilteredData(f)
    }, [dataList, locationFilter])

    const width = useChartWidth()

    return (
        <div className='box divided-box'>
            <div className="divided-box-header">
                <div className="header-row">
                    <h3>Scans over time by location</h3>
                    <div style={{ marginLeft: "auto", marginRight: "1rem" }}>
                        <LocationSelector data={dataList} sendLocation={handleLocationFilter} />
                    </div>
                    <DatePicker
                        startDate={dates.start}
                        endDate={dates.end}
                        startDateId={"startDateSOTBL"}
                        endDateId={"endDateSOTBL"}
                        updateDates={updateDates}
                    />
                </div>
            </div>
            <div className="divided-box-content">
                {loadingData
                    ? <Skeleton height={500} />
                    : filteredData && filteredData.length
                        ? <TimelineChart
                            width={width}
                            height={500}
                            startDate={dates.start}
                            endDate={dates.end}
                            data={filteredData}
                            legendTitle="Number of Scans"
                        />
                        : <EmptyState filterableData={true} />
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state: AppState) => {
    return {
        session: state.session,
        experiences: state.experiences.experienceIdList,
        globalView: state.experiences.globalView,
        demoBrandActive: state.experiences.demoBrandActive,
        dataList: state.data.demographics.scansOverTimeByLocation,
        dates: state.dates.demographics.scansOverTimeByLocation,
        loadingData: state.loading.demographics.scansOverTimeByLocation
    }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
        getDataList: (dates: DateRange) => dispatch(getScansOverTimeByLocation(dates)),
        updateDates: (dateRange: MomentRange) => dispatch(setDemographicsDateRange(dateRange, "scansOverTimeByLocation"))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ScansOverTimeByLocation)