import { TableData } from "@declarations"
import { getRandomNumber } from "Utils/numbers"

const titles = [
    "Spring 2021 Promo BOGO",
    "Summer 2021 Promo Intro",
    "Fall 2021 Promo Tutorial",
    "Winter 2021 Promo Tutorial",
]

const places = [
    "Ontario, Canada",
    "British Columbia, Canada",
    "Texas, United States",
    "California, United States"
]

export const getDemoDataQRCodesByLocation = (count: number): TableData.QRCodesByLocation[] => {
    const episodes: TableData.QRCodesByLocation[] = []

    for (let i = 1; i <= count; i++) {
        const place = places[getRandomNumber(0, places.length - 1)]

        const ep: TableData.QRCodesByLocation = {
            rank: 0,
            QRCodeTitle: titles[getRandomNumber(0, titles.length - 1)],
            numberOfOpens: getRandomNumber(1, 25),
            country: place.split(",")[1].trim(),
            region: place.split(",")[0].trim()
        }
        episodes.push(ep)
    }

    episodes.sort((a, b) => b.numberOfOpens - a.numberOfOpens)

    episodes.forEach((e, idx) => {
        e.rank = idx + 1
    })

    return episodes
}