import axios from 'axios';
import { handle401 } from "Api/Auth/auth"
import { Constants } from "Values/constants";

export const axiosConfig = (): void => {
    axios.defaults.baseURL = Constants.arc_url;
    axios.defaults.withCredentials = true;
    axios.defaults.headers.post['Content-Type'] = 'application/json';

    axios.interceptors.response.use(
        response => response,
        error => handle401(error)
    );
    return;
}