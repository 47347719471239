import { MapCount } from "@declarations"
import { getRandomNumber } from "Utils/numbers"

const Canada = [
    "British Columbia, Canada",
    "Yukon, Canada",
    "Northwest Territories, Canada",
    "Nunavut, Canada",
    "Alberta, Canada",
    "Saskatchewan, Canada",
    "Manitoba, Canada",
    "Ontario, Canada",
    "Québec, Canada",
    "Prince Edward Island, Canada",
    "Newfoundland and Labrador, Canada",
    "Nova Scotia, Canada",
    "New Brunswick, Canada",
]

const USA = [
    "Alabama, United States",
    "Alaska, United States",
    "American Samoa, United States",
    "Arizona, United States",
    "Arkansas, United States",
    "California, United States",
    "Colorado, United States",
    "Connecticut, United States",
    "Delaware, United States",
    "District Of Columbia, United States",
    "Federated States Of Micronesia, United States",
    "Florida, United States",
    "Georgia, United States",
    "Guam, United States",
    "Hawaii, United States",
    "Idaho, United States",
    "Illinois, United States",
    "Indiana, United States",
    "Iowa, United States",
    "Kansas, United States",
    "Kentucky, United States",
    "Louisiana, United States",
    "Maine, United States",
    "Marshall Islands, United States",
    "Maryland, United States",
    "Massachusetts, United States",
    "Michigan, United States",
    "Minnesota, United States",
    "Mississippi, United States",
    "Missouri, United States",
    "Montana, United States",
    "Nebrask, United Statesa",
    "Nevada, United States",
    "New Hampshire, United States",
    "New Jersey, United States",
    "New Mexico, United States",
    "New York, United States",
    "North Carolina, United States",
    "North Dakota, United States",
    "Northern Mariana Islands, United States",
    "Ohio, United States",
    "Oklahoma, United States",
    "Oregon, United States",
    "Palau, United States",
    "Pennsylvania, United States",
    "Puerto Rico, United States",
    "Rhode Island, United States",
    "South Carolina, United States",
    "South Dakota, United States",
    "Tennessee, United States",
    "Texas, United States",
    "Utah, United States",
    "Vermont, United States",
    "Virgin Islands, United States",
    "Virginia, United States",
    "Washington, United States",
    "West Virginia, United States",
    "Wisconsin, United States",
    "Wyoming, United States"
]

const places = [...Canada, ...USA]

const MIN = 100
const MAX = 2500

export const getDemoDataScansByLocation = (count: number): MapCount[] => {
    const episodes: MapCount[] = []

    for (let i = 1; i <= count; i++) {
        const place = places[i % places.length]
        const ep: MapCount = {
            count: getRandomNumber(MIN, MAX),
            region: place.split(",")[0].trim(),
            country: place.split(",")[1].trim()
        }
        episodes.push(ep)
    }

    return episodes
}