import { TableData } from "@declarations"
import { getRandomNumber } from "Utils/numbers"

const titles = [
    "Spring 2021 Promo",
    "Summer 2021 Promo",
    "Fall 2021 Promo",
    "Winter 2021 Promo",
]

export const getDemoDataBounceRates = (count: number): TableData.BounceRate[] => {
    const br: TableData.BounceRate[] = []

    for (let i = 1; i <= count; i++) {
        const ep: TableData.BounceRate = {
            episodeTitle: titles[getRandomNumber(0, titles.length - 1)],
            datePosted: new Date().valueOf(),
            bounceRate: getRandomNumber(1, 25),
            opens: getRandomNumber(1, 25),
            averageTimeDroppedOff: getRandomNumber(1000, 100000),
            totalTimeViewed: getRandomNumber(1000, 100000)
        }
        br.push(ep)
    }

    return br
}