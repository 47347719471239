import React, { useState } from "react"
import Form from 'react-bootstrap/Form';
import InputGroup from "react-bootstrap/InputGroup";
import { sessionLogin } from "Store/Actions/SessionActions";
import { connect } from "react-redux";
import { store } from "Store/store";
import { AppState, PageProps, Session } from "@declarations";
// import { login, setAxiosAuthHeader } from "../../utils/api/auth";
// import { getAuthToken, setSession } from "../../utils/session";
// import nextechLogo from "../../assets/images/nextech-logo.png";
// import "./auth-form.css"
import "./Login.css"
import { Spinner } from "react-bootstrap";

interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {
}

const Login = (props: Props) => {


    const { session, login } = props
    const loginError = (session as Session.GuestSession).error

    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [validated, setValidated] = useState(false);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
            return
        }

        login(email, password)
    }

    return (
        <div>
            <Form
                noValidate validated={validated}
                className="form auth-form"
                onSubmit={handleSubmit}>
                {/* <img className="logo" src={nextechLogo} alt="nextech-logo" />
                <img className="logo" src={holoxLogo} alt="nextech-logo" /> */}
                <h2>nextech AR Analytics</h2>
                <h5>Sign in to view analytics across your products</h5>
                <br />
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                            type="email"
                            placeholder="Enter email"
                            required
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.currentTarget.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter a valid email.
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>

                <br />
                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            required
                            onChange={e => setPassword(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter a password.
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
                {/* <Link to={`/forgot-password`}>Forgot password?</Link> */}
                <br />
                <p className="error">{loginError}</p>
                <br />
                <button className="primary" type="submit">
                    {session.state === "LoggingIn"
                        ? <Spinner animation="border" variant="light" size="sm" />
                        : "Log In"}
                </button>
                {/* <br />
                <br />
                <p>Don't have an account yet? <Link to={`${ADMIN_PORTAL_ROOT_PATH}/signup`}>Sign Up</Link></p>  */}
            </Form>
        </div>
    )
}

const mapStateToProps = (state: AppState) => {
    return { session: state.session }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
        login: (email: string, password: string) => dispatch(sessionLogin(email, password))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Login)
