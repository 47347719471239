import { PageView } from "@declarations";
import { capitalizeFirstLetter } from "./strings";

export const getPathRoot = (pathname: string) => {
    const root = pathname.split("/")
    root.shift()
    return root[0]
}

export const cleanPathRoot = (root: string) => {
    return root.split("-").map(s => capitalizeFirstLetter(s)).join(" ")
}

export const buildToLink = (pathname: string, page: string): string => {
    const path = pathname.split("/")
    path.shift()

    // if returning to home page then let through
    if (page === "/") return page
    // else keep user in the brand or channel they're currently viewing
    else if (path.length === 3) return `${page}/${path[1]}/${path[2]}`
    else if (path.length === 4) return `${page}/${path[1]}/${path[2]}/${path[3]}`
    return page
}

export const getPageName = (brandName: string, root: PageView) => {
    if (!root) return `Global Brand List`

    const page = cleanPathRoot(root)

    if (!brandName) return `Global ${page}`
    return `${brandName} ${page}`
}

export const getDashboardName = (brand: string, channel: string, root: PageView) => {

    const board = cleanPathRoot(root)

    if (!brand) {
        return `Global ${board}`
    }
    if (!channel) {
        return `All ${brand} Products ${board}`
    }
    return `${channel} ${board}`
}