import { PageProps, AppState, Session, DateRange, MomentRange, Breakdown, DonutData } from "@declarations"
import { getPushNotificationStatus } from "Store/Actions/DataActions-Notifications"
import DonutChart from "Components/DonutChart/DonutChart"
import { connect } from "react-redux"
import { store } from "Store/store"
import { useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import DatePicker from "Components/DatePicker/DatePicker"
import EmptyState from "Components/EmptyState/EmptyState"
import Skeleton from "react-loading-skeleton"
import FunnelChart from "Components/FunnelChart/FunnelChart"
import { buildToLink } from "Utils/routes"
import rightArrowBlue from "Assets/images/arrows/right-arrow-blue.svg"
import { setNotificationsDateRange } from "Store/Actions/DateActions"
import { callCheck } from "Utils/data"

interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {
    showLinkRow: boolean
}

//platform breakdown
function prepData(data: Breakdown[]): DonutData[] {

    const on: DonutData = { label: "On", angle: 0, color: "#0038A8" }

    const off: DonutData = { label: "Off", angle: 0, color: "#B80290" }

    for (let i = 0; i < data.length; i++) {
        if (data[i]._id === "on") on.angle += data[i].count
        if (data[i]._id === "off") off.angle += data[i].count
    }

    const result: DonutData[] = []
    if (on.angle > 0) result.push(on)
    if (off.angle > 0) result.push(off)

    return result
}


const PushNotificationStatus = (props: Props) => {

    const { session,
        experiences,
        globalView,
        demoBrandActive,
        data,
        getData,
        loadingData,
        dates, updateDates,
        showLinkRow } = props

    const location = useLocation()

    useEffect(() => {

        if (!callCheck(globalView, (session as Session.AuthSession).user.admin, experiences?.length)) return

        getData(dates)

    }, [getData, session, demoBrandActive, dates, experiences, globalView])

    return (
        <div className='box divided-box' >
            <div className="divided-box-header">
                <div className="header-row">
                    <h3>Push notification status</h3>
                    <DatePicker
                        startDate={dates.start}
                        endDate={dates.end}
                        startDateId={"startDatePNS"}
                        endDateId={"endDatePNS"}
                        updateDates={updateDates}
                    />
                </div>
            </div>
            <div className={`divided-box-content`}>
                <div className={
                    (data.breakdown && data.breakdown.length)
                        && data.funnel
                        ? "row-content"
                        : ""}>
                    {loadingData
                        ? <Skeleton height={200} width={300} />
                        : data.breakdown && data.breakdown.length
                            ? <DonutChart
                                width={350}
                                height={500}
                                data={prepData(data.breakdown)}
                                bigText="Donut"
                            />
                            : <EmptyState filterableData={true} />
                    }
                    {
                        loadingData
                            ? <Skeleton height={200} width={300} />
                            : data.funnel
                                ? <FunnelChart data={data.funnel} />
                                : null
                    }
                </div>
                {showLinkRow && <div className="link-row">
                    <Link to={buildToLink(location.pathname, "/notifications")}>
                        <span>View Notifications Report</span>
                        <img src={rightArrowBlue} alt="arrow" />
                    </Link>
                </div>
                }
            </div>
        </div >
    )
}

const mapStateToProps = (state: AppState) => {
    return {
        session: state.session,
        experiences: state.experiences.experienceIdList,
        globalView: state.experiences.globalView,
        demoBrandActive: state.experiences.demoBrandActive,
        loadingData: state.loading.notifications.pushNotificationStatus,
        data: state.data.notifications.pushNotificationStatus,
        dates: state.dates.notifications.pushNotificationStatus
    }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
        getData: (dates: DateRange) => dispatch(getPushNotificationStatus(dates)),
        updateDates: (dateRange: MomentRange) => dispatch(setNotificationsDateRange(dateRange, "pushNotificationStatus"))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PushNotificationStatus)