
const returnExample = [
    {
        "instance": "2021-07-22T00:00:00.000Z",
        "count": 53.84615384615385
    },
]

export type bounceRateOverTimeReturnType = typeof returnExample

const buildBounceRateOverTime = (): any[] => [
    {
        "$group": {
            "_id": {
                "date": {
                    "$dateToString": {
                        "format": "%Y-%m-%dT00:00:00.000Z",
                        "date": "$statement.timestamp"
                    }
                },
                "verb": "$statement.verb.id"
            },
            "count": {
                "$sum": 1
            },

        }
    },
    {
        "$group": {
            "_id": "$_id.date",
            "sum": {
                "$sum": "$count"
            },
            "counts": {
                "$push": {
                    "verb": "$_id.verb",
                    "count": "$count"
                }
            }
        }
    },
    {
        "$unwind": {
            "path": "$counts",
        }
    },
    {
        "$project": {
            "_id": "$_id",
            "counts": "$counts.count",
            "verb": "$counts.verb",
            "sum": "$sum",
            "percent": {
                "$multiply": [
                    {
                        "$subtract": [
                            1,
                            {
                                "$cond": [
                                    {
                                        "$eq": [
                                            {
                                                "$subtract": [
                                                    "$sum",
                                                    "$counts.count"
                                                ]
                                            },
                                            0
                                        ]
                                    },
                                    0,
                                    {
                                        "$divide": [
                                            "$counts.count",
                                            {
                                                "$subtract": [
                                                    "$sum",
                                                    "$counts.count"
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    100
                ]
            }
        }
    },
    {
        "$match": {
            "verb": {
                "$in": [
                    "https://w3id.org/xapi/dod-isd/verbs/placed"
                ]
            }
        }
    },
    {
        "$project": {
            "_id": 0,
            "instance": "$_id",
            "count": {
                "$round": [
                    "$percent",
                    2
                ],
            }
        }
    },
    {
        "$sort": {
            "instance": 1
        }
    },
]
export default buildBounceRateOverTime