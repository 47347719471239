import { DateRange, TimelineSlice } from "@declarations"
import { A_DAY } from "Utils/dates"
import { getRandomNumber } from "Utils/numbers"

export const getDemoDataBounceRatesOverTime = (dates: DateRange, count: number): TimelineSlice[] => {
    const br: TimelineSlice[] = []

    let min = 50
    let max = 100

    for (let i = 1; i <= count; i++) {
        min -= 5
        max -= 5
        if (min < 0) min = 0
        if (max < 0) max = 0
        const d = new Date().setTime(dates.start + A_DAY * (i - 1))
        const ep: TimelineSlice = {
            count: getRandomNumber(min, max),
            instance: d < dates.end ? new Date(d).toISOString() : new Date(dates.end).toISOString()
        }
        br.push(ep)

        if (d === dates.end) break
    }

    return br
}