import { VisitorBehaviorTab } from "@declarations"
import "./Tabs.css"

type Props = {
    options: VisitorBehaviorTab[] | string[]
    select: (option: VisitorBehaviorTab | string) => void
    selected: VisitorBehaviorTab | string
}

const Tabs = (props: Props) => {

    const { options, select, selected } = props

    return (
        <div className="tab-row">
            {options.map(o => (
                <button
                    key={o}
                    onClick={() => select(o)}
                    className={selected === o ? "selected-tab" : ""}
                >
                    {o}
                </button>
            ))}
        </div>
    )
}

export default Tabs