import { PageProps, AppState, MomentRange, DateRange, Session } from "@declarations"
import { getPushNotificationStatusTimeline } from "Store/Actions/DataActions-Notifications"
import DatePicker from "Components/DatePicker/DatePicker"
import TimelineChart from "Components/TimelineChart/TimelineChart"
import { useEffect } from "react"
import { connect } from "react-redux"
import { store } from "Store/store"
import EmptyState from "Components/EmptyState/EmptyState"
import { setNotificationsDateRange } from "Store/Actions/DateActions"
import useChartWidth from "Utils/useChartWidth"
import { callCheck } from "Utils/data"

interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {
}

const PushNotificationStatusTimelineChart = (props: Props) => {

    const { session, globalView, experiences, demoBrandActive, dataList, dates, updateDates, getDataList } = props

    useEffect(() => {

        if (!callCheck(globalView, (session as Session.AuthSession).user.admin, experiences?.length)) return

        getDataList(dates)

    }, [getDataList, session, demoBrandActive, dates, experiences?.length, globalView])

    const width = useChartWidth()

    return (
        <div className='box divided-box' >
            <div className="divided-box-header">
                <div className="header-row">
                    <h3>Push notification status timeline</h3>
                    <DatePicker
                        startDate={dates.start}
                        endDate={dates.end}
                        startDateId={"startDatePNSTC"}
                        endDateId={"endDatePNSTC"}
                        updateDates={updateDates}
                    />
                </div>
            </div>
            <div className="divided-box-content">
                {
                    dataList && dataList.length
                        ? <TimelineChart
                            width={width}
                            height={500}
                            startDate={dates.start}
                            endDate={dates.end}
                            data={dataList.filter(d => d.id === "on")}
                            data2={dataList.filter(d => d.id === "off")}
                            legendTitle="Notification turned on"
                            legendTitle2="Notification turned off"
                        />
                        : <EmptyState filterableData={true} />
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state: AppState) => {
    return {
        session: state.session,
        experiences: state.experiences.experienceIdList,
        globalView: state.experiences.globalView,
        demoBrandActive: state.experiences.demoBrandActive,
        dataList: state.data.notifications.pushNotificationStatusTimeline,
        dates: state.dates.notifications.pushNotificationStatusTimeline,
        loadingData: state.loading.notifications.pushNotificationStatusTimeline
    }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
        getDataList: (dates: DateRange) => dispatch(getPushNotificationStatusTimeline(dates)),
        updateDates: (dateRange: MomentRange) => dispatch(setNotificationsDateRange(dateRange, "pushNotificationStatusTimeline"))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PushNotificationStatusTimelineChart)