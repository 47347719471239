import { PageProps, AppState, Session, MomentRange, DateRange } from "@declarations"
import DatePicker from "Components/DatePicker/DatePicker"
import { useEffect } from "react"
import Skeleton from "react-loading-skeleton"
import { connect } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { store } from "Store/store"
import { buildToLink } from "Utils/routes"
import rightArrowBlue from "Assets/images/arrows/right-arrow-blue.svg"
import useElementWidth from "Utils/useElementWidth"
import EmptyState from "Components/EmptyState/EmptyState"
import { getVisitorsByTimeOfDay } from "Store/Actions/DataActions-VisitorBehavior"
import HeatMap from "Components/HeatMap/HeatMap"
import HeatMapLegend from "Components/HeatMapLegend/HeatMapLegend"
import { setVisitorBehaviorDateRange } from "Store/Actions/DateActions"
import { callCheck } from "Utils/data"

interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {
}

const VisitorsTimeOfDay = (props: Props) => {

    const { session, globalView, experiences, dataList, getDataList, loadingData, demoBrandActive, dates, updateDates } = props

    const location = useLocation()

    useEffect(() => {

        if (!callCheck(globalView, (session as Session.AuthSession).user.admin, experiences?.length)) return

        getDataList(dates)

    }, [experiences, getDataList, session, demoBrandActive, dates, globalView])

    const { ref, width } = useElementWidth(64)

    return (
        <>
            {
                loadingData
                    ? <Skeleton height={500} />
                    :
                    <div className='box divided-box' ref={ref}>
                        <div className="divided-box-header">
                            <div className="header-row">
                                <h3>Visitors by time of day</h3>
                                <DatePicker
                                    startDate={dates.start}
                                    endDate={dates.end}
                                    startDateId={"startDateVTOD"}
                                    endDateId={"endDateVTOD"}
                                    updateDates={updateDates}
                                />
                            </div>
                        </div>
                        <div className="divided-box-content undo-top-margin-default">
                            {dataList && dataList.length
                                ? <>
                                    <HeatMap width={width} height={425} data={dataList} />
                                    <HeatMapLegend data={dataList} />
                                </>
                                : <EmptyState filterableData={true} />
                            }
                            <div className="link-row">
                                <Link to={buildToLink(location.pathname, "/visitor-behavior")}>
                                    <span>View Visitor Behavior Report</span>
                                    <img src={rightArrowBlue} alt="arrow" />
                                </Link>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

const mapStateToProps = (state: AppState) => {
    return {
        session: state.session,
        experiences: state.experiences.experienceIdList,
        globalView: state.experiences.globalView,
        demoBrandActive: state.experiences.demoBrandActive,
        dataList: state.data.visitorBehavior.visitorsByTimeOfDay,
        dates: state.dates.visitorBehavior.visitorsByTimeOfDay,
        loadingData: state.loading.visitorBehavior.visitorsByTimeOfDay
    }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
        getDataList: (dates: DateRange) => dispatch(getVisitorsByTimeOfDay(dates)),
        updateDates: (dateRange: MomentRange) => dispatch(setVisitorBehaviorDateRange(dateRange, "visitorsByTimeOfDay"))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(VisitorsTimeOfDay)