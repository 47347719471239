import { DateRange, NotificationFunnel } from "@declarations"

export const getDemoDataPushNotificationFunnel = (dates: DateRange, count: number): NotificationFunnel => {
    const br: NotificationFunnel = {
        notificationsDelivered: 392,
        notificationsOpened: 214,
        CTAsClicked: 58,
        CTR: 0.027
    }
    return br
}