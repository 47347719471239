import { AppState, DateRange, ReduxAction } from "@declarations";
import { Actions } from "Store/Actions/ActionsTypes";
import { getXDaysAgo } from "Utils/dates";

// default all dates to the last 28 days
export const getDefaultDates = (): DateRange => {
    return {
        start: getXDaysAgo(new Date(), 28).valueOf(),
        end: new Date().valueOf()
    }
}

const initialState: AppState.DateRanges = {
    demographics: {
        scansByLocation: getDefaultDates(),
        scansOverTimeByLocation: getDefaultDates(),
        scansOverTimeTotal: getDefaultDates(),
        totalScans: getDefaultDates(),
        qrCodesByLocation: getDefaultDates(),
        top5QRCodes: getDefaultDates()
    },
    visitorBehavior: {
        bounceRatesOverTime: getDefaultDates(),
        ctaClicksOverTime: getDefaultDates(),
        visitorsByTimeOfDay: getDefaultDates()
    },
    notifications: {
        pushNotificationStatus: getDefaultDates(),
        pushNotificationStatusTimeline: getDefaultDates(),
        pushNotificationStatusByLocation: getDefaultDates()
    }
};

export const dates = (state: AppState.DateRanges = initialState, { type, payload }: ReduxAction): AppState.DateRanges => {
    switch (type) {
        case Actions.SET_VISITOR_BEHAVIOR_DATES:
            return {
                ...state,
                visitorBehavior: payload as AppState.VisitorBehaviorDateRanges
            }
        case Actions.SET_DEMOGRAPHICS_DATES:
            return {
                ...state,
                demographics: payload as AppState.DemographicsDateRanges
            }
        case Actions.SET_NOTIFICATIONS_DATES:
            return {
                ...state,
                notifications: payload as AppState.NotificationsDateRanges
            }
        default:
            return state
    }
}