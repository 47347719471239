import { AppState, PageProps, VisitorBehaviorTab } from "@declarations"
import { connect } from "react-redux"
import { store } from "Store/store"
import BounceRatesTable from "../Tables/BounceRatesTable/BounceRatesTable"
import CTATable from "../Tables/CTATable/CTATable"
import TopViewedEpisodesTable from "../Tables/TopViewedEpisodesTable/TopViewedEpisodesTable"
import VisitorsTable from "../Tables/VisitorsTable/VisitorsTable"

type Props = PageProps<typeof mapStateToProps, typeof mapDispatchToProps> & {
    tab: VisitorBehaviorTab
}

const TableArea = (props: Props) => {

    const { tab } = props


    const renderTable = (tab: VisitorBehaviorTab) => {
        switch (tab) {
            case "Top Viewed Episodes":
                return <TopViewedEpisodesTable />
            case "Visitors":
                return <VisitorsTable />
            case "Bounce Rates":
                return <BounceRatesTable />
            case "Calls to Action":
                return <CTATable />
            default:
                return null
        }
    }

    return (
        <div className="">
            {renderTable(tab)}
        </div>
    )
}

const mapStateToProps = (state: AppState) => {
    return {
    }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableArea)