import React from 'react';
import { Group } from '@visx/group';
import { Bin, Bins } from '@visx/mock-data/lib/generators/genBins';
import { scaleLinear } from '@visx/scale';
import { HeatmapRect } from '@visx/heatmap';
import { HourCount } from '@declarations';
import { buildHeatMapBins, heatMapDays, heatMapHours } from 'Utils/heatmap';
import { useMemo } from 'react';
import { AxisBottom, AxisRight } from '@visx/axis';
import "./HeatMap.css"

const hot = '#012A7B';
const cool = '#DDDEE0';
export const background = 'white';


const axisColor = 'transparent';
const tickLabelColor = '#858993';
const tickLabelProps = () =>
({
    fill: tickLabelColor,
    fontSize: 12,
    fontFamily: 'sans-serif',
    textAnchor: 'middle',
} as const);

export type HeatmapProps = {
    width: number;
    height: number;
    margin?: { top: number; right: number; bottom: number; left: number };
    separation?: number;
    events?: boolean;
    data: HourCount[]
};

const defaultMargin = { top: 0, left: 0, right: 50, bottom: 50 };

const HeatMap = ({
    width,
    height,
    events = false,
    margin = defaultMargin,
    separation = 0,
    data
}: HeatmapProps) => {
    // bounds
    const xMax = width - margin.right
    const yMax = height - margin.bottom
    const yAxisMargin = margin.bottom / 2

    const binData = useMemo(() => buildHeatMapBins(), [])

    for (const d of data) {
        const xValue = heatMapDays.findIndex(i => i === d.day)
        const yValue = heatMapHours.findIndex(i => i === d.hour)
        if (xValue !== -1 && yValue !== -1) {
            binData[xValue].bins[yValue].count = d.count
        }
    }

    function max<Datum>(data: Datum[], value: (d: Datum) => number): number {
        return Math.max(...data.map(value));
    }

    // accessors
    const bins = (d: Bins) => d.bins;
    const count = (d: Bin) => d.count;

    const colorMax = max(binData, d => max(bins(d), count));
    const bucketSizeMax = max(binData, d => bins(d).length);

    // scales
    const xScale = scaleLinear<number>({
        domain: [0, binData.length],
    });
    const yScale = scaleLinear<number>({
        domain: [0, bucketSizeMax],
    });
    const rectColorScale = scaleLinear<string>({
        range: [cool, hot],
        domain: [0, colorMax],
    });
    const opacityScale = scaleLinear<number>({
        range: [1, 1],
        domain: [0, colorMax],
    });

    const binWidth = (xMax / binData.length) - 4
    const binHeight = 12

    xScale.range([0, xMax]);
    yScale.range([yMax, 0]);

    const yTickFormatter: any = (_: number, index: number) => {
        if (index % 2 === 0) return ""
        return heatMapHours[index]
    }

    const xTickFormatter: any = (n: number, index: number) => {
        if (n % 1 === 0) return ""
        return heatMapDays[Math.floor(n)]
    }

    if (width < 10) return null

    return (
        <svg width={width} height={height} className="heatmap">
            <rect x={0} y={0} width={width} height={height} rx={14} fill={background} />
            <Group top={0} left={0}>
                <HeatmapRect
                    data={binData}
                    xScale={d => xScale(d) ?? 0}
                    yScale={d => yScale(d) ?? 0}
                    colorScale={rectColorScale}
                    opacityScale={opacityScale}
                    binWidth={binWidth}
                    binHeight={binWidth}
                    gap={5}
                >
                    {(heatmap: any) =>
                        heatmap.map((heatmapBins: any) =>
                            heatmapBins.map((bin: any) => {
                                return (
                                    <rect
                                        key={`heatmap-rect-${bin.row}-${bin.column}`}
                                        className="visx-heatmap-rect"
                                        width={binWidth}
                                        height={binHeight}
                                        x={bin.x ?? 0}
                                        y={bin.y ?? 0}
                                        fill={bin.color}
                                        fillOpacity={bin.opacity}
                                    // onClick={() => {
                                    //     if (!events) return;
                                    //     const { row, column } = bin;
                                    //     alert(JSON.stringify({ row, column, bin: bin.bin }));
                                    // }}
                                    />
                                )
                            }),
                        )
                    }
                </HeatmapRect>
            </Group>
            <AxisRight
                scale={yScale}
                top={0 + yAxisMargin - 10}
                left={xMax + 13}
                numTicks={24}
                tickFormat={yTickFormatter}
                stroke={axisColor}
                tickStroke={axisColor}
                tickLabelProps={tickLabelProps}
            />
            <AxisBottom
                top={height - yAxisMargin - 5}
                scale={xScale}
                numTicks={14}
                tickFormat={xTickFormatter}
                stroke={axisColor}
                tickStroke={axisColor}
                tickLabelProps={tickLabelProps}
            />
        </svg>
    );
};

export default HeatMap