const returnExample = [
    {
        "count": 852
    }
]

export type totalScansToDateReturnType = typeof returnExample

const buildTotalScansToDate = (): any[] => [
    {
        "$group": {
            "_id": null,
            "count": {
                "$sum": 1
            }
        }
    }
]
export default buildTotalScansToDate