import { AppState, PageProps, Session } from "@declarations"
import { connect } from "react-redux"
import nextechLogo from "Assets/images/header/nextech-logo.png"
import userIcon from "Assets/images/header/user.png"
import chartIcon from "Assets/images/sidebar/chart.png"
import gridIcon from "Assets/images/sidebar/grid.png"
import pieChartIcon from "Assets/images/sidebar/pie-chart.png"
import usersIcon from "Assets/images/sidebar/users.png"
import serverIcon from "Assets/images/sidebar/server.png"
import "./DashboardNav.css"
import { NavLink, useHistory, useLocation } from "react-router-dom"
import { store } from "Store/store"
import { Actions, makeAction } from "Store/Actions/ActionsTypes"
import { logout } from "Api/Auth/auth"
import { buildToLink } from "Utils/routes"
import useWindowSize from "Utils/useWindowSize"
import { useEffect, useState } from "react"
import { useRef } from "react"
import useOutsideClick from "Utils/useOutsideClick"
import { Constants } from "Values/constants"

type Props = PageProps<typeof mapStateToProps, typeof mapDispatchToProps> & {
    children: React.ReactChild
}

const DashboardNav = (props: Props) => {

    const { children, logout } = props

    const session = props.session as Session.AuthSession

    const location = useLocation()
    const history = useHistory()
    const logOut = () => {
        logout()
        history.push("/")
    }

    const isHomePath = (path: string): boolean => {
        return path.split("/")[1] === ""
    }

    // handle side menu on mobile
    const [width, _] = useWindowSize()

    const dashboard = useRef<HTMLDivElement | null>(null)

    const [sideMenu, setSideMenu] = useState<boolean>(false)

    const toggleSideMenu = () => {
        setSideMenu(!sideMenu)
    }
    const { ref: aside, isComponentVisible } = useOutsideClick()

    useEffect(() => {
        if (!isComponentVisible) {
            setSideMenu(false)
        }
    }, [isComponentVisible])

    useEffect(() => {
        setSideMenu(false)
    }, [location])

    useEffect(() => {
        if (aside.current) {
            if (sideMenu) {
                aside.current.style.left = "0px"
            } else {
                aside.current.style.left = "-100%"
            }
        }
    }, [aside, sideMenu, width])

    useEffect(() => {
        if (aside.current) {
            if (width < Constants.mobile_breakpoint) {
                const h = dashboard.current?.clientHeight
                aside.current.style.height = `${h}px`
            } else {
                aside.current.style.height = ""
            }
        }
    }, [aside, width])

    return (
        <div className="dashboard" ref={dashboard}>
            <header>
                <div className="logo-hamburger-wrapper">
                    {width < Constants.mobile_breakpoint &&
                        <button onClick={toggleSideMenu} className="hamburger">
                            <div className="hamburger-line"></div>
                            <div className="hamburger-line"></div>
                            <div className="hamburger-line"></div>
                        </button>}
                    <img src={nextechLogo} alt="logo" />
                </div>
                <button onClick={logOut}>
                    <img src={userIcon} alt="log out" />
                    Log Out
                </button>
            </header>
            <aside ref={aside} style={{ width: Constants.side_menu_width }}>
                {width < Constants.mobile_breakpoint &&
                    <button onClick={toggleSideMenu} className="close-aside">
                        <div className="x-line"></div>
                        <div className="x-line"></div>
                    </button>
                }
                {session.user.admin &&
                    <NavLink to={buildToLink(location.pathname, "/")}
                        className={isHomePath(location.pathname) ? 'selected' : ''}
                    >
                        <img src={chartIcon} alt="admin" />
                        Admin
                    </NavLink>
                }
                <NavLink to={buildToLink(location.pathname, "/overview")}
                    className={location.pathname.startsWith("/overview") ? 'selected' : ''}
                >
                    <img src={gridIcon} alt="overview" />
                    Overview
                </NavLink>
                <NavLink to={buildToLink(location.pathname, "/demographics")}
                    className={location.pathname.startsWith("/demographics") ? 'selected' : ''}
                >
                    <img src={pieChartIcon} alt="demographics" />
                    Demographics
                </NavLink>
                <NavLink to={buildToLink(location.pathname, "/visitor-behavior")}
                    className={location.pathname.startsWith("/visitor-behavior") ? 'selected' : ''}
                >
                    <img src={usersIcon} alt="visitor behavior" />
                    Visitor Behavior
                </NavLink>
                <NavLink to={buildToLink(location.pathname, "/notifications")}
                    className={location.pathname.startsWith("/notifications") ? 'selected' : ''}
                >
                    <img src={serverIcon} alt="notifications" />
                    Notifications
                </NavLink>
            </aside>
            <div className="nav-children">
                {children}
            </div>
        </div>
    )
}

const mapStateToProps = (state: AppState) => {
    return { session: state.session }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
        logout: () => {
            logout()
            dispatch(makeAction(Actions.CLEAR_SESSION))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DashboardNav)