import { ReduxAction, AppState } from '@declarations';
import { Actions } from 'Store/Actions/ActionsTypes'

const initialState: AppState.Loading = {
    brandlist: false,
    demographics: {
        trafficTotalsByRegion: false,
        qrCodesByLocation: false,
        top5QRCodes: false,
        scansByLocation: false,
        scansOverTimeByLocation: false,
        scansOverTimeTotal: false,
        totalScans: false,
    },
    // visitor behavior,
    visitorBehavior: {
        topViewedEpisodes: {
            topViewedEpisodes: false,
            summaryData: false,
        },
        visitors: {
            visitors: false,
            summaryData: false,
        },
        bounceRates: {
            bounceRates: false,
            bounceRatesOverTime: false,
            summaryData: false,
        },
        cta: {
            cta: false,
            ctaOvertime: false,
            summaryData: false,
        },
        visitorsByTimeOfDay: false,
    },
    notifications: {
        totalCount: false,
        pushNotificationsCTR: false,
        pushNotificationStatus: false,
        pushNotificationStatusTimeline: false,
    }
};

export const loading = (state: AppState.Loading = initialState, { type, payload }: ReduxAction): AppState.Loading => {
    switch (type) {
        case Actions.SET_LOADING_BRAND_LIST:
            return {
                ...state,
                brandlist: payload as boolean
            }
        case Actions.SET_LOADING_DEMOGRAPHICS_DATA:
            return {
                ...state,
                demographics: payload as AppState.LoadingDemographics
            }
        case Actions.SET_LOADING_VISITOR_BEHAVIOR_DATA:
            return {
                ...state,
                visitorBehavior: payload as AppState.LoadingVisitorBehavior
            }
        case Actions.SET_LOADING_NOTIFICATIONS_DATA:
            return {
                ...state,
                notifications: payload as AppState.LoadingNotifications
            }
        default:
            return state
    }
}