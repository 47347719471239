import { useEffect } from "react"
import { useState } from "react"
import { Constants } from "Values/constants"

const useChartWidth = () => {

    const [width, setWidth] = useState<number>(0)

    useEffect(() => {
        const updateSize = () => {
            let w = window.innerWidth - Constants.chart_width_difference
            if (window.innerWidth < Constants.mobile_breakpoint) {
                w += Constants.side_menu_width
            }
            setWidth(w)
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [])

    return width
}

export default useChartWidth