import { AppState, PageProps } from "@declarations"
import { getBrands } from "Store/Actions/ExperienceActions"
import React, { useEffect, useState } from "react"
import Skeleton from "react-loading-skeleton"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { store } from "Store/store"
import { demoSite } from "Values/DemoData/demoSite"
import "./Admin.css"

interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {
}

const Admin = (props: Props) => {

    const { getBrandList, brandsList } = props

    useEffect(() => {
        getBrandList()
    }, [getBrandList])

    const [brandsWithDemo, setBrandsWithDemo] = useState<AppState.Brand[]>([])

    useEffect(() => {
        let inclusive = [...brandsList, demoSite]
        setBrandsWithDemo(inclusive)
    }, [brandsList])

    const [search, setSearch] = useState<string>("")
    const [sort, setSort] = useState<"AZ" | "ZA">("AZ")

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value
        setSearch(value)
    }

    return (
        <div className="page">
            <div className="header">
                <h2>Brand List ({brandsWithDemo.length})</h2>
                <div className="controls">
                    <button
                        onClick={() => setSort("AZ")}
                        className={sort === "AZ" ? "alternate" : "secondary"}
                    >
                        A-Z
                    </button>
                    <button
                        onClick={() => setSort("ZA")}
                        className={sort === "ZA" ? "alternate" : "secondary"}
                    >
                        Z-A
                    </button>
                    <input
                        placeholder="Search brands..."
                        onChange={handleSearch}
                    />
                </div>
            </div>
            <div className="list">
                {!brandsWithDemo || !brandsWithDemo.length
                    ? <Skeleton height={217} />
                    : brandsWithDemo
                        .filter(b => b.name.toLowerCase().startsWith(search.toLowerCase()))
                        .sort((a, b) => (
                            sort === "AZ"
                                ? (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
                                : (b.name > a.name) ? 1 : ((a.name > b.name) ? -1 : 0)
                        ))
                        .map((b: AppState.Brand, idx: number) => {
                            return <Link key={b.id} to={`/overview/brand/${b.id}`} className="brand-box">
                                <h5>{b.name}</h5>
                                <p>{b.channels.length} {b.channels.length === 1 ? "Channel" : "Channels"}</p>
                            </Link>
                        })
                }
            </div>

        </div>
    )
}

const mapStateToProps = (state: AppState) => {
    return { brandsList: state.experiences.brandsList }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
        getBrandList: () => dispatch(getBrands())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Admin)