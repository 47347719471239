import { TableData } from "@declarations"
import { getRandomNumber } from "Utils/numbers"

const titles = [
    "Spring 2021 Promo BOGO",
    "Summer 2021 Promo Intro",
    "Fall 2021 Promo Tutorial",
    "Winter 2021 Promo Tutorial",
]

export const getDemoDataPushNotificationsCTR = (count: number): TableData.PushNotificationCTR[] => {
    const episodes: TableData.PushNotificationCTR[] = []

    for (let i = 1; i <= count; i++) {
        const ep: TableData.PushNotificationCTR = {
            notificationTitle: titles[getRandomNumber(0, titles.length - 1)],
            notificationsDelivered: getRandomNumber(1, 25),
            notificationsOpened: getRandomNumber(1, 25),
            CTAsClicked: getRandomNumber(1, 25),
            CTR: getRandomNumber(1, 25),
        }
        episodes.push(ep)
    }

    return episodes
}