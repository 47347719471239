import { PageProps, AppState, Session, DateRange, MomentRange, Breakdown, DonutData } from "@declarations"
import DonutChart from "Components/DonutChart/DonutChart"
import { connect } from "react-redux"
import { store } from "Store/store"
import { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import DatePicker from "Components/DatePicker/DatePicker"
import EmptyState from "Components/EmptyState/EmptyState"
import Skeleton from "react-loading-skeleton"
import { buildToLink } from "Utils/routes"
import rightArrowBlue from "Assets/images/arrows/right-arrow-blue.svg"
import "./TotalNumberOfScans.css"
import { formatDateRange } from "Utils/dates"
import { getTotalScans } from "Store/Actions/DataActions-Demographics"
import { getTotalAngles } from "Utils/donutData"
import { setDemographicsDateRange } from "Store/Actions/DateActions"
import { callCheck } from "Utils/data"

interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {
}

//platform breakdown
function prepData(data: { total: number, breakdown: Breakdown[] }): DonutData[] {

    const ios: DonutData = { label: "iOS", angle: 0, color: "#0038A8" }

    const android: DonutData = { label: "Android", angle: 0, color: "#B80290" }

    for (let i = 0; i < data.breakdown.length; i++) {
        if (data.breakdown[i]._id.toLowerCase() === ios.label.toLowerCase()) ios.angle += data.breakdown[i].count
        if (data.breakdown[i]._id.toLowerCase() === android.label.toLowerCase()) android.angle += data.breakdown[i].count
    }

    const result: DonutData[] = []
    if (ios.angle > 0) result.push(ios)
    if (android.angle > 0) result.push(android)

    return result
}

const TotalNumberOfScans = (props: Props) => {

    const { session, demoBrandActive, experiences,
        data, getData, loadingData,
        dates, updateDates,
        globalView
    } = props

    const location = useLocation()

    useEffect(() => {

        if (!callCheck(globalView, (session as Session.AuthSession).user.admin, experiences?.length)) return

        getData(dates)

    }, [session, demoBrandActive, dates, getData, experiences, globalView])

    const [donutData, setDonutData] = useState<DonutData[]>([])

    useEffect(() => {
        const d = prepData(data)
        setDonutData(d)
    }, [data])

    return (
        <div className='box divided-box' >
            <div className="divided-box-header">
                <div className="header-row">
                    <h3>Total Number of Scans</h3>
                    <DatePicker
                        startDate={dates.start}
                        endDate={dates.end}
                        startDateId={"startDateTNOS"}
                        endDateId={"endDateTNOS"}
                        updateDates={updateDates}
                    />
                </div>
            </div>
            <div className={`divided-box-content`}>
                <div>
                    {loadingData
                        ? <Skeleton height={200} width={300} />
                        : data && data.breakdown.length
                            ? <div className="section-wrapper">
                                <div className="total-banner">
                                    <h2>{getTotalAngles(donutData)}</h2>
                                    <p>Total scans from:</p>
                                    <p><strong>{formatDateRange(new Date(dates.start), new Date(dates.end))}</strong></p>
                                </div>
                                <div className="breakdown-wrapper">
                                    <h4>Total scans by devices</h4>
                                    <DonutChart
                                        width={350}
                                        height={500}
                                        data={donutData}
                                        bigText="Donut"
                                    />
                                </div>
                            </div>
                            : <EmptyState filterableData={true} />
                    }
                </div>
                <div className="link-row">
                    <Link to={buildToLink(location.pathname, "/demographics")}>
                        <span>View Demographics Report</span>
                        <img src={rightArrowBlue} alt="arrow" />
                    </Link>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: AppState) => {
    return {
        session: state.session,
        experiences: state.experiences.experienceIdList,
        globalView: state.experiences.globalView,
        demoBrandActive: state.experiences.demoBrandActive,
        loadingData: state.loading.demographics.totalScans,
        data: state.data.demographics.totalScans,
        dates: state.dates.demographics.totalScans
    }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
        getData: (dates: DateRange) => dispatch(getTotalScans(dates)),
        updateDates: (dateRange: MomentRange) => dispatch(setDemographicsDateRange(dateRange, "totalScans"))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TotalNumberOfScans)