import { HourCount } from "@declarations"
import { useEffect } from "react"
import { useState } from "react"
import "./HeatMapLegend.css"

type Props = {
    data: HourCount[]
}

const HeatMapLegend = (props: Props) => {

    const { data } = props

    useEffect(() => {
        let max = -Infinity
        let min = Infinity

        for (const d of data) {
            if (d.count > max) max = d.count
        }

        for (const d of data) {
            if (d.count < min) min = d.count
        }

        const mid = Math.round((max + min) / 2);
        const oneQuarter = Math.round((mid + min) / 2)
        const threeQuarters = Math.round((mid + max) / 2)

        setOne(min)
        setTwo(oneQuarter)
        setThree(mid)
        setFour(threeQuarters)
        setFive(max)

    }, [data])


    const [one, setOne] = useState<number>(0)
    const [two, setTwo] = useState<number>(0)
    const [three, setThree] = useState<number>(0)
    const [four, setFour] = useState<number>(0)
    const [five, setFive] = useState<number>(0)

    return (
        <div className="heat-map-legend">
            <div className="heat-map-row">
                <div className="heat-map-legend-box"></div>
                <div className="heat-map-legend-box"></div>
                <div className="heat-map-legend-box"></div>
                <div className="heat-map-legend-box"></div>
            </div>
            <div className="heat-map-row">
                <p>{one}</p>
                <p>{two}</p>
                <p>{three}</p>
                <p>{four}</p>
                <p>{five}</p>
            </div>
        </div>
    )
}

export default HeatMapLegend