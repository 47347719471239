

import { Comparison } from "@declarations"

// check whether the user has permission to query the data
// and whether the experience ids are  ready

export const callCheck = (globalView: boolean, admin: boolean, experienceLength?: number): boolean => {

    // only admins can call data at the global view level
    if (globalView && !admin) return false

    // if it's global view, we should have  a list of experiences for the brand
    // don't proceed unless we do
    if (!globalView && !experienceLength) return false

    // otherwise proceed
    return true

}

// get region with highest occurrence in an array of states and countries.
// not currently used—sorting done in mongodb query
export const getMostCommonRegion = (arr: { state: string, country: string }[]) => {

    let result = arr[0].state
    const map: Record<string, { state: string, country: string, count: number }> = {}

    for (const a of arr) {
        const current = a.state
        if (!map[current]) {
            map[current] = {
                state: a.state,
                country: a.country,
                count: 1
            }
        } else {
            map[current].count++
        }

        if (map[result] < map[current]) result = current;
    }

    return {
        state: map[result].state,
        country: map[result].country
    }
}

export const getPercentageComparison = (previous: number, current: number, isRate: boolean = false): Comparison => {
    const result: Comparison = {
        comparison: current >= previous ? "Up" : "Down",
        value: isRate
            ? Math.abs(current - previous)
            : previous === 0 ? current : (Math.abs(current - previous) / previous) * 100
    }

    return result
}