import { PageProps, AppState, Session } from "@declarations"
import SummaryBox from "Pages/DashboardNav/VisitorBehavior/SummaryBox/SummaryBox"
import tvIcon from "Assets/images/data/visitor-behavior/tv-icon.svg"
import eyeIcon from "Assets/images/data/visitor-behavior/eye-icon.svg"
import percentageIconPurple from "Assets/images/data/visitor-behavior/percentage-icon-purple.svg"
import clickIcon from "Assets/images/data/visitor-behavior/click-icon.svg"
import rightArrowBlue from "Assets/images/arrows/right-arrow-blue.svg"
import { connect } from "react-redux"
import "../VisitorBehavior.css"
import { Link, useLocation } from "react-router-dom"
import { buildToLink } from "Utils/routes"
import { store } from "Store/store"
import Skeleton from "react-loading-skeleton"
import { useEffect } from "react"
import { getVBSnapshotData } from "Store/Actions/DataActions-VisitorBehavior"
import { cleanDuration } from "Utils/dates"
import { callCheck } from "Utils/data"

type Props = PageProps<typeof mapStateToProps, typeof mapDispatchToProps> & {
}

const SummarySnapshot = (props: Props) => {

    const { data,
        session,
        globalView,
        experiences,
        loadingDataTopViewedEpisodes,
        loadingDataBounceRates,
        loadingDataCTA,
        loadingDataVisitors,
        getData, demoBrandActive } = props

    const location = useLocation()

    useEffect(() => {

        if (!callCheck(globalView, (session as Session.AuthSession).user.admin, experiences?.length)) return

        getData()

    }, [getData, demoBrandActive, experiences, session, globalView])

    return (
        <div className="box divided-box">
            <div className="divided-box-header">
                <div className="header-row">
                    <h3>Visitor Behavior</h3>
                </div>
            </div>
            <div className="divided-box-content">
                <div className="summary-2x2">
                    {loadingDataTopViewedEpisodes ||
                        loadingDataBounceRates ||
                        loadingDataCTA ||
                        loadingDataVisitors
                        ? <><Skeleton height={91} />
                            <Skeleton height={91} />
                            <Skeleton height={91} />
                            <Skeleton height={91} />
                        </>
                        : <> <SummaryBox
                            variant="green"
                            icon={tvIcon}
                            value={data.topViewedEpisodes.summaryData.topViewedEpisodeToDate
                                ? data.topViewedEpisodes.summaryData.topViewedEpisodeToDate
                                : "--"}
                            text={"Top viewed episode to date"}
                        />
                            <SummaryBox
                                variant="blue"
                                icon={eyeIcon}
                                value={data.visitors.summaryData.totalAccumulatedViewTime
                                    ? cleanDuration(data.visitors.summaryData.totalAccumulatedViewTime)
                                    : "--"}
                                text={"Total accumulated view time"}
                            />
                            <SummaryBox
                                variant="purple"
                                icon={percentageIconPurple}
                                value={data.bounceRates.summaryData.overallBounceRateToDate
                                    ? `${data.bounceRates.summaryData.overallBounceRateToDate.toFixed(2)}%`
                                    : "--"}
                                text={"Overall bounce rate to date"}
                            />
                            <SummaryBox
                                variant="pink"
                                icon={clickIcon}
                                value={data.cta.summaryData.totalClicksToDate
                                    ? data.cta.summaryData.totalClicksToDate.toLocaleString()
                                    : "--"}
                                text={"Total clicks to date"}
                            />
                        </>
                    }
                </div>
                <div className="link-row">
                    <Link to={buildToLink(location.pathname, "/visitor-behavior")}>
                        <span>View Visitor Behavior Report</span>
                        <img src={rightArrowBlue} alt="arrow" />
                    </Link>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state: AppState) => {
    return {
        data: state.data.visitorBehavior,
        session: state.session,
        experiences: state.experiences.experienceIdList,
        globalView: state.experiences.globalView,
        demoBrandActive: state.experiences.demoBrandActive,
        loadingDataTopViewedEpisodes: state.loading.visitorBehavior.topViewedEpisodes.summaryData,
        loadingDataVisitors: state.loading.visitorBehavior.visitors.summaryData,
        loadingDataBounceRates: state.loading.visitorBehavior.bounceRates.summaryData,
        loadingDataCTA: state.loading.visitorBehavior.cta.summaryData
    }
}

const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
        getData: () => dispatch(getVBSnapshotData())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SummarySnapshot)