import { useLayoutEffect, useRef, useState } from "react"


const useElementWidth = (padding: number) => {
    const [width, setWidth] = useState<number>(0)
    const ref = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        const updateWidth = () => {
            if (ref.current?.clientWidth) {
                const insideWidth = ref.current?.clientWidth - (padding * 2)
                setWidth(insideWidth);
            }
        }
        window.addEventListener('resize', updateWidth);
        updateWidth();
        return () => window.removeEventListener('resize', updateWidth);
    }, [padding]);

    return { ref, width }
}

export default useElementWidth