import { AppState, PageProps } from "@declarations"
import DownloadReportButton from "Components/DownloadReportButton/DownloadReportButton"
import { connect } from "react-redux"
import { store } from "Store/store"
import { getDashboardName } from "Utils/routes"
import TotalNumberOfScans from "../Demographics/Charts/TotalNumberOfScans/TotalNumberOfScans"
import TotalScansOverTime from "../Demographics/Charts/TotalScansOverTime/TotalScansOverTime"
import ScansByLocationMap from "../Demographics/Maps/ScansByLocationMap/ScansByLocationMap"
import Top5QRCodesTable from "../Demographics/Tables/Top5QRCodesTable.tsx/Top5QRCodesTable"
import PushNotificationStatus from "../Notifications/Charts/PushNotificationStatus/PushNotificationStatus"
import VisitorsTimeOfDay from "../VisitorBehavior/Charts/VisitorsTimeOfDay/VisitorsTimeOfDay"
import SummarySnapshot from "../VisitorBehavior/SummarySnapshot/SummarySnapshot"
import "./Overview.css"

interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {
}

const Overview = (props: Props) => {

    const { brandInView, channelInView } = props

    return (
        <div className="page">
            <div className="header">
                <h2>{getDashboardName(brandInView?.name, channelInView?.name, "overview")}</h2>
                <DownloadReportButton page="overview" />
            </div>
            <SummarySnapshot />
            <br />
            <Top5QRCodesTable />
            <br />
            <ScansByLocationMap showLink={true} showTable={false} />
            <br />
            <div className="two-split-grid">
                <TotalNumberOfScans />
                <VisitorsTimeOfDay />
            </div>
            <br />
            <TotalScansOverTime />
            <br />
            <PushNotificationStatus showLinkRow={true} />
        </div>
    )
}

const mapStateToProps = (state: AppState) => {
    return {
        brandInView: state.experiences.brandInView,
        channelInView: state.experiences.channelInView
    }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Overview)