import { xApiStatementKeys } from "Values/xApiKeys"

const returnExample = [
    {
        "count": 26,
        "instance": "2021-07-22T00:00:00.000Z",
        "region": "Ontario",
        "country": "Canada"
    },
]

export type scansOverTimeByLocationReturnType = typeof returnExample

const buildScansOverTimeByLocation = (): any[] => [
    {
        "$group": {
            "_id": {
                "$dateToString": {
                    "format": "%Y-%m-%dT00:00:00.000Z",
                    "date": `$${xApiStatementKeys.timestamp}`
                }
            },
            "entry": {
                "$first": "$$ROOT"
            },
            "count": {
                "$sum": 1
            }
        }
    },
    {
        "$replaceRoot": {
            "newRoot": {
                "$mergeObjects": [
                    {
                        "count": "$count"
                    },
                    "$entry"
                ]
            }
        }
    },
    {
        "$addFields": {
            "isoTime": {
                "$toDate": "$_id"
            }
        }
    },
    {
        "$sort": {
            "isoTime": 1
        }
    },
    {
        "$project": {
            "_id": 0,
            "instance": "$isoTime",
            "count": "$count",
            "region": "$statement.object.definition.extensions.https://aritize*`*nextechar*`*com/state",
            "country": "$statement.object.definition.extensions.https://aritize*`*nextechar*`*com/country"
        }
    }



]
export default buildScansOverTimeByLocation