import { AppState, MomentRange, PageProps, TableData, Session, DateRange } from "@declarations"
import { getQRCodesByLocation } from "Store/Actions/DataActions-Demographics"
import DatePicker from "Components/DatePicker/DatePicker"
import Table from "Components/Table/Table"
import { useEffect, useState } from "react"
import Skeleton from "react-loading-skeleton"
import { connect } from "react-redux"
import { store } from "Store/store"
import EmptyState from "Components/EmptyState/EmptyState"
import LocationSelector from "Components/LocationSelector/LocationSelector"
import { filterDataByLocation } from "Utils/locations"
import DownloadCSVButton from "Components/DownloadCSVButton/DownloadCSVButton"
import { setDemographicsDateRange } from "Store/Actions/DateActions"
import { callCheck } from "Utils/data"

interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {
}

const QRCodesByLocationTable = (props: Props) => {

    const { session, globalView, experiences, dataList, getDataList, loadingData, demoBrandActive, dates, updateDates } = props

    const [locationFilter, setLocationFilter] = useState({
        country: "",
        region: ""
    })

    useEffect(() => {

        if (!callCheck(globalView, (session as Session.AuthSession).user.admin, experiences?.length)) return

        getDataList(dates)

    }, [dates, getDataList, session, demoBrandActive, experiences, globalView])

    const handleLocationFilter = (country: string, region: string) => {
        setLocationFilter({ country, region })
    }

    const [filteredData, setFilteredData] = useState<TableData.QRCodesByLocation[]>([])

    useEffect(() => {
        const f = filterDataByLocation(dataList, locationFilter)
        setFilteredData(f)
    }, [dataList, locationFilter])

    const title = "Highest performing QR codes by location"

    return (
        <div className='box divided-box'>
            <div className="divided-box-header two-rows">
                <div className="header-row">
                    <h3>{title}</h3>
                    <div style={{ marginLeft: "auto", marginRight: "1rem" }}>
                        <LocationSelector data={dataList} sendLocation={handleLocationFilter} />
                    </div>
                    <DatePicker
                        startDate={dates.start}
                        endDate={dates.end}
                        startDateId={"startDateQRCBLT"}
                        endDateId={"endDateQRCBLT"}
                        updateDates={updateDates}
                    />
                </div>
                <div className="header-row">
                    {dataList && dataList.length
                        ? <div style={{ marginLeft: "auto" }}>
                            <DownloadCSVButton
                                title={title}
                                headers={Object.keys(dataList[0])}
                                data={dataList}
                            />
                        </div>
                        : null
                    }
                </div>
            </div>
            <div className={`divided-box-content ${filteredData && filteredData.length ? "has-table" : ""}`}>
                {
                    loadingData
                        ? <Skeleton height={250} style={{ marginTop: '2.5rem' }} />
                        : filteredData && filteredData.length
                            ? <Table
                                title=""
                                showTitle={true}
                                headers={Object.keys(filteredData[0])}
                                sortable={["numberOfScans"]}
                                data={filteredData}
                                limit={10}
                                pagination={true}
                            />
                            : <EmptyState filterableData={true} />
                }
            </div>
        </div>


    )
}

const mapStateToProps = (state: AppState) => {
    return {
        session: state.session,
        experiences: state.experiences.experienceIdList,
        globalView: state.experiences.globalView,
        demoBrandActive: state.experiences.demoBrandActive,
        dataList: state.data.demographics.qrCodesByLocation,
        dates: state.dates.demographics.qrCodesByLocation,
        loadingData: state.loading.demographics.qrCodesByLocation
    }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
        getDataList: (dates: DateRange) => dispatch(getQRCodesByLocation(dates)),
        updateDates: (dateRange: MomentRange) => dispatch(setDemographicsDateRange(dateRange, "qrCodesByLocation"))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(QRCodesByLocationTable)