import { TableData } from "@declarations"
import { getRandomNumber } from "Utils/numbers"

const titles = [
    "Spring 2021 Promo BOGO",
    "Summer 2021 Promo Intro",
    "Fall 2021 Promo Tutorial",
    "Winter 2021 Promo Tutorial",
]

const countries = [
    "Canada",
    "USA"
]

export const getDemoDataTop5QRCodes = (count: number): TableData.Top5QRCodes[] => {
    const episodes: TableData.Top5QRCodes[] = []

    for (let i = 1; i <= count; i++) {
        const ep: TableData.Top5QRCodes = {
            rank: 0,
            QRCodeTitle: titles[getRandomNumber(0, titles.length - 1)],
            numberOfOpens: getRandomNumber(1, 25),
            country: countries[getRandomNumber(0, countries.length - 1)],
        }
        episodes.push(ep)
    }

    episodes.sort((a, b) => b.numberOfOpens - a.numberOfOpens)

    episodes.forEach((e, idx) => {
        e.rank = idx + 1
    })

    return episodes
}