
export const heatMapDays = [
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
    "Sun"
]

// export const heatMapHours = ["12AM", "1AM", "2AM", "3AM", "4AM", "5AM", "6AM", "7AM", "8AM", "9AM", "10AM", "11AM",
//     "12PM", "1PM", "2PM", "3PM", "4PM", "5PM", "6PM", "7PM", "8PM", "9PM", "10PM", "11PM"]

// reverse order of the y-axis of the heatmap, as bins are built from bottom up
export const heatMapHours = [
    "11PM",
    "10PM",
    "9PM",
    "8PM",
    "7PM",
    "6PM",
    "5PM",
    "4PM",
    "3PM",
    "2PM",
    "1PM",
    "12PM",
    "11AM",
    "10AM",
    "9AM",
    "8AM",
    "7AM",
    "6AM",
    "5AM",
    "4AM",
    "3AM",
    "2AM",
    "1AM",
    "12AM"
]

// expected structure for visx heatmap
type yBin = {
    count: number,
    bin: number,
}

type xBin = {
    bin: number,
    bins: yBin[]
}

export const buildHeatMapBins = () => {

    const bins: xBin[] = []

    // bin for each weekday
    for (let d = 0; d < heatMapDays.length; d++) {

        const x: xBin = {} as xBin
        const ys: yBin[] = []

        // bin for 24 hours within each weekday
        for (let h = 0; h < heatMapHours.length; h++) {

            const y: yBin = {} as yBin

            y.count = 0
            y.bin = h + 1

            ys.push(y)

        }

        x.bin = d + 1
        x.bins = ys
        bins.push(x)

    }

    return bins


}