import { xApiQueries } from "@declarations";
import buildQrCodesByLocation from "./demographics/qrCodesByLocation";
import buildScansByLocation from "./demographics/scansByLocation";
import buildScansOverTimeByLocation from "./demographics/scansOverTimeByLocation";
import buildScansOverTimeTotal from "./demographics/scansOverTimeTotal";
import buildTop5QRCodes from "./demographics/top5QRCodes";
import buildTotalScans from "./demographics/totalScans";
import buildTrafficTotalsByRegion from "./demographics/trafficTotalsByRegion";
import buildBounceRateDetails from "./visitor-behavior/bounceRates/buildBounceRateDetails";
import buildBounceRateOverTime from "./visitor-behavior/bounceRates/buildBounceRateOverTime";
import buildOverallBounceRate from "./visitor-behavior/bounceRates/buildOverallBounceRate";
import buildClickRateDetails from "./visitor-behavior/cta/buildClickRateDetails";
import buildCTAOverTime from "./visitor-behavior/cta/buildCTAOverTime";
import buildOverallClickRate from "./visitor-behavior/cta/buildOverallClickRate";
import buildTotalClicksToDate from "./visitor-behavior/cta/buildTotalClicksToDate";
import buildTopViewedEpisodeToDate from "./visitor-behavior/topViewedEpisodes/buildTopViewedEpisodeToDate";
import buildTotalEpisodes from "./visitor-behavior/topViewedEpisodes/buildTotalEpisodes";
import buildMostTrafficByTimeOfDay from "./visitor-behavior/visitors/buildMostTrafficDuringTimeOfDay";
import buildTotalScansToDate from "./visitor-behavior/visitors/buildTotalScansToDate";
import buildTotalVisitors from "./visitor-behavior/visitors/buildTotalVisitors";
import buildViewTime from "./visitor-behavior/visitors/buildViewTime";
import buildVisitorsByTimeOfDay from "./visitor-behavior/visitors/buildVisitorsByTimeOfDay";

export const xApiQueryLibrary: xApiQueries = {
    demographics: {
        trafficTotalsByRegion: buildTrafficTotalsByRegion,
        qrCodesByLocation: buildQrCodesByLocation,
        top5QRCodes: buildTop5QRCodes,
        scansByLocation: buildScansByLocation,
        scansOverTimeByLocation: buildScansOverTimeByLocation,
        scansOverTimeTotal: buildScansOverTimeTotal,
        totalScans: buildTotalScans,
    },
    visitorBehavior: {
        topViewedEpisodes: {
            topViewedEpisodes: buildTotalEpisodes,
            summaryData: {
                topViewedEpisodeToDate: buildTopViewedEpisodeToDate,
                mostViewsOfAnEpisode: buildTopViewedEpisodeToDate,
                avgViewTime: buildViewTime,
            }
        },
        visitors: {
            visitors: buildTotalVisitors,
            summaryData: {
                totalScansToDate: buildTotalScansToDate,
                totalAccumulatedViewTime: buildViewTime,
                mostTrafficDuringTimeOfDay: buildMostTrafficByTimeOfDay
            }
        },
        bounceRates: {
            bounceRates: buildBounceRateDetails,
            bounceRatesOverTime: buildBounceRateOverTime,
            summaryData: {
                overallBounceRateToDate: buildOverallBounceRate,
                bouncRateComparedToLastMonth: buildOverallBounceRate,
                avgSessionDuration: buildViewTime,
            }
        },
        cta: {
            cta: buildClickRateDetails,
            ctaOvertime: buildCTAOverTime,
            summaryData: {
                totalClicksToDate: buildTotalClicksToDate,
                ctaComparedToLastMonth: buildTotalClicksToDate,
                avgClickRate: buildOverallClickRate
            }
        },
        visitorsByTimeOfDay: buildVisitorsByTimeOfDay,
    },
    notifications: {
        totalCount: () => [],
        pushNotificationsCTR: () => [],
        pushNotificationStatus: () => [],
        pushNotificationStatusTimeline: () => [],
    }
}