import { Constants } from "Values/constants";

export const queryXapi = async (query: Array<Record<string, any>>): Promise<any> => {

    const JWT = sessionStorage.getItem('token')

    if (!JWT) {
        console.error("No JWT to query xAPI")
        return
    }

    const headers = new Headers();
    headers.set('Authorization', `Bearer ${JWT}`);
    headers.set('Content-Type', 'application/json');

    const request: RequestInit = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(query)
    }

    const response = await fetch(`${Constants.xapi_url}`, request)

    if (response.status !== 200) {
        throw Error(await response.json());
    }

    return response.json();
}
