import { ExtensionKey, VerbKey } from "@declarations"

const Verbs: Record<VerbKey, string> = {
    "CodeEntered": "http://activitystrea.ms/schema/1.0/access",
    "Loggedin": "https://brindlewaye.com/xAPITerms/verbs/loggedin/",
    "Searched": "http://activitystrea.ms/schema/1.0/search",
    "ChannelOpened": "http://activitystrea.ms/schema/1.0/open",
    "ExperienceClosed": "http://activitystrea.ms/schema/1.0/close",
    "Experienced": "http://activitystrea.ms/schema/1.0/experience",
    "Placed": "https://w3id.org/xapi/dod-isd/verbs/placed",
    "PortalEntered": "https://w3id.org/xapi/dod-isd/verbs/entered",
    "Weblinked": "http://activitystrea.ms/schema/1.0/use",
}

const EXT_DOMAIN = "$EXT.https://aritize*`*nextechar*`*com"

const Extensions: Record<ExtensionKey, { path: string, restrictedTo: string[] | null }> = {
    code: { path: EXT_DOMAIN + "/code", restrictedTo: ["CodeEntered"] }, // CodeEntered
    // searchterm: { path: EXT_DOMAIN + "/searchterm", restrictedTo: ["Searched"] }, // Searched
    method: { path: EXT_DOMAIN + "/method", restrictedTo: ["ExperienceClosed"] }, // ExperienceClosed
    linktype: { path: EXT_DOMAIN + "/linktype", restrictedTo: ["Weblinked"] }, // Weblinked
    devicetype: { path: EXT_DOMAIN + "/devicetype", restrictedTo: null }, //every event has this
    appversion: { path: EXT_DOMAIN + "/appversion", restrictedTo: null }, //every event has this
    platform: { path: EXT_DOMAIN + "/platform", restrictedTo: null }, // every event has this
    state: { path: EXT_DOMAIN + "/state", restrictedTo: null },
    country: { path: EXT_DOMAIN + "/country", restrictedTo: null },
    secondsfromstart: { path: EXT_DOMAIN + "/secondsfromstart", restrictedTo: null }
}

export const xApiKeys = {
    Verbs,
    Extensions
}

export const xApiStatementKeys = {
    verbId: "statement.verb.id",
    objectId: "statement.object.id",
    timestamp: "statement.timestamp"
}

export const experienceIdRoot = "https://aritize.nextechar.com/experience/"
export const channelIdRoot = "https://aritize.nextechar.com/channel/"