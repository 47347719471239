import { DateRangePicker, FocusedInputShape } from "react-dates";
import moment, { Moment } from "moment"
import { MomentRange } from "@declarations";
import { useState } from "react";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import "./DatePicker.css"
import { useEffect } from "react";
import { formatDate } from "Utils/dates";
import leftArrowBlue from 'Assets/images/arrows/left-arrow-blue-small.svg'
import rightArrowBlue from 'Assets/images/arrows/right-arrow-blue-small.svg'
import dropdownArrow from 'Assets/images/arrows/dropdown-arrow.svg'

type Props = {
    startDate: number
    endDate: number
    startDateId: string
    endDateId: string
    updateDates: (dateRange: MomentRange) => void
}

const DatePicker = (props: Props) => {

    const { startDate, endDate, startDateId, endDateId, updateDates } = props

    const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null)
    const [closed, setClosed] = useState<boolean>(true)

    const openDatePicker = () => {
        handleFocusChange("startDate")
    }

    const handleFocusChange = (focusedInput: FocusedInputShape | null) => {
        if (focusedInput === null) {
            setClosed(true)
        } else {
            setClosed(false)
        }
        setFocusedInput(focusedInput)
    }

    const handleClosed = () => {
        setClosed(true)
    }

    const handleOutsideRange = (day: Moment) => {
        if (day.isAfter(moment(), 'day')) return true
        return false
    }

    const setInitialVisibleMonth = () => {
        const start = moment(startDate)
        const startMonth = start.month()
        const thisMonth = moment().month()

        if (startMonth === thisMonth) {
            return start.subtract(1, 'month')
        }
        return start
    }

    useEffect(() => {

        const formatDates = () => {
            const startInput: HTMLInputElement | null = document.getElementById(startDateId) as HTMLInputElement
            if (startInput) {
                startInput.value = formatDate(new Date(startDate))
            }

            const endInput: HTMLInputElement | null = document.getElementById(endDateId) as HTMLInputElement
            if (endInput) {
                endInput.value = formatDate(new Date(endDate))
            }
        }

        formatDates()

    }, [startDate, endDate, startDateId, endDateId, focusedInput])

    useEffect(() => {

        const replaceLeftArrow = () => {
            const leftArrow = document.querySelector(".DayPickerNavigation_leftButton__horizontalDefault")

            if (leftArrow) {
                if (!leftArrow.innerHTML.includes('our-left-arrow')) {
                    leftArrow.innerHTML = ""
                    leftArrow.innerHTML = `<img src=${leftArrowBlue} alt="our-left-arrow" />`
                }
            }
        }

        const replaceRightArrow = () => {
            const rightArrow = document.querySelector(".DayPickerNavigation_rightButton__horizontalDefault")

            if (rightArrow) {
                if (!rightArrow.innerHTML.includes('our-right-arrow')) {
                    rightArrow.innerHTML = ""
                    rightArrow.innerHTML = `<img src=${rightArrowBlue} alt="our-right-arrow" />`
                }
            }
        }

        const replaceArrows = () => {
            replaceLeftArrow()
            replaceRightArrow()
        }

        replaceArrows()

    }, [focusedInput])

    const [lastXDays, setLastXDays] = useState<string>("")

    useEffect(() => {

        const updateInputs = (id: string, width: string) => {

            const inputs = document.querySelectorAll(`#${id}`)

            inputs.forEach(input => {
                const parent = input.parentElement
                if (parent) parent.style.width = width
            })
        }

        const setDateInput = (startDate: number, endDate: number) => {

            if (!startDate || !endDate) return

            const startMoment = moment(startDate)
            const endMoment = moment(endDate)

            let width = '6.5rem'
            let xDays = ""

            if (moment().isSame(endMoment, 'day')) {
                const diff = endMoment.diff(startMoment, 'days') + 1
                xDays = diff === 1 ? "Today" : `Last ${diff} days`
                width = '58px'
            }

            setLastXDays(xDays)
            updateInputs(startDateId, width)
            updateInputs(endDateId, width)

        }
        setDateInput(startDate, endDate)

    }, [startDate, endDate, startDateId, endDateId])

    return (
        <div className="date-picker-wrapper">
            <button className="date-picker-title" onClick={openDatePicker}>
                <p>Date:</p>
            </button>
            <DateRangePicker

                //required props
                startDate={moment(startDate)} // momentPropTypes.momentObj or null,
                startDateId={startDateId} // PropTypes.string.isRequired,
                endDate={moment(endDate)} // momentPropTypes.momentObj or null,
                endDateId={endDateId} // PropTypes.string.isRequired,
                onDatesChange={updateDates} // PropTypes.func.isRequired,
                focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={handleFocusChange} // PropTypes.func.isRequired,

                //optional props
                anchorDirection="right"
                block={false}
                customArrowIcon={"–"}
                customCloseIcon={null}
                customInputIcon={null}
                disabled={false}
                displayFormat={undefined}
                enableOutsideDays={false}
                endDatePlaceholderText="End Date"
                hideKeyboardShortcutsPanel={true}
                horizontalMargin={0}
                initialVisibleMonth={setInitialVisibleMonth}
                isDayBlocked={undefined}
                isDayHighlighted={undefined}
                isOutsideRange={handleOutsideRange}
                isRTL={false}
                keepOpenOnDateSelect={false}
                maxDate={moment()}
                minDate={undefined}
                minimumNights={0}
                monthFormat="MMMM YYYY"
                navNext={null}
                navPosition="navPositionTop"
                navPrev={null}
                numberOfMonths={2}
                onClose={handleClosed}
                onNextMonthClick={function noRefCheck() { }}
                onPrevMonthClick={function noRefCheck() { }}
                orientation="horizontal"
                phrases={{
                    calendarLabel: 'Calendar',
                    // chooseAvailableEndDate: function noRefCheck() { },
                    // chooseAvailableStartDate: function noRefCheck() { },
                    clearDates: 'Clear Dates',
                    closeDatePicker: 'Close',
                    // dateIsSelected: function noRefCheck() { },
                    // dateIsSelectedAsEndDate: function noRefCheck() { },
                    // dateIsSelectedAsStartDate: function noRefCheck() { },
                    // dateIsUnavailable: function noRefCheck() { },
                    enterKey: 'Enter key',
                    escape: 'Escape key',
                    focusStartDate: 'Interact with the calendar and add the check-in date for your trip.',
                    hideKeyboardShortcutsPanel: 'Close the shortcuts panel.',
                    homeEnd: 'Home and end keys',
                    jumpToNextMonth: 'Move forward to switch to the next month.',
                    jumpToPrevMonth: 'Move backward to switch to the previous month.',
                    keyboardBackwardNavigationInstructions: 'Navigate backward to interact with the calendar and select a date. Press the question mark key to get the keyboard shortcuts for changing dates.',
                    keyboardForwardNavigationInstructions: 'Navigate forward to interact with the calendar and select a date. Press the question mark key to get the keyboard shortcuts for changing dates.',
                    keyboardShortcuts: 'Keyboard Shortcuts',
                    leftArrowRightArrow: 'Right and left arrow keys',
                    moveFocusByOneDay: 'Move backward (left) and forward (right) by one day.',
                    moveFocusByOneMonth: 'Switch months.',
                    moveFocusByOneWeek: 'Move backward (up) and forward (down) by one week.',
                    moveFocustoStartAndEndOfWeek: 'Go to the first or last day of a week.',
                    openThisPanel: 'Open this panel.',
                    pageUpPageDown: 'page up and page down keys',
                    questionMark: 'Question mark',
                    returnFocusToInput: 'Return to the date input field.',
                    roleDescription: 'datepicker',
                    selectFocusedDate: 'Select the date in focus.',
                    showKeyboardShortcutsPanel: 'Open the keyboard shortcuts panel.',
                    upArrowDownArrow: 'up and down arrow keys'
                }}
                regular={false}
                renderCalendarDay={undefined}
                renderDayContents={null}
                renderMonthText={null}
                reopenPickerOnClearDates={false}
                required={false}
                screenReaderInputMessage=""
                showClearDates={false}
                showDefaultInputIcon={false}
                small={false}
                startDatePlaceholderText="Start Date"
                // stateDateWrapper={function noRefCheck(){}}
                transitionDuration={300}
                // verticalSpacing={-65}
                verticalSpacing={0}
                weekDayFormat={"ddd"}
                withFullScreenPortal={false}
                withPortal={false}
            />
            {lastXDays &&
                <button className="last-days" onClick={() => setFocusedInput("startDate")}>
                    {lastXDays}
                </button>
            }
            <button className="date-picker-arrow" onClick={openDatePicker}>
                <img src={dropdownArrow} alt="dropdown-arrow" className={closed ? "" : "selected-arrow"} />
            </button>
        </div>
    )
}

export default DatePicker