import { ReduxAction, AppState } from '@declarations';
import { Actions } from 'Store/Actions/ActionsTypes'

const initialState: AppState.AppExperiences = {
    brandsList: [] as AppState.Brand[],
    demoBrandActive: false,
    globalView: false,
    brandInView: {} as AppState.Brand,
    channelInView: {} as AppState.Channel,
    experienceIdList: [] as string[],
    experienceSummary: {} as Record<string, AppState.ExperiencesSummary>
};

export const experiences = (state: AppState.AppExperiences = initialState, { type, payload }: ReduxAction): AppState.AppExperiences => {
    switch (type) {
        case Actions.SET_BRANDS_LIST:
            return { ...state, brandsList: payload as AppState.Brand[] }
        case Actions.SET_BRAND_IN_VIEW:
            return { ...state, brandInView: payload as AppState.Brand }
        case Actions.SET_CHANNEL_IN_VIEW:
            return { ...state, channelInView: payload as AppState.Channel }
        case Actions.SET_EXPERIENCE_ID_LIST:
            return { ...state, experienceIdList: payload as string[] }
        case Actions.SET_EXPERIENCES_SUMMARY:
            return { ...state, experienceSummary: payload as Record<string, AppState.ExperiencesSummary> }
        case Actions.SET_DEMO_BRAND_ACTIVE:
            return { ...state, demoBrandActive: payload as boolean }
        case Actions.SET_GLOBAL_VIEW:
            return { ...state, globalView: payload as boolean }
        default:
            return state
    }
}