
import { DateRange } from "@declarations"
import { generateTimeSampling } from "Utils/xAPIQueries/dates"
import { Constants } from "Values/constants"

const returnExample = [
    {
        "instance": "2021-07-22T00:00:00.000Z",
        "count": 26
    },
]

export type scansOverTimeTotalReturnType = typeof returnExample

const buildScansOverTimeTotal = (dates?: DateRange): any[] => [
    {
        "$group": {
            "_id": {
                "$dateToString": {
                    "format": dates ? generateTimeSampling(dates.start, dates.end) : Constants.default_timeline_time_sampling_format,
                    "date": "$statement.timestamp"
                },
            },
            "count": {
                "$sum": 1
            },
        }
    },
    {
        "$addFields": {
            "isoTime": {
                "$toDate": "$_id"
            }
        }
    },
    {
        "$sort": {
            "isoTime": 1
        }
    },
    {
        "$bucketAuto": {
            "groupBy": "$_id",
            "buckets": Constants.timeline_bucket,
            "output": {
                "count": {
                    "$sum": "$count"
                },
            }
        }
    },
    {
        "$addFields": {
            "_id": "$_id.max"
        }
    },
    {
        "$project": {
            "_id": 0,
            "instance": "$_id",
            "count": "$count",
        }
    }
]
export default buildScansOverTimeTotal