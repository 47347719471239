

import { AppState, PageProps } from "@declarations"
import DownloadReportButton from "Components/DownloadReportButton/DownloadReportButton"
import { connect } from "react-redux"
import { store } from "Store/store"
import { getDashboardName } from "Utils/routes"
import PushNotificationStatus from "./Charts/PushNotificationStatus/PushNotificationStatus"
import PushNotificationStatusTimelineChart from "./Charts/PushNotificationStatusTimelineChart/PushNotificationStatusTimelineChart"
import "./Notifications.css"
import PushNotificationsCTRTable from "./Tables/PushNotificationsCTRTable/PushNotificationsCTRTable"

interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {
}

const Notifications = (props: Props) => {

    const { brandInView, channelInView } = props

    return (
        <div className="page">
            <div className="header">
                <h2>{getDashboardName(brandInView?.name, channelInView?.name, "notifications")}</h2>
                <DownloadReportButton page="notifications" />
            </div>
            <PushNotificationStatus showLinkRow={false} />
            <br />
            <PushNotificationsCTRTable />
            <br />
            <PushNotificationStatusTimelineChart />
        </div>
    )
}

const mapStateToProps = (state: AppState) => {
    return {
        brandInView: state.experiences.brandInView,
        channelInView: state.experiences.channelInView
    }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Notifications)