import { HourCount } from "@declarations"
import { heatMapDays, heatMapHours } from "Utils/heatmap"
import { getRandomNumber } from "Utils/numbers"


export const getDemoDataVisitorsByTimeOfDay = (count: number): HourCount[] => {
    const episodes: HourCount[] = []

    for (let i = 1; i <= count; i++) {
        const ep: HourCount = {
            day: heatMapDays[getRandomNumber(0, heatMapDays.length - 1)],
            hour: heatMapHours[getRandomNumber(0, heatMapHours.length - 1)],
            count: getRandomNumber(1, 250)
        }
        episodes.push(ep)
    }

    return episodes
}