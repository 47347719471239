import { AppState } from "@declarations";

export const getExperienceIdList = (brand?: AppState.Brand, channel?: AppState.Channel): string[] => {

    let expIds: string[]

    if (channel) {

        expIds = channel.experiences.map(exp => exp.id)

    } else if (brand) {

        expIds = brand?.channels?.reduce((arr, channel) => {
            channel.experiences.forEach(exp => arr.push(exp.id))
            return arr
        }, [] as string[])
    }
    else {
        expIds = []
    }

    return expIds
}