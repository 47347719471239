import { Constants } from "Values/constants"
import { xApiKeys } from "Values/xApiKeys"

const returnExample = [
    {
        "region": "Ontario",
        "country": "Canada",
        "rank": 1,
        "experienceOpens": 200,
        "totalPercentage": 76.62835249042146
    }
]

export type trafficTotalsReturnType = typeof returnExample

const buildTrafficTotalsByRegion = (): any[] => [
    {
        "$group": {
            "_id": {
                "state": xApiKeys.Extensions.state.path,
                "country": xApiKeys.Extensions.country.path
            },
            "count": {
                "$sum": 1
            },
        }
    },
    {
        "$sort": {
            "count": -1
        }
    },
    {
        "$group": {
            "_id": null,
            "groups": {
                "$push": "$$ROOT"
            },
        }
    },
    {
        "$unwind": {
            "path": "$groups",
            "includeArrayIndex": "rank"
        }
    },
    {
        "$project": {
            "_id": 0,
            "state": "$groups._id.state",
            "country": "$groups._id.country",
            "rank": {
                "$add": [
                    "$rank",
                    1
                ]
            },
            "count": "$groups.count",
            "states": "$groups.states"
        }
    },
    {
        "$group": {
            "_id": null,
            "sum": {
                "$sum": "$count"
            },
            "regions": {
                "$push": {
                    "state": "$state",
                    "country": "$country",
                    "rank": "$rank",
                    "count": "$count"
                }
            }
        }
    },
    {
        "$unwind": {
            "path": "$regions",
        }
    },
    {
        "$project": {
            "_id": 0,
            "rank": "$regions.rank",
            "region": { $ifNull: ["$regions.state", Constants.unavailable_data_label] },
            "country": { $ifNull: ["$regions.country", Constants.unavailable_data_label] },
            "experienceOpens": "$regions.count",
            "totalPercentage": {
                "$multiply": [
                    {
                        "$divide": [
                            "$regions.count",
                            "$sum"
                        ]
                    },
                    100
                ]
            }
        }
    },
]
export default buildTrafficTotalsByRegion