import "./SummaryBox.css"

type Props = {
    variant: "green" | "blue" | "purple" | "pink"
    icon: string
    value?: string
    text: string
}

const SummaryBox = (props: Props) => {

    const { variant, icon, value, text } = props

    return (
        <div className={`summary-box ${variant}`}>
            <img src={icon} alt="icon" />
            <h3>{value}</h3>
            <p>{text}</p>
        </div>
    )
}

export default SummaryBox