import { Session } from '@declarations';
import DashboardNav from 'Pages/DashboardNav/DashboardNav';
import Admin from 'Pages/DashboardNav/Admin/Admin';
import Overview from 'Pages/DashboardNav/Overview/Overview';
import Login from 'Pages/Login/Login';
import { PageNotFound } from 'Pages/PageNotFound/PageNotFound';
import React from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import PageWrapper from 'Components/PageWrapper/PageWrapper';
import { SessionCheck } from 'Components/SessionCheck/SessionCheck';
import { store } from './Store/store';
import VisitorBehavior from 'Pages/DashboardNav/VisitorBehavior/VisitorBehavior';
import Notifications from 'Pages/DashboardNav/Notifications/Notifications';
import Demographics from 'Pages/DashboardNav/Demographics/Demographics';
import { Spinner } from 'react-bootstrap';
import { axiosConfig } from 'Config/axios';
import ScrollToTop from 'Utils/useScrollToTop';

axiosConfig()

function App() {

  return (
    <React.Fragment>
      <Provider store={store}>

        <Router>
          <ScrollToTop />
          <SessionCheck showIf="Auth">
            <DashboardNav>
              <Switch>
                <Route exact path="/">
                  <SessionCheck showIf="HasAccess" else={(session: Session) => {
                    return <Redirect to={"/overview/brand/" + (session as Session.AuthSession).user.brandId} />
                  }}>
                    <PageWrapper>
                      <Admin />
                    </PageWrapper>
                  </SessionCheck>
                </Route>
                <Route exact path="/overview">
                  <SessionCheck showIf="HasAccess" else={(session: Session) => {
                    return <Redirect to={"/overview/brand/" + (session as Session.AuthSession).user.brandId} />
                  }}>
                    <PageWrapper>
                      <Overview />
                    </PageWrapper>
                  </SessionCheck>
                </Route>
                <Route exact path="/overview/brand/:brand">
                  <SessionCheck showIf="HasAccess" else={<PageNotFound />}>
                    <PageWrapper>
                      <Overview />
                    </PageWrapper>
                  </SessionCheck>
                </Route>

                <Route exact path="/overview/channel/:brand/:channel">
                  <SessionCheck showIf="HasAccess" else={<PageNotFound />}>
                    <PageWrapper>
                      <Overview />
                    </PageWrapper>
                  </SessionCheck>
                </Route>


                <Route exact path="/demographics">
                  <SessionCheck showIf="HasAccess" else={(session: Session) => {
                    return <Redirect to={"/demographics/brand/" + (session as Session.AuthSession).user.brandId} />
                  }}>
                    <PageWrapper>
                      <Demographics />
                    </PageWrapper>
                  </SessionCheck>
                </Route>

                <Route exact path="/demographics/brand/:brand">
                  <SessionCheck showIf="HasAccess" else={<PageNotFound />}>
                    <PageWrapper>
                      <Demographics />
                    </PageWrapper>
                  </SessionCheck>
                </Route>

                <Route exact path="/demographics/channel/:brand/:channel">
                  <SessionCheck showIf="HasAccess" else={<PageNotFound />}>
                    <PageWrapper>
                      <Demographics />
                    </PageWrapper>
                  </SessionCheck>
                </Route>


                <Route exact path="/visitor-behavior">
                  <SessionCheck showIf="HasAccess" else={(session: Session) => {
                    return <Redirect to={"/visitor-behavior/brand/" + (session as Session.AuthSession).user.brandId} />
                  }}>
                    <PageWrapper>
                      <VisitorBehavior />
                    </PageWrapper>
                  </SessionCheck>
                </Route>

                <Route exact path="/visitor-behavior/brand/:brand">
                  <SessionCheck showIf="HasAccess" else={<PageNotFound />}>
                    <PageWrapper>
                      <VisitorBehavior />
                    </PageWrapper>
                  </SessionCheck>
                </Route>

                <Route exact path="/visitor-behavior/channel/:brand/:channel">
                  <SessionCheck showIf="HasAccess" else={<PageNotFound />}>
                    <PageWrapper>
                      <VisitorBehavior />
                    </PageWrapper>
                  </SessionCheck>
                </Route>


                <Route exact path="/notifications">
                  <SessionCheck showIf="HasAccess" else={(session: Session) => {
                    return <Redirect to={"/notifications/brand/" + (session as Session.AuthSession).user.brandId} />
                  }}>
                    <PageWrapper>
                      <Notifications />
                    </PageWrapper>
                  </SessionCheck>
                </Route>

                <Route exact path="/notifications/brand/:brand">
                  <SessionCheck showIf="HasAccess" else={<PageNotFound />}>
                    <PageWrapper>
                      <Notifications />
                    </PageWrapper>
                  </SessionCheck>
                </Route>

                <Route exact path="/notifications/channel/:brand/:channel">
                  <SessionCheck showIf="HasAccess" else={<PageNotFound />}>
                    <PageWrapper>
                      <Notifications />
                    </PageWrapper>
                  </SessionCheck>
                </Route>

                <Route path="*">
                  <PageNotFound />
                </Route>
              </Switch>
            </DashboardNav>
          </SessionCheck>

          <SessionCheck showIf={["Guest", "LoggingIn"]}>
            <Login />
          </SessionCheck>

          <SessionCheck showIf="Verifying">
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "50vh" }}>
              <Spinner animation="border" />
            </div>
          </SessionCheck>

        </Router>
      </Provider >
    </React.Fragment >
  );
}

export default App;
