
import { LegendItem, NotificationFunnel } from "@declarations"
import Legend from "Components/Legend/Legend"
import "./FunnelChart.css"

type Props = {
    data: NotificationFunnel
}

const legendMap: Record<keyof NotificationFunnel, any> = {
    notificationsDelivered: {
        label: "Notifications Delivered",
        color: "#DDDEE0",
        order: 1
    },
    notificationsOpened: {
        label: "Notifications Opened",
        color: "#0038A8",
        order: 2
    },
    CTAsClicked: {
        label: "CTAs Clicked",
        color: "#B80290",
        order: 3
    },
    CTR: {
        label: "CTR",
        color: "#858993",
        order: 4
    },
}

const FunnelChart = (props: Props) => {

    const { data } = props

    const legendData: LegendItem[] = (Object.keys(data) as Array<keyof NotificationFunnel>).map(key => {
        return {
            title: legendMap[key].label,
            value: key === "CTR" ? `${data[key] * 100}%` : `${data[key]}`,
            color: legendMap[key].color,
            order: legendMap[key].order
        }
    })

    return (
        <div className="funnel-chart-wrapper">
            <ul className="funnel">
                {Object.keys(data).map((d, idx) => (
                    <li key={idx}></li>
                ))}
            </ul>
            <Legend items={legendData} />
        </div>
    )
}

export default FunnelChart