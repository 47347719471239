import { Constants } from "Values/constants"
import { xApiStatementKeys } from "Values/xApiKeys"

const returnExample = [
    {
        "day": 1,
        "hour": 1,
        "count": 1
    },
]

export type visitorsByTimeOfDayReturnType = typeof returnExample

const buildVisitorsByTimeOfDay = (): any[] => [
    {
        "$group": {
            "_id": {
                "day": {
                    "$dayOfWeek": `$${xApiStatementKeys.timestamp}`,
                },
                "hour": {
                    "$hour": {
                        "date": `$${xApiStatementKeys.timestamp}`,
                        "timezone": `${Constants.default_timezone}`
                    }
                }
            },
            "count": {
                "$sum": 1
            }
        }
    },
    {
        "$sort": {
            "_id": 1
        }
    },
    {
        "$project": {
            "_id": 0,
            "day": "$_id.day",
            "hour": "$_id.hour",
            "count": "$count"
        }
    }
]
export default buildVisitorsByTimeOfDay