import { TableData } from "@declarations"
import { getRandomNumber } from "Utils/numbers"

const titles = [
    "Spring 2021 Promo",
    "Summer 2021 Promo",
    "Fall 2021 Promo",
    "Winter 2021 Promo",
]

export const getDemoDataTopViewedEpisodes = (count: number): TableData.Episode[] => {
    const episodes: TableData.Episode[] = []

    for (let i = 1; i <= count; i++) {
        const ep: TableData.Episode = {
            episodeTitle: titles[getRandomNumber(0, titles.length - 1)],
            launchDate: new Date().valueOf(),
            opens: getRandomNumber(1, 25),
            sessions: getRandomNumber(1, 25),
            averageTimeViewed: getRandomNumber(1, 1000),
            totalTimeViewed: getRandomNumber(1000, 100000)
        }
        episodes.push(ep)
    }

    return episodes
}