import { AppState, Session } from "@declarations"
import { getExperiencesSummary } from "Api/Experiences/experiences";
import { store } from "Store/store"
import { getExperienceIdList } from "Utils/experienceIds";
import { excludeList } from "Values/experienceExcludeList";
import { Actions, makeAction } from "./ActionsTypes";

export const getBrands = () => async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    dispatch(makeAction(Actions.SET_LOADING_BRAND_LIST, true))

    const data: AppState.ExperiencesSummary[] = await getExperiencesSummary()

    const brandMap = data.filter(experience => {
        const session = (store.getState().session as Session.AuthSession);
        if (session.user?.admin) return true;
        return experience.channel.brand.id === session.user?.brandId
    }).reduce((brands, experience) => {

        const channel = experience.channel
        const brand = channel.brand

        const channelsMap = brands[brand.id] || {};
        const exps: AppState.Experience[] = channelsMap[channel.id] || [];

        if (excludeList.has(brand.name)) return brands;

        exps.push({
            id: experience.id,
            name: experience.name,
            brand: {
                id: brand.id,
                name: brand.name
            },
            channel: {
                id: channel.id,
                name: channel.name
            }
        });
        channelsMap[channel.id] = exps;
        brands[brand.id] = channelsMap;
        return brands;
    }, {} as Record<string, Record<string, AppState.Experience[]>>);

    const brands: AppState.Brand[] = Object.keys(brandMap).map<AppState.Brand>(brandId => {
        const channelsMap = brandMap[brandId];
        // look at first channel for brand name, all have same brand
        const firstChannel = channelsMap[Object.keys(channelsMap)[0]][0]
        return {
            id: brandId,
            name: firstChannel?.brand?.name || "",
            channels: Object.keys(channelsMap).map<AppState.Channel>(channelId => {
                const currentChannel = channelsMap[channelId]
                return {
                    experiences: channelsMap[channelId],
                    name: currentChannel?.find((e: AppState.Experience) => e.channel.id === channelId)?.name || "",
                    id: channelId
                }
            })
        }
    })

    const expSummaryMap: Record<string, AppState.ExperiencesSummary> = {}
    data.forEach(d => {
        expSummaryMap[d.id] = d
    })

    dispatch(makeAction(Actions.SET_LOADING_BRAND_LIST, false))
    dispatch(makeAction(Actions.SET_BRANDS_LIST, brands));
    dispatch(makeAction(Actions.SET_EXPERIENCES_SUMMARY, expSummaryMap))
}

export const setBrandInView = (brand: AppState.Brand, channel?: AppState.Channel) => async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    dispatch(makeAction(Actions.SET_BRAND_IN_VIEW, brand))

    if (channel) {
        dispatch(makeAction(Actions.SET_CHANNEL_IN_VIEW, channel))
    } else {
        dispatch(makeAction(Actions.SET_CHANNEL_IN_VIEW, {} as AppState.Channel))
    }

    const expIds = (getExperienceIdList(brand, channel))
    dispatch(makeAction(Actions.SET_EXPERIENCE_ID_LIST, expIds))

}