import { AppState, PageProps, VisitorBehaviorTab } from "@declarations"
import { connect } from "react-redux"
import { store } from "Store/store"
import BounceRatesOverTime from "../Charts/BounceRatesOverTime/BounceRatesOverTime"
import CTAOverTime from "../Charts/CTAOverTime/CTAOverTime"

type Props = PageProps<typeof mapStateToProps, typeof mapDispatchToProps> & {
    tab: VisitorBehaviorTab
}

const ChartArea = (props: Props) => {

    const { tab } = props


    const renderChart = (tab: VisitorBehaviorTab) => {
        switch (tab) {
            case "Top Viewed Episodes":
                return null
            case "Visitors":
                return null
            case "Bounce Rates":
                return <BounceRatesOverTime />
            case "Calls to Action":
                return <CTAOverTime />
            default:
                return null
        }
    }

    return (
        <div className="">
            {renderChart(tab)}
        </div>
    )
}

const mapStateToProps = (state: AppState) => {
    return {
    }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartArea)