

import { AppState, PageProps } from "@declarations"
import DownloadReportButton from "Components/DownloadReportButton/DownloadReportButton"
import { connect } from "react-redux"
import { store } from "Store/store"
import { getDashboardName } from "Utils/routes"
import ScansOverTimeByLocation from "./Charts/ScansOverTimeByLocation/ScansOverTimeByLocation"
import "./Demographics.css"
import ScansByLocationMap from "./Maps/ScansByLocationMap/ScansByLocationMap"
import QRCodesByLocationTable from "./Tables/QRCodesByLocationTable.tsx/QRCodesByLocationTable"

interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {
}

const Demographics = (props: Props) => {

    const { brandInView, channelInView } = props

    return (
        <div className="page">
            <div className="header">
                <h2>{getDashboardName(brandInView?.name, channelInView?.name, "demographics")}</h2>
                <DownloadReportButton page="demographics" />
            </div>
            <ScansByLocationMap showLink={false} showTable={true} />
            <br />
            <ScansOverTimeByLocation />
            <br />
            <QRCodesByLocationTable />
        </div>
    )
}

const mapStateToProps = (state: AppState) => {
    return {
        brandInView: state.experiences.brandInView,
        channelInView: state.experiences.channelInView
    }
}
const mapDispatchToProps = (dispatch: typeof store.dispatch) => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Demographics)