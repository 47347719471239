import { AppState, PageProps } from "@declarations"
import { connect } from "react-redux"
import PageHeader from "Components/PageHeader/PageHeader"

type Props = PageProps<typeof mapStatesToProps> & {
    children: React.ReactElement,
}

const PageWrapper = (props: Props) => {

    const { children } = props

    return (
        <div id="page-wrapper">
            <PageHeader />
            {children}
        </div>
    )
}

const mapStatesToProps = (state: AppState) => {
    return { session: state.session }
}
export default connect(mapStatesToProps)(PageWrapper)