import { AppState, Breakdown, DateRange, MarkedTimelineSlice, NotificationFunnel, TableData } from "@declarations";
import { store } from "Store/store";
import { getDemoDataPushNotificationBreakdown } from "Values/DemoData/Notifications/push-notification-status-breakdown";
import { getDemoDataPushNotificationFunnel } from "Values/DemoData/Notifications/push-notification-status-funnel";
import { getDemoDataPushNotificationStatusTimeline } from "Values/DemoData/Notifications/push-notification-status-timeline";
import { getDemoDataPushNotificationsCTR } from "Values/DemoData/Notifications/push-notifications-ctr";
import { Actions, makeAction } from "./ActionsTypes";

// LOADING
const setLoading = (property: keyof AppState.LoadingNotifications, on: boolean) => async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    const currentLoading = getState().loading.notifications

    const loading: AppState.LoadingNotifications = {
        ...currentLoading,
        [property]: on
    }
    dispatch(makeAction(Actions.SET_LOADING_NOTIFICATIONS_DATA, loading))
}

// TABLES

export const getPushNotificationsCTR = () => async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    await dispatch(setLoading("pushNotificationsCTR", true))

    let n: TableData.PushNotificationCTR[] = []

    const demoBrandActive = getState().experiences.demoBrandActive

    if (demoBrandActive) {
        n = getDemoDataPushNotificationsCTR(100)
    }

    const currentState = getState().data.notifications
    const data: AppState.NotificationsData = {
        ...currentState,
        pushNotificationsCTR: n
    }
    await dispatch(makeAction(Actions.SET_NOTIFICATIONS_DATA, data))

    await dispatch(setLoading("pushNotificationsCTR", false))

}

// CHARTS

export const getPushNotificationStatus = (dates: DateRange) => async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    await dispatch(setLoading("pushNotificationStatus", true))

    let b: Breakdown[] = []
    let f: NotificationFunnel = {} as NotificationFunnel

    const demoBrandActive = getState().experiences.demoBrandActive

    if (demoBrandActive) {
        b = getDemoDataPushNotificationBreakdown(dates, 100)
    }

    if (demoBrandActive) {
        f = getDemoDataPushNotificationFunnel(dates, 100)
    }

    const currentState = getState().data.notifications
    const data: AppState.NotificationsData = {
        ...currentState,
        pushNotificationStatus: {
            breakdown: b,
            funnel: f
        }
    }
    await dispatch(makeAction(Actions.SET_NOTIFICATIONS_DATA, data))

    await dispatch(setLoading("pushNotificationStatus", false))

}

export const getPushNotificationStatusTimeline = (dates: DateRange) => async (dispatch: typeof store.dispatch, getState: typeof store.getState) => {

    await dispatch(setLoading("pushNotificationStatusTimeline", true))

    let n: MarkedTimelineSlice[] = []

    const demoBrandActive = getState().experiences.demoBrandActive

    if (demoBrandActive) {
        n = getDemoDataPushNotificationStatusTimeline(dates, 100)
    }

    const currentState = getState().data.notifications
    const data: AppState.NotificationsData = {
        ...currentState,
        pushNotificationStatusTimeline: n
    }
    await dispatch(makeAction(Actions.SET_NOTIFICATIONS_DATA, data))

    await dispatch(setLoading("pushNotificationStatusTimeline", true))

}