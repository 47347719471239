import { ReduxAction, Session } from '@declarations';
import { Actions } from 'Store/Actions/ActionsTypes'

const initialState: Session = {
    state: "Guest"
};

export const session = (state: Session = initialState, { type, payload }: ReduxAction): Session => {
    switch (type) {
        case Actions.SET_SESSION:
            return payload as Session
        case Actions.CLEAR_SESSION:
            return initialState;
        default:
            return state
    }
}