
export const getCountriesAndRegions = (data: any[]) => {

    const countryRegionMap: Record<string, Set<string>> = {}

    for (const d of data) {
        if (d.hasOwnProperty('country') && d.hasOwnProperty('region')) {
            const c = d.country as string
            const r = d.region as string

            if (countryRegionMap[c]) {
                if (!countryRegionMap[c].has(r)) {
                    countryRegionMap[c].add(r)
                }
            } else {
                countryRegionMap[c] = new Set([r])
            }
        }
    }

    return countryRegionMap
}

export const filterDataByLocation = (data: any[], locationFilter: { country: string, region: string }): any[] => {

    return data.reduce((filtered: any[], d: any) => {

        if (locationFilter.country && locationFilter.region) {
            if (d.country === locationFilter.country && d.region === locationFilter.region) {
                filtered.push(d)
            }
        } else if (locationFilter.country) {
            if (d.country === locationFilter.country) {
                filtered.push(d)
            }
        } else {
            filtered.push(d)
        }
        return filtered

    }, []);

}